import React, { useRef, useState, useEffect } from 'react';
import HeaderMobile from '../../layouts/HeaderMobile';
import Header from '../../layouts/Header';
import { Button, Modal, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { addCandidateInvite, getcompanyId, getToken, viewemployeetoken } from '../../services/api/api';
import { DataGrid } from '@mui/x-data-grid';
import { toast } from 'react-toastify';

const InviteCandidate = () => {
    const [show, setShow] = useState(false);
    const tableRef = useRef(null);
    const buttonRef = useRef(null);
    const [isSending, setIsSending] = useState(false);
    const [url, setURL] = useState([]);
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        email: '',
    });
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await viewemployeetoken();
                const dataWithIds = response.data[0].employee_form.map((item, index) => ({
                    ...item,
                    id: index + 1,
                    email: item.email,
                    url: item.url,
                }));
                setURL(dataWithIds);
                console.log(dataWithIds);
            } catch (error) {
                console.log("Error fetching data:", error.message);
            }
        };

        fetchData();
    }, [show])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        if (name === 'email') {
            if (!validateEmail(value)) {
                setEmailError('Please enter a valid email address');
            } else {
                setEmailError('');
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        if (!validateEmail(formData.email)) {
            setEmailError('Please enter a valid email address');
            return; // Prevent form submission if email is invalid
        }
        try {
            setLoading(true);
            const token = getToken();
            console.log("token", token);
            const companyId = getcompanyId();
            const inviteResponse = await addCandidateInvite({
                email: formData.email,
                token: token,
            });
            handleClose();
            if (inviteResponse) {
                toast.success(inviteResponse.message, {
                    position: "top-center",
                    autoClose: 1000,
                });
            }
            else {
                toast.error('Something went wrong', {
                    position: "top-center",
                    autoClose: 1000,
                });
            }

        } catch (error) {
            console.log("*************************", error.message);
        } finally {
            setLoading(false);
        }
    };

    // const columns = [
    //     {
    //         field: 'id',
    //         headerName: 'Sr No.',
    //         width: 100,
    //         align: 'center',
    //         headerAlign: 'center',
    //         renderCell: (params) => (
    //             <span>{params.row.id}</span>
    //         ),
    //     },
    //     {
    //         field: 'email',
    //         headerName: 'Email',
    //         width: 300,
    //         align: 'center',
    //         headerAlign: 'center',
    //         renderCell: (params) => (
    //             <span>{params.row.email}</span>
    //         )
    //     },
    //     {
    //         field: 'url',
    //         headerName: 'URL',
    //         align: 'center',
    //         headerAlign: 'center',
    //         width: 700, // Increase the width to accommodate longer URLs
    //         renderCell: (params) => (
    //             <a
    //                 href={params.row.url}
    //                 target="_blank"
    //                 rel="noopener noreferrer"
    //                 style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', maxWidth: '100%' }}
    //             >
    //                 {params.row.url}
    //             </a>
    //         )
    //     },
    // ];

    const columns = [
        {
            field: 'id',
            headerName: 'Sr No.',
            width: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <span>{params.row.id}</span>
            ),
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 300,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <span>{params.row.email}</span>
            )
        },
        {
            field: 'url',
            headerName: 'URL',
            align: 'center',
            headerAlign: 'center',
            width: 330,
            renderCell: (params) => (
                <a
                    href={params.row.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', maxWidth: '20rem' }}
                >
                    {params.row.url}
                </a>
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-copy"
                    style={{ cursor: 'pointer', transition: 'color 0.3s' }}
                    onClick={() => copyToClipboard(params.row.url)}
                >
                    <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
                    <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
                </svg>
            ),
        },
    ];

    const copyToClipboard = (url) => {
        navigator.clipboard.writeText(url).then(
            () => {
                toast.info("Link copied!", {
                    position: "top-center",
                    autoClose: 1000,
                });
            },
            (err) => {
                console.error('Failed to copy URL: ', err);
            }
        );
    };


    // const handleSubmit = async () => {
    //     try {
    //         setLoading(true);
    //         let response = await viewemployeetoken();
    //         const dataWithIds = response.data[0].employee_form.map((item, index) => ({
    //             ...item,
    //             id: index + 1,
    //             email: item.email,
    //             url: item.url,
    //         }));
    //         setURL(dataWithIds);
    //         console.log(dataWithIds);
    //         setLoading(false);

    //         const token = getToken();
    //         console.log("token", token);
    //         const companyId = getcompanyId();
    //         const inviteResponse = await addCandidateInvite({
    //             email: formData.email,
    //             token: token,
    //         });
    //         handleClose();
    //     } catch (error) {
    //         setLoading(false);
    //         console.log("*************************", error.message);
    //     }
    // };

    // useEffect(() => {
    //     // Call handleSubmit to fetch data on component mount
    //     handleSubmit();
    // }, []);

    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
                <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                    <div>
                        <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                            <li className="breadcrumb-item ">
                                <Link to="/admin/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/pages/recruitment/candidate">Applicants</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/pages/recruitment/invitecandidate">Invite Applicants</Link>
                            </li>
                            {/* <li className="breadcrumb-item active">Lists</li> */}
                        </ol>
                        <div>
                            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                                <li className="">
                                    <Button
                                        variant="primary"
                                        className="mb-3 btn-1"
                                        onClick={handleShow}
                                        style={{
                                            backgroundColor: '#28a745',
                                            borderColor: '#28a745',
                                            fontWeight: 'bold',
                                            borderRadius: '5px',
                                            padding: '10px 20px',
                                            color: '#fff',
                                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                        }}
                                    >
                                        Invite Applicant
                                    </Button>
                                </li>
                                <Modal show={show} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Invite Applicant</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form>
                                            <Form.Group controlId="formEmail">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    placeholder="Enter email"
                                                />
                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button>
                                        <Button
                                            ref={buttonRef}
                                            variant="primary"
                                            onClick={handleSubmit}
                                            disabled={isSending}
                                        >
                                            {isSending ? 'Sending...' : 'Send Invites'}
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </ol>
                        </div>

                    </div>
                    <nav aria-label="breadcrumb"></nav>
                </div>

                {loading ? (
                    <div className="loader-container">
                        <div className="loader"></div>
                    </div>
                ) : (
                    <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                        <div style={{ height: '100%', width: '100%', backgroundColor: 'white' }}>
                            <DataGrid
                                rows={url}
                                columns={columns}
                                pageSize={5}
                                autoHeight
                                getRowId={(row) => row.id}
                                components={{
                                    NoRowsOverlay: () => (
                                        <div style={{ textAlign: 'center', padding: '20px' }}>
                                            No data available
                                        </div>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default InviteCandidate;
