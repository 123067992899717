
import React, { useState, useEffect, useRef } from 'react';
import _debounce from 'lodash/debounce';
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import { Card } from "react-bootstrap";
import $ from 'jquery';
import 'datatables.net';
import { useNavigate } from "react-router-dom";
import { viewAnnouncement, deleteAnnouncement } from '../../services/api/api';
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { DataGrid } from '@mui/x-data-grid';
import * as XLSX from 'xlsx'; // Import XLSX library
const Announcement = () => {
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [announcementData, setAnnouncementData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteAnnouncementId, setdeleteAnnouncementId] = useState('');
  const [masterId, setMasterId] = useState('');
  const [searchText, setSearchText] = useState('');

  // const fetchData = async () => {
  //   try {
  //     const formattedData = await viewAnnouncement();
  
  //     if (!formattedData || !formattedData.length) {
  //       throw new Error('Failed to fetch data.');
  //     }
  
  //     // Set masterId to the _id from the fetched data
  //     const masterId = formattedData[0]._id;
  //     setMasterId(masterId);
  
  //     const announcementsArray = formattedData.reduce((accumulator, currentData) => {
  //       const companyAnnouncement = currentData.announcement;
  
  //       if (companyAnnouncement) {
  //         if (Array.isArray(companyAnnouncement)) {
  //           accumulator.push(
  //             ...companyAnnouncement.map((item) => ({
  //               _id: item._id,
  //               announcement_subject: item.announcement_subject || 'N/A',
  //             }))
  //           );
  //         } else if (typeof companyAnnouncement === 'object') {
  //           const values = Object.values(companyAnnouncement).map((value) => ({
  //             _id: value._id,
  //             announcement_subject: value.announcement_subject || 'N/A',
  //           }));
  //           accumulator.push(...values);
  //         }
  //       }
  //       return accumulator;
  //     }, []);
  
  //     setAnnouncementData(announcementsArray);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  
  // useEffect(() => {
  //   fetchData();
  // }, []);

  
  const fetchData = async () => {
    try {
      const formattedData = await viewAnnouncement();
  
      if (!formattedData || !formattedData.length) {
        throw new Error('Failed to fetch data.');
      }
  
      // Set masterId to the _id from the fetched data
      const masterId = formattedData[0]._id;
      setMasterId(masterId);
  
      const announcementsArray = formattedData.reduce((accumulator, currentData) => {
        const companyAnnouncement = currentData.announcement;
  
        if (companyAnnouncement) {
          if (Array.isArray(companyAnnouncement)) {
            accumulator.push(
              ...companyAnnouncement.map((item) => ({
                _id: item._id,
                announcement_subject: item.announcement_subject || 'N/A',
              }))
            );
          } else if (typeof companyAnnouncement === 'object') {
            const values = Object.values(companyAnnouncement).map((value) => ({
              _id: value._id,
              announcement_subject: value.announcement_subject || 'N/A',
            }));
            accumulator.push(...values);
          }
        }
        return accumulator;
      }, []);
  
      setAnnouncementData(announcementsArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    const debouncedFetchData = _debounce(fetchData, 100);

    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, []);

  
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  };
  
  const exportToExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    // Create a new worksheet with the data
    const worksheet = XLSX.utils.json_to_sheet(
      reversedRows.map((row, index) => ({
        'Sr.': index + 1,
        'Announcement Subject': row.announcement_subject || '', // Update field name here
      }))
    );
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    // Convert the workbook to a binary string
    const excelBinaryString = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    // Convert the binary string to a Blob
    const excelBlob = new Blob([s2ab(excelBinaryString)], { type: 'application/octet-stream' });
    // Create a download link
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(excelBlob);
    downloadLink.download = 'Announcement Data.xlsx';
    // Trigger the download
    downloadLink.click();
  };

  const reversedRows = [...announcementData].reverse();

  const columns = [

    {
      field: 'id',
      headerName: 'Sr.',align: 'center',  headerAlign: 'center',
      
      renderCell: (params) => (
        <span>
          {reversedRows.indexOf(params.row) + 1}
        </span>
      ),
    },
    {
      field: 'announcement_subject', headerName: 'Announcement Subject',flex:2, align: 'center',  headerAlign: 'center',
      //  renderCell: (params) => (
        // <span style={{ whiteSpace: 'nowrap' }}>{params.row.first_name} {params.row.last_name}</span>
      // )
    },
    
    {
      field: 'actions', headerName: 'Actions', width: 221, align: 'center', flex:1, headerAlign: 'center',renderCell: (params) => (
        <>
           <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-pencil-fill edit ebtn"
            viewBox="0 0 16 16"
            data-id={params.row._id}
            style={{ cursor: 'pointer', marginRight: "15px" }}
            onClick={() => {
              const id = params.row._id;
              navigate(`/edit_announcement/${id}/${masterId}`);
              // console.log("viewemployee id", id);
            }}
          >
            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-trash3-fill delete delete-button"
            viewBox="0 0 16 16"
            data-id={params.row._id}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const id = params.row._id;
              openConfirmationModal(id);
            }}
          >
            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
          </svg>
        </>
      )
    },
  ];
  
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filterRows = () => {
    if (!searchText) {
      return [...announcementData].reverse();
    }

    return [...announcementData].reverse().filter((row) =>
      Object.values(row).some(
        (value) =>
          typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  
  const handledeleteAnnouncement = async (id,masterId) => {
    setdeleteAnnouncementId(id);
    setShowConfirmation(true);
  
    try {
      const response = await deleteAnnouncement(id,masterId);
      if (response && response.status === 'success') {
        if (!toast.isActive('announcementDeletedToast')) {
          toast.success('Announcement deleted successfully', { toastId: 'announcementDeletedToast' });
        }
        fetchData();
      }
    } catch (error) {
      toast.error('An error occurred while deleting');
      console.error('Error deleting announcement:', error);
    } finally {
      setLoading(false);
    }
  };
  

  const createActionButton = (data) => {
    const id = data[0];

    const viewButton = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill view" viewBox="0 0 16 16" style="cursor: pointer;">
  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
  </svg>`;

    const editButton = `
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-pencil-fill edit edit-button"
        viewBox="0 0 16 16"
        data-id="${id}"
        data-masterId="${masterId}"
        style="cursor: pointer;">
        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
      </svg>
    `;


    const deleteButton = `
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill delete delete-button" viewBox="0 0 16 16" 
  data-id="${id}" style="cursor: pointer;">
  //   <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
  // </svg>
  `;


    return ` ${editButton} <span style="margin-right: 8px;"></span> ${deleteButton} <span style="margin-right: 8px;"></span> ${viewButton} <span style="margin-right: 8px;"></span> `;
  };


 
  const openConfirmationModal = (id) => {
    setdeleteAnnouncementId(id);
    setShowConfirmation(true);
  };
  const closeConfirmationModal = () => {
    setShowConfirmation(false);
  };
  // $('#commonTable tbody').on('click', '.delete-button', function () {
  //   const id = $(this).data('id');
  //   openConfirmationModal(id);
  // });
  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      {/* Your header components */}
      <div className="main p-4 p-lg-5 mt-5">
        {/* Your breadcrumb section */}
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              {/* <li className="mx-2">
                  <h4>Holidays</h4>
                </li> */}
              <li class="breadcrumb-item ">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li class="breadcrumb-item active"><Link to="/admin/announcement">Announcements</Link></li>
              {/* <li class="breadcrumb-item active">Lists</li> */}
            </ol>
          </div>
          <nav aria-label="breadcrumb">
       
              <Link to="/pages/announcementform">
              <div><button className="button-one" type="submit"><span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                  </svg>Create Announcement</span></button></div>
              </Link>
           
          </nav>
        </div>
        <Card className="popup-card empHistory">
        <Card.Body>
        <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', justifyContent: 'space-between', marginBottom: "1rem" }}>
              <Button className='bg-success border-0' onClick={exportToExcel }>Export Data to Excel Sheet</Button>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <label htmlFor="searchInput"></label>
                <input
                  type="text"
                  className='p-1 w-75 ms-2'
                  placeholder="Search"
                  value={searchText}
                  onChange={handleSearch}
                />
              </div>
            </div>
            {loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (
              <div className="data-grid-container" style={{ height: '100%', width: '100%', backgroundColor: 'white' }}>
                <DataGrid
                  rows={filterRows()}
                  columns={columns}
                  pageSize={5}
                  responsive
                  autoHeight
                  // checkboxSelection
                  getRowId={(row) => row._id}
                  components={{
                    NoRowsOverlay: () => <div style={{ textAlign: 'center', padding: '20px' }}>No data available</div>,
                  }}
                />
              </div>
            )}
          </Card.Body>

        </Card>
      </div>

      <Modal show={showConfirmation} onHide={closeConfirmationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this announcement?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeConfirmationModal}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              handledeleteAnnouncement(deleteAnnouncementId, masterId);
              closeConfirmationModal();
            }}

          >
            Delete
          </Button>

        </Modal.Footer>
      </Modal>

   
    </React.Fragment>
  );

}
export default Announcement;




















