import React, { useState, useEffect, useRef } from 'react';
import _debounce from 'lodash/debounce';
import Header from "../../../layouts/Header";
import HeaderMobile from "../../../layouts/HeaderMobile";
import { Card, Button, Modal } from "react-bootstrap";
import $ from 'jquery';
import 'datatables.net';
import { viewResignation, deleteResignation, viewresignationpaggination, BASE_URL } from '../../../services/api/api';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DataGrid } from '@mui/x-data-grid';
import * as XLSX from 'xlsx'; // Import XLSX library
import { Box, Typography } from '@mui/material';


const ViewResignation = () => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [resignationData, setResignationData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteResignationId, setDeleteResignationId] = useState(null);
  const [isDeleteMessageDisplayed, setIsDeleteMessageDisplayed] = useState(false);
  const [masterId, setMasterId] = useState();
  const [searchText, setSearchText] = useState('');
  // const reversedRows = [...resignationData].reverse();
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setpageSize] = useState(100);
  const [count, setCount] = useState(0);



  const customStyles = {
    grid: {
      border: '1px solid #ddd',
    },
    cell: {
      border: 'none', // Remove all borders
      borderBottom: '1px solid #ddd', // Only horizontal border
      padding: '0.5rem 0.5rem',
      color: 'var(--bs-table-color-state, var(--bs-table-color-type, #000000b8))', // Using custom properties for text color
      backgroundColor: 'white', // Using custom properties for background color
      fontWeight: 'bold', // Making text important
      fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', // Highly standard font family
    },
    columnHeader: {
      border: 'none', // Remove all borders
      borderBottom: '1px solid #ddd', // Only horizontal border
      padding: '0.5rem 0.5rem',
      color: 'var(--bs-table-color-state, var(--bs-table-color-type, #000))', // Using custom properties for text color
      backgroundColor: 'var(--bs-table-bg)', // Using custom properties for background color

      fontWeight: 'bold',
      fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', // Highly standard font family
    },
    lastColumn: {
      borderRight: 'none',
    },
  };

  const handlePageSizeChange = (event) => {
    setpageSize(parseInt(event.target.value, 10));
  };

  const handlePageChange = (event) => {
    setPage(parseInt(event.target.value, 10));
    console.log(event.target.value)
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month starts from 0
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const debouncedFetchData = _debounce(fetchData, 100);
    debouncedFetchData(); return () => {
      debouncedFetchData.cancel();
    };
  }, []);



  // const fetchData = async () => {
  //   try {
  //     const { success, data, totalPages } = await viewresignationpaggination(page, pageSize);

  //     if (success) {
  //       // Flatten the data
  //       const flattenedData = data.flatMap(item => item.company_resignation.map(resignation => ({
  //         ...resignation,
  //         id: resignation._id,
  //       })));

  //       setResignationData(flattenedData)
  //       setTotalPages(totalPages);
  //       setpageSize(flattenedData.pageSize);
  //       console.log(flattenedData);
  //       setMasterId(data[0]._id);


  //     } else {
  //       setResignationData([]);
  //       setTotalPages(0);
  //       setMasterId(null);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchData = async () => {
    try {
      const { success, data, totalPages } = await viewresignationpaggination(page, pageSize);

      if (success) {
        // Flatten the data and handle cases where there may be no resignations
        const flattenedData = data.flatMap(item =>
          item.company_resignation?.map(resignation => ({
            ...resignation,
            id: resignation._id,
          })) || [] // Use empty array if no resignations
        );

        // Update the state based on the flattened data
        setResignationData(flattenedData);
        setTotalPages(totalPages);
        setpageSize(flattenedData.length > 0 ? flattenedData.pageSize : 0); // Adjust pageSize logic
        console.log(flattenedData);

        // Only set the master ID if there's data
        setMasterId(data[0]._id);
      } else {
        // Handle unsuccessful response
        setResignationData([]);
        setTotalPages(0);
        setMasterId(null);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };


  const [error, setError] = useState(null);

  const getToken = () => {
    const token = sessionStorage.getItem('token');

    return token ? `Bearer ${token}` : '';


  };

  // const handleExportResignation = async () => {
  //   setLoading(true);

  //   try {
  //     const response = await fetch(`${BASE_URL}/exportResignationData`, {
  //       method: 'POST',
  //       headers: {
  //         'Accept': 'application/json',
  //         "Content-Type": "application/json",
  //         "Authorization": getToken(),
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }

  //     const data = await response.json();

  //     // Convert JSON data to CSV
  //     const csvData = convertToCSV(data); // Ensure you have a function to convert data to CSV

  //     // Create a Blob and trigger the download
  //     const blob = new Blob([csvData], { type: 'text/csv' });
  //     const link = document.createElement('a');
  //     link.href = URL.createObjectURL(blob);
  //     link.download = 'employee_data.csv'; // Change the file name as needed
  //     link.click();

  //     // Clean up
  //     URL.revokeObjectURL(link.href);



  //   } catch (error) {
  //     console.error("Fetch error: ", error);
  //     setError(error.message || 'An error occurred');



  //   } finally {
  //     setLoading(false); // Hide loading state
  //   }
  // };

  const handleExportResignation = async () => {
    setLoading(true); // Show loading state

    try {
      const response = await fetch(`${BASE_URL}/exportResignationData`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          "Content-Type": "application/json",
          "Authorization": getToken(),
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      // Convert JSON data to CSV
      const csvData = convertToCSV(data); // Ensure you have a function to convert data to CSV

      // Create a Blob and trigger the download
      const blob = new Blob([csvData], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'resignation_data.csv'; // Change the file name as needed
      link.click();

      // Clean up
      URL.revokeObjectURL(link.href);

      // Show success toast message
      toast.success("Resignation data exported successfully!", {
        position: "top-center",
        autoClose: 1000,
      });

    } catch (error) {
      console.error("Fetch error: ", error);

      // Show error toast message
      toast.error(error.message || 'An error occurred during export');

      setError(error.message || 'An error occurred');
    } finally {
      setLoading(false); // Hide loading state
    }
  };

  function convertToCSV(data) {
    const array = [Object.keys(data[0])].concat(data);

    return array.map(row => {
      return Object.values(row).map(value =>
        typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value
      ).join(',');
    }).join('\n');
  }

  const columns = [

    {
      field: 'id',
      headerName: 'Sr.', align: 'center', headerAlign: 'center',
      width: 50,
      flex: 1,
      renderCell: (params) => (
        <span>
          {resignationData.indexOf(params.row) + 1}
        </span>
      ),
    },
    { field: 'employee_name', headerName: 'Employee name', width: 100, flex: 3, align: 'center', headerAlign: 'center', },
    { field: 'reason', headerName: 'Reason', width: 210, align: 'center', headerAlign: 'center', flex: 3, },
    { field: 'notice_date', headerName: 'Notice Date', width: 210, align: 'center', headerAlign: 'center', flex: 1, },
    { field: 'resignation_date', headerName: 'Resignation Date', width: 210, align: 'center', headerAlign: 'center', flex: 1, },
    {
      field: 'actions', headerName: 'Actions', width: 210, align: 'center', flex: 1, headerAlign: 'center', renderCell: (params) => (


        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-trash3-fill delete delete-button"
          viewBox="0 0 16 16"
          data-id={params.row._id}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const id = params.row._id;
            openConfirmationModal(id);
          }}
        >
          <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
        </svg>

      )
    },
  ];

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  // const filterRows = () => {
  //   if (!searchText) {
  //     return [...resignationData].reverse();
  //   }

  //   return [...resignationData].reverse().filter((row) =>
  //     Object.values(row).some(
  //       (value) =>
  //         typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
  //     )
  //   );
  // };


  const handleDeleteResignation = async (id, masterId) => {
    setDeleteResignationId(id);
    setShowConfirmation(true);

    try {
      const response = await deleteResignation(id, masterId);
      if (response && response.status === 'success') {
        if (!toast.isActive('resignationDeletedToast')) {
          toast.success('Resignation deleted successfully', { toastId: 'resignationDeletedToast' });
        }
        fetchData();
      } else {
        // Handle failure case
      }
    } catch (error) {
      console.error('Error deleting Resignation:', error);
    }
  };




  const createActionButton = (data) => {
    const id = data[0];

    const viewButton = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill view" viewBox="0 0 16 16" style="cursor: pointer;">
    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
    </svg>`;

    const editButton = `
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-pencil-fill edit edit-button"
          viewBox="0 0 16 16"
          data-id="${id}"
          data-masterId="${masterId}"
          style="cursor: pointer;">
          <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
        </svg>
      `;


    const deleteButton = `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill delete delete-button" viewBox="0 0 16 16" 
    data-id="${id}" style="cursor: pointer;">
    //   <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
    // </svg>
    `;


    return ` ${editButton} <span style="margin-right: 8px;"></span> ${deleteButton} <span style="margin-right: 8px;"></span> ${viewButton} <span style="margin-right: 8px;"></span> `;
  };

  const openConfirmationModal = (id) => {
    setDeleteResignationId(id);
    setShowConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setShowConfirmation(false);
  };

  return (
    <React.Fragment>
      {/* Header components */}
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        {/* Breadcrumb section */}
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              {/* <li className="mx-2">
                    <h4>Holidays</h4>
                  </li> */}
              <li className="breadcrumb-item ">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active"><Link to="/admin/resignation ">Resignation</Link></li>
              {/* <li className="breadcrumb-item active">Lists</li> */}
            </ol>
          </div>
          <nav aria-label="breadcrumb">
            {/* <Button variant="primary" className="mb-3">
              <Link to="/pages/hr/resignationform">
                <span style={{ color: "white" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                  </svg>
                  Create Resigantion
                </span>
              </Link>
            </Button> */}
          </nav>
        </div>
        <Card className="popup-card empHistory">
          <Card.Body>
            <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', justifyContent: 'space-between', marginBottom: "1rem" }}>
              <Button className='bg-success border-0' onClick={handleExportResignation}>Export Data to Excel Sheet</Button>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <label htmlFor="searchInput"></label>
                <input
                  type="text"
                  className='p-1 w-75 ms-2'
                  placeholder="Search"
                  value={searchText}
                  onChange={handleSearch}
                />
              </div>
            </div>
            {loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (
              // <div style={{ height: '100%', width: '100%' }}>
              //   <DataGrid
              //     rows={resignationData}
              //     columns={columns}
              //     pageSize={5}
              //     responsive
              //     autoHeight
              //     // checkboxSelection
              //     getRowId={(row) => row._id}
              //     components={{
              //       NoRowsOverlay: () => <div style={{ textAlign: 'center', padding: '20px' }}>No data available</div>,
              //     }}
              //   />
              // </div>

              // <div style={customStyles.grid}>
              //   <DataGrid
              //     className="gridColor"
              //     rows={resignationData}
              //     columns={columns}
              //     pageSize={pageSize}
              //     pagination
              //     paginationMode="client"
              //     getRowId={(row) => row._id}
              //     components={{
              //       Pagination: () => (
              //         // <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px' }}>
              //         <div className="custom-select-container bg-transparent box-shadow">
              //           <div>
              //             <label htmlFor="page-size">Rows per page: </label>
              //             <select
              //               id="page-size"
              //               value={pageSize}
              //               onChange={handlePageSizeChange}
              //             >
              //               <option value={5}>5</option>
              //               <option value={10}>10</option>
              //               <option value={20}>20</option>
              //               <option value={50}>50</option>
              //             </select>
              //           </div>
              //           <div>
              //             <label htmlFor="page-number">Page: </label>
              //             <select
              //               className="page-number"
              //               id="page-number"
              //               value={page}
              //               onChange={handlePageChange}
              //             >
              //               {[...Array(totalPages).keys()].map((pageNumber) => (
              //                 <option key={pageNumber} value={pageNumber + 1}>
              //                   {pageNumber + 1}
              //                 </option>
              //               ))}
              //             </select>
              //           </div>
              //         </div>
              //       ),
              //       NoRowsOverlay: () => <div style={{ textAlign: 'center', padding: '20px' }}>No data available</div>,
              //     }}
              //     componentsProps={{
              //       cell: { style: customStyles.cell },
              //       columnHeader: { style: customStyles.columnHeader },
              //     }}
              //     rowCount={count}
              //     page={page - 1}
              //     onPageChange={(newPage) => setPage(newPage + 1)}
              //     rowsPerPageOptions={[]} // Avoid displaying the default rows per page options
              //   />
              // </div>

              // <div className="data-grid-container" style={customStyles.grid}>
              //   <DataGrid
              //     className="gridColor"
              //     rows={resignationData} // Use resignationData for the grid rows
              //     columns={columns} // Your defined columns
              //     pageSize={pageSize} // Current page size
              //     pagination
              //     paginationMode="client"
              //     getRowId={(row) => row.id} // Use 'id' as the unique identifier
              //     components={{
              //       Pagination: () => (
              //         <div className="custom-select-container">
              //           <div>
              //             <label htmlFor="page-size">Rows per page: </label>
              //             <select
              //               id="page-size"
              //               value={pageSize}
              //               onChange={handlePageSizeChange} // Handle page size changes
              //             >
              //               <option value={5}>5</option>
              //               <option value={10}>10</option>
              //               <option value={20}>20</option>
              //               <option value={50}>50</option>
              //               <option value={100}>100</option>
              //             </select>
              //           </div>
              //           <div>
              //             <label htmlFor="page-number">Page: </label>
              //             <select
              //               className="page-number"
              //               id="page-number"
              //               value={page}
              //               onChange={handlePageChange} // Handle page changes
              //             >
              //               {[...Array(totalPages).keys()].map((pageNumber) => (
              //                 <option key={pageNumber} value={pageNumber + 1}>
              //                   {pageNumber + 1}
              //                 </option>
              //               ))}
              //             </select>
              //           </div>
              //         </div>
              //       ),
              //       NoRowsOverlay: () => (
              //         <div style={{ textAlign: 'center', padding: '20px' }}>
              //           No Resignation available
              //         </div>
              //       ),
              //     }}
              //     componentsProps={{
              //       cell: { style: customStyles.cell }, // Your custom cell styles
              //       columnHeader: { style: customStyles.columnHeader }, // Your custom header styles
              //     }}
              //     rowCount={resignationData.length} // Use the length of resignationData to show total records
              //     page={page - 1} // Current page (0-based index)
              //     onPageChange={(newPage) => setPage(newPage + 1)} // Handle page change
              //     rowsPerPageOptions={[]} // Avoid displaying the default rows per page options
              //   />
              // </div>

              <div className="data-grid-container" style={customStyles.grid}>
                <DataGrid
                  className="gridColor"
                  rows={resignationData} // Use resignationData for the grid rows
                  columns={columns.map((col) => ({
                    ...col,
                    sortable: false, // Disable sorting for all columns
                    filterable: false, // Disable filtering for all columns
                  }))}
                  pageSize={pageSize} // Current page size
                  disableColumnMenu // Disable the column menu button (removes triple dots)
                  sortingOrder={[]} // Disable all sorting orders (removes up/down arrows)
                  pagination={false} // Disable pagination buttons if needed
                  getRowId={(row) => row.id} // Use 'id' as the unique identifier
                  components={{
                    NoRowsOverlay: () => (
                      <div style={{ textAlign: 'center', padding: '20px' }}>
                        No Resignation available
                      </div>
                    ),
                  }}
                  componentsProps={{
                    cell: { style: customStyles.cell }, // Your custom cell styles
                    columnHeader: { style: customStyles.columnHeader }, // Your custom header styles
                  }}
                  rowCount={resignationData.length} // Use the length of resignationData to show total records
                  rowsPerPageOptions={[]} // Avoid displaying the default rows per page options
                />
              </div>


            )}
          </Card.Body>

        </Card>
      </div>

      <Modal show={showConfirmation} onHide={closeConfirmationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this resignation?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeConfirmationModal}>
            Cancel
          </Button>

          <Button
            variant="danger"
            onClick={() => {
              handleDeleteResignation(deleteResignationId, masterId);
              closeConfirmationModal();
            }}

          >
            Delete
          </Button>

        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};


export default ViewResignation
