import React, { useState } from "react";
import Header from "../../layouts/Header";
import { Link, useNavigate } from "react-router-dom";
import '../../styles/globals.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css"; // Import date picker CSS
import { CountryDropdown, countryDropdown } from "react-country-region-selector";
import { addJobDetails, BASE_URL } from "../../services/api/api";
import { toast } from "react-toastify";

const Jobform = () => {
  const getCurrentDateTime = () => {
    const now = new Date();
    return now.toISOString().slice(0, 19).replace("T", " "); // Format: "YYYY-MM-DD HH:MM:SS"
  };

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    jobTitle: "",
    jobType: "",
    companyName: "",
    schedule: getCurrentDateTime(), // Initialize with the current date
    numberOfOpening: "",
    payTotal: "",
    country: "",
    supplementalPay: "",
    language: "",
    benefits: "",
    advertisingLocation: "",
    jobDescription: "",
    keyResponsibilities: '',
    requirements: '',
    location: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const languages = [
    { value: 'af', label: 'Afrikaans' },
    { value: 'sq', label: 'Albanian' },
    { value: 'ar', label: 'Arabic' },
    { value: 'hy', label: 'Armenian' },
    { value: 'bn', label: 'Bengali' },
    { value: 'bs', label: 'Bosnian' },
    { value: 'ca', label: 'Catalan' },
    { value: 'hr', label: 'Croatian' },
    { value: 'cs', label: 'Czech' },
    { value: 'da', label: 'Danish' },
    { value: 'nl', label: 'Dutch' },
    { value: 'en', label: 'English' },
    { value: 'eo', label: 'Esperanto' },
    { value: 'et', label: 'Estonian' },
    { value: 'tl', label: 'Filipino' },
    { value: 'fi', label: 'Finnish' },
    { value: 'fr', label: 'French' },
    { value: 'de', label: 'German' },
    { value: 'el', label: 'Greek' },
    { value: 'gu', label: 'Gujarati' },
    { value: 'hi', label: 'Hindi' },
    { value: 'hu', label: 'Hungarian' },
    { value: 'is', label: 'Icelandic' },
    { value: 'id', label: 'Indonesian' },
    { value: 'it', label: 'Italian' },
    { value: 'ja', label: 'Japanese' },
    { value: 'jw', label: 'Javanese' },
    { value: 'kn', label: 'Kannada' },
    { value: 'km', label: 'Khmer' },
    { value: 'ko', label: 'Korean' },
    { value: 'la', label: 'Latin' },
    { value: 'lv', label: 'Latvian' },
    { value: 'lt', label: 'Lithuanian' },
    { value: 'mk', label: 'Macedonian' },
    { value: 'ml', label: 'Malayalam' },
    { value: 'mr', label: 'Marathi' },
    { value: 'my', label: 'Myanmar' },
    { value: 'ne', label: 'Nepali' },
    { value: 'no', label: 'Norwegian' },
    { value: 'pl', label: 'Polish' },
    { value: 'pt', label: 'Portuguese' },
    { value: 'pa', label: 'Punjabi' },
    { value: 'ro', label: 'Romanian' },
    { value: 'ru', label: 'Russian' },
    { value: 'sr', label: 'Serbian' },
    { value: 'si', label: 'Sinhala' },
    { value: 'sk', label: 'Slovak' },
    { value: 'sl', label: 'Slovenian' },
    { value: 'es', label: 'Spanish' },
    { value: 'su', label: 'Sundanese' },
    { value: 'sw', label: 'Swahili' },
    { value: 'sv', label: 'Swedish' },
    { value: 'ta', label: 'Tamil' },
    { value: 'te', label: 'Telugu' },
    { value: 'th', label: 'Thai' },
    { value: 'tr', label: 'Turkish' },
    { value: 'uk', label: 'Ukrainian' },
    { value: 'ur', label: 'Urdu' },
    { value: 'vi', label: 'Vietnamese' },
    { value: 'cy', label: 'Welsh' },
    { value: 'xh', label: 'Xhosa' },
    { value: 'yi', label: 'Yiddish' },
    { value: 'zu', label: 'Zulu' },
  ];


  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    // Handle checkbox separately if you have any checkboxes
    const newValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));

    console.log(formData);

  };






  const validateForm = () => {
    const errors = {};

    if (!formData.jobTitle) errors.jobTitle = "Job Title is required.";
    if (!formData.companyName) errors.companyName = "Company Name is required.";
    if (!formData.numberOfOpening || formData.numberOfOpening <= 0) errors.numberOfOpening = "Number of Openings must be greater than 0.";
    if (!formData.country) errors.country = "Country is required.";
    if (!formData.language) errors.language = "Language is required.";
    if (!formData.advertisingLocation) errors.advertisingLocation = "Advertising Location is required.";
    if (!formData.location) errors.location = "Location is required.";
    if (!formData.jobType) errors.jobType = "Job Type is required.";
    if (!formData.schedule) errors.schedule = "Schedule is required.";
    if (!formData.payTotal) errors.payTotal = "Pay is required.";
    if (!formData.supplementalPay) errors.supplementalPay = "Supplemental Pay is required.";
    if (!formData.benefits) errors.benefits = "Benefits are required.";
    if (!formData.jobDescription) errors.jobDescription = "Job Summary is required.";
    if (!formData.keyResponsibilities) errors.keyResponsibilities = "Key Responsibilities are required.";
    if (!formData.requirements) errors.requirements = "Requirements are required.";
    if (!formData.jobDescription) errors.jobDescription = "Job Description is required.";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };


  // const handleSubmit = async () => {
  //   try {
  //     const formattedIds = ids[0] || ids;
  //     let payload = {
  //       ...formattedIds,
  //       totalViolance,
  //       ...updatePayload,  // Spread updatePayload here to include comment and violationFine
  //     };

  //     console.log('Formatted IDS:', formattedIds);
  //     console.log('Payload:', payload);

  //     const response = await updatebreakdetails(payload);

  //     console.log('Response:', response);
  //     if (response) {
  //       toast.success('Break Updated Successfully', {
  //         position: "top-center",
  //         autoClose: 1000,
  //       });
  //     }

  //   } catch (error) {
  //     console.error('Error updating data:', error);
  //   } finally {
  //     setLoading(false);
  //     setFlag(flag => flag + 1); // Increment the counter to force a re-render
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    // Basic validation (you can expand this as needed)
    const errors = {};
    if (!formData.jobTitle) {
      errors.jobTitle = "Job Title is required.";
    }
    if (!formData.jobType) {
      errors.jobType = "Job Type is required.";
    }
    if (!formData.companyName) {
      errors.companyName = "Company Name is required.";
    }
    if (formData.numberOfOpening <= 0) {
      errors.numberOfOpening = "Number of Openings must be greater than zero.";
    }
    if (!formData.country) {
      errors.country = "Country is required.";
    }
    if (!formData.language) {
      errors.language = "Language is required.";
    }
    if (!formData.advertisingLocation) {
      errors.advertisingLocation = "Advertising Location is required.";
    }
    if (!formData.location) {
      errors.location = "Location is required.";
    }
    if (!formData.schedule) {
      errors.schedule = "Schedule is required.";
    }
    if (!formData.payTotal) {
      errors.payTotal = "Pay Total is required.";
    }
    if (!formData.supplementalPay) {
      errors.supplementalPay = "Supplemental Pay is required.";
    }
    if (!formData.benefits) {
      errors.benefits = "Benefits are required.";
    }
    if (!formData.keyResponsibilities) {
      errors.keyResponsibilities = "Key Responsibilities are required.";
    }
    if (!formData.keySkills) {
      errors.keySkills = "Key Skills are required.";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return; // Stop the submission if there are errors
    }

    try {
      const response = await addJobDetails(formData);
      console.log(response);

      if (response) {
        toast.success('Job Added Successfully', {
          position: "top-center",
          autoClose: 1000,
        });
        navigate('/pages/recruitment/job')
      }

      console.log('Form submitted successfully!');
      setFormData({}); // Reset form data if needed
      setFormErrors({}); // Clear any existing errors
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle submission error (e.g., show an error message)
    }
  };


  return (
    <React.Fragment>
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              <li className="breadcrumb-item">
                <Link to="/dashboard/helpdesk">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to="/pages/recruitment/job">Jobs</Link>
              </li>
              <li className="breadcrumb-item active">Add Job</li>
            </ol>
          </div>
          <nav aria-label="breadcrumb">
            <Link to="/pages/recruitment/job" className="btn btn-danger mb-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
              </svg>
              Back
            </Link>
          </nav>
        </div>




        <div className="row" style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", borderRadius: "10px", overflow: "hidden" }}>
          <div className="col-md-12">
            <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
              <form onSubmit={handleSubmit} className="form-labels">
                {/* First Row */}
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="jobTitle" className="form-label">Job Title</label>
                    <input
                      type="text"
                      className={`form-control ${formErrors.jobTitle && "is-invalid"}`}
                      id="jobTitle"
                      name="jobTitle"
                      value={formData.jobTitle} // Uncommented for controlled input
                      onChange={handleInputChange}
                    />
                    {formErrors.jobTitle && <div className="invalid-feedback">{formErrors.jobTitle}</div>}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="jobType" className="form-label">Job Type</label>
                    <select
                      className={`form-select ${formErrors.jobType && "is-invalid"}`}
                      id="jobType"
                      name="jobType"
                      value={formData.jobType} // Uncommented for controlled input
                      onChange={handleInputChange}
                    >
                      <option value="">Select Job Type</option>
                      <option value="Full-Time">Full-Time</option>
                      <option value="Part-Time">Part-Time</option>
                    </select>
                    {formErrors.jobType && <div className="invalid-feedback">{formErrors.jobType}</div>}
                  </div>
                </div>

                {/* Second Row */}
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="companyName" className="form-label">Company Name</label>
                    <input
                      type="text"
                      className={`form-control ${formErrors.companyName && "is-invalid"}`}
                      id="companyName"
                      name="companyName"
                      value={formData.companyName} // Uncommented for controlled input
                      onChange={handleInputChange}
                    />
                    {formErrors.companyName && <div className="invalid-feedback">{formErrors.companyName}</div>}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="numberOfOpening" className="form-label">Number of Openings</label>
                    <input
                      type="number"
                      className={`form-control ${formErrors.numberOfOpening && "is-invalid"}`}
                      id="numberOfOpening"
                      name="numberOfOpening"
                      value={formData.numberOfOpening} // Uncommented for controlled input
                      onChange={handleInputChange}
                    />
                    {formErrors.numberOfOpening && <div className="invalid-feedback">{formErrors.numberOfOpening}</div>}
                  </div>
                </div>

                {/* Third Row */}
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="country" className="form-label">Country</label>
                    <CountryDropdown
                      className={`form-control ${formErrors.country && 'is-invalid'}`}
                      name="country"
                      value={formData.country}
                      onChange={(val) => handleInputChange({ target: { name: 'country', value: val } })} // Pass the right object to handleInputChange
                      id="country"
                    />
                    {formErrors.country && <div className="invalid-feedback">{formErrors.country}</div>}
                  </div>



                  
                  <div className="col-md-6 mb-3">
                    <label htmlFor="language" className="form-label">Language</label>
                    <select
                      className={`form-select ${formErrors.language && "is-invalid"}`}
                      id="language"
                      name="language"
                      value={formData.language} // Uncommented for controlled input
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>Select Language</option>
                      {languages.map((lang) => (
                        <option key={lang.value} value={lang.value}>
                          {lang.label}
                        </option>
                      ))}
                    </select>
                    {formErrors.language && <div className="invalid-feedback">{formErrors.language}</div>}
                  </div>
                </div>

                {/* Fourth Row */}
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="advertisingLocation" className="form-label">Advertising Location</label>
                    <input
                      type="text"
                      className={`form-control ${formErrors.advertisingLocation && "is-invalid"}`}
                      id="advertisingLocation"
                      name="advertisingLocation"
                      value={formData.advertisingLocation} // Uncommented for controlled input
                      onChange={handleInputChange}
                    />
                    {formErrors.advertisingLocation && <div className="invalid-feedback">{formErrors.advertisingLocation}</div>}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="location" className="form-label">Location</label>
                    <input
                      type="text"
                      className={`form-control ${formErrors.location && "is-invalid"}`}
                      id="location"
                      name="location"
                      value={formData.location} // Uncommented for controlled input
                      onChange={handleInputChange}
                    />
                    {formErrors.location && <div className="invalid-feedback">{formErrors.location}</div>}
                  </div>
                </div>


                {/* Fifth Row */}
                <div className="row">
                  {/* <div className="col-md-6 mb-3">
                    <label htmlFor="schedule" className="form-label">Schedule</label>
                    <DatePicker
                      selected={formData.schedule}
                      onChange={(date) => setFormData({ ...formData, schedule: date })} // This should be adjusted if needed
                      className={`form-control ${formErrors.schedule && "is-invalid"}`}
                      id="schedule"
                      name="schedule"
                    />
                    {formErrors.schedule && <div className="invalid-feedback">{formErrors.schedule}</div>}
                  </div> */}

                  <div className="col-md-6 mb-3">
                    <label htmlFor="payTotal" className="form-label">Pay Total</label>
                    <input
                      type="text"
                      className={`form-control ${formErrors.payTotal && "is-invalid"}`}
                      id="payTotal"
                      name="payTotal"
                      value={formData.payTotal} // Uncommented for controlled input
                      onChange={handleInputChange}
                    />
                    {formErrors.payTotal && <div className="invalid-feedback">{formErrors.payTotal}</div>}
                  </div>
                </div>

                {/* Sixth Row */}
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="supplementalPay" className="form-label">Supplemental Pay</label>
                    <input
                      type="text"
                      className={`form-control ${formErrors.supplementalPay && "is-invalid"}`}
                      id="supplementalPay"
                      name="supplementalPay"
                      value={formData.supplementalPay} // Uncommented for controlled input
                      onChange={handleInputChange}
                    />
                    {formErrors.supplementalPay && <div className="invalid-feedback">{formErrors.supplementalPay}</div>}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="benefits" className="form-label">Benefits</label>
                    <input
                      type="text"
                      className={`form-control ${formErrors.benefits && "is-invalid"}`}
                      id="benefits"
                      name="benefits"
                      value={formData.benefits} // Uncommented for controlled input
                      onChange={handleInputChange}
                    />
                    {formErrors.benefits && <div className="invalid-feedback">{formErrors.benefits}</div>}
                  </div>
                </div>

                {/* Seventh Row */}
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="keyResponsibilities" className="form-label">Key Responsibilities</label>
                    <textarea
                      className={`form-control ${formErrors.keyResponsibilities && "is-invalid"}`}
                      id="keyResponsibilities"
                      name="keyResponsibilities"
                      value={formData.keyResponsibilities} // Uncommented for controlled input
                      onChange={handleInputChange}
                      rows="4"
                    />
                    {formErrors.keyResponsibilities && <div className="invalid-feedback">{formErrors.keyResponsibilities}</div>}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="keySkills" className="form-label">Key Skills</label>
                    <textarea
                      className={`form-control ${formErrors.keySkills && "is-invalid"}`}
                      id="keySkills"
                      name="keySkills"
                      value={formData.keySkills} // Uncommented for controlled input
                      onChange={handleInputChange}
                      rows="4"
                    />
                    {formErrors.keySkills && <div className="invalid-feedback">{formErrors.keySkills}</div>}
                  </div>
                </div>

                <button type="submit" className="btn btn-primary">Submit</button>
              </form>


            </div >
          </div>
        </div>
      </div>
    </React.Fragment >
  );
};

export default Jobform;
