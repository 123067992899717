import React, { useState, useEffect, useRef } from 'react';
import _debounce from 'lodash/debounce';
import _ from 'lodash';
import { Card } from 'react-bootstrap';
import { DataGrid } from '@mui/x-data-grid';
import { TextField, Tooltip } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import $ from 'jquery';
import 'datatables.net';
import { getemployeeId, viewEmployee, editEmployee, updateEmployeePassword, deleteEmployee, viewBranch, viewemployeepaggination, BASE_URL, searchviewemployee } from '../../services/api/api';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Modal, OverlayTrigger, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import HeaderMobile from '../../layouts/HeaderMobile';
import Header from '../../layouts/Header';
import * as XLSX from 'xlsx'; // Import XLSX library

const Employees = (props) => {

  // const { selectedShift } = props;

  // useEffect(()=>{
  // console.log(selectedShift);
  // },[])

  const [branches, setBranches] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState('');
  const navigate = useNavigate();
  const tableRef = useRef(null);


  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteEmployeeId, setDeleteEmployeeId] = useState(null);
  const [actualPassword, setActualPassword] = useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);
  const [empId, setEmpid] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [excelData, setExcelData] = useState(null);
  const [location, setLocation] = useState('');
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setpageSize] = useState(100);
  const [count, setCount] = useState(0);
  const pageRef = useRef(page);
  const [error, setError] = useState(null);

  useEffect(() => {
    pageRef.current = page;
  }, [page]);

  const customStyles = {
    grid: {
      border: '1px solid #ddd',
    },
    cell: {
      border: 'none', // Remove all borders
      borderBottom: '1px solid #ddd', // Only horizontal border
      padding: '0.5rem 0.5rem',
      color: 'var(--bs-table-color-state, var(--bs-table-color-type, #000000b8))', // Using custom properties for text color
      backgroundColor: 'white', // Using custom properties for background color
      fontWeight: 'bold', // Making text important
      fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', // Highly standard font family
    },
    columnHeader: {
      border: 'none', // Remove all borders
      borderBottom: '1px solid #ddd', // Only horizontal border
      padding: '0.5rem 0.5rem',
      color: 'var(--bs-table-color-state, var(--bs-table-color-type, #000))', // Using custom properties for text color
      backgroundColor: 'var(--bs-table-bg)', // Using custom properties for background color

      fontWeight: 'bold',
      fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', // Highly standard font family
    },
    lastColumn: {
      borderRight: 'none',
    },
  };

  useEffect(() => {
    if (!searchTerm) {
      fetchData();
    }
  }, [page, pageSize, searchTerm]);

  // const fetchData = async () => {
  //   console.log(page);

  //   try {
  //     setLoading(true);
  //     const response = await viewemployeepaggination(page, pageSize);
  //     console.log(response);

  //     if (!response || !response.success || !response.data) {
  //       throw new Error('Failed to fetch data.');
  //     }

  //     setRows(response.data);
  //     console.log(response.data);

  //     setFilteredRows(response.data);
  //     setTotalPages(response.totalPages);
  //     setpageSize(pageSize);

  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await viewemployeepaggination(page, pageSize);
      console.log(response);

      // Check for a valid response
      if (!response || !response.success || !Array.isArray(response.data)) {
        throw new Error('Failed to fetch data or no data available.');
      }

      // Set rows based on data length
      if (response.data.length === 0) {
        setRows([]); // Set to empty array if no records
        setFilteredRows([]); // Clear filtered rows as well
        console.log('No data available');
      } else {
        setRows(response.data); // Use the response data directly
        setFilteredRows(response.data); // Set filtered rows
      }

      setTotalPages(response.totalPages); // Set total pages
      setpageSize(pageSize); // Set page size
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSearchData = async (searchText) => {
    try {
      setLoading(true);
      const response = await searchviewemployee(searchText);
      console.log(response);

      // Check for a valid response
      if (!response || !response.success || !Array.isArray(response.data)) {
        throw new Error('Failed to fetch data or no data available.');
      }

      setRows(response.data);
      setFilteredRows(response.data);

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };


  const handleFetchData = async () => {
    if (searchTerm.trim()) {
      await fetchSearchData(searchTerm); // Call search API if search term exists
    } else {
      await fetchData(); // Otherwise, call normal employee API
    }
  };

  const handlePageChange = (event) => {
    setPage(parseInt(event.target.value, 10));
    console.log(event.target.value)
  };
  const getToken = () => {
    const token = sessionStorage.getItem('token');
    return token ? `Bearer ${token}` : '';
  };
  const handlePageSizeChange = (event) => {
    setpageSize(parseInt(event.target.value, 10));
  };

  const handleSearch = (event) => {
    const searchText = event.target.value.toLowerCase(); // Convert search term to lowercase
    setSearchTerm(searchText); // Update search term state

    if (searchText) {
      fetchSearchData(searchText);
    } else {
      // If search term is cleared, fetch default data
      fetchData();
    }
  };

  const filterRows = (searchText, rows) => {
    if (!searchText) {
      return rows.slice().reverse(); // Reverse all rows if no searchText
    }

    const filteredRows = rows.filter((row) =>
      row.employeeData.first_name
        .toString()
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );

    return filteredRows.slice().reverse(); // Reverse filtered rows
  };



  const reversedRows = [...rows].reverse();
  console.log(reversedRows);

  const columns = [


    {
      field: 'id',
      headerName: 'Sr.',
      width: 20, align: 'left', headerAlign: 'left',
      flex: 1,
      renderCell: (params) => (
        <span>
          {rows.indexOf(params.row) + 1}

        </span>
      ),
    },
    {
      field: 'image',
      headerName: 'Profile',
      width: 80,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <img src={`${params.row.employeeData.image_path}?cb=${Date.now()}`} style={{ width: '40px', height: '40px', borderRadius: '50%', }} />
      )
    },
    {
      field: 'name', headerName: 'Name', width: 150, align: 'left', headerAlign: 'left', flex: 2, renderCell: (params) => (
        <Tooltip title={`${params.row.employeeData.first_name} ${params.row.employeeData.last_name}`} arrow>
          <span className='ellipsis' style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{params.row.employeeData.first_name} {params.row.employeeData.last_name}</span>
        </Tooltip>
      )
    },
    {
      field: 'email', headerName: 'Email', width: 150, align: 'left', headerAlign: 'left', flex: 2, renderCell: (params) => (
        <span
          data-toggle="tooltip"
          data-placement="top"
          title={params.row.employeeData.email || ''}
          style={{
            whiteSpace: 'nowrap',
            cursor: 'pointer',
            position: 'relative',
          }}
        >
          <Tooltip title={`${params.row.employeeData.email}`} arrow>
            {params.row.employeeData.email ? (params.row.employeeData.email.length > 10 ? `${params.row.employeeData.email.slice(0, 10)}...` : params.row.email) : ''}
            <span
              className="tooltip-text ellipsis"
              style={{
                visibility: 'hidden',
                width: 'auto',
                backgroundColor: 'black',
                color: 'white',
                whiteSpace: 'nowrap',
                textAlign: 'left',
                borderRadius: '6px',
                padding: '5px',
                position: 'absolute',
                zIndex: '1',
                bottom: '125%',
                left: '50%',
                marginLeft: '-60px',
                opacity: '0',
                transition: 'opacity 0.3s',
              }}
            >
              {params.row.employeeData.email || ''}
            </span>
          </Tooltip>
        </span>
      )
    },

    {
      field: 'joining_date',
      headerName: 'Joining Date',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      flex: 2,
      renderCell: (params) => (
        <span>
          {formatDate(params.row.employeeData.joining_date)}
        </span>
      ),
    },


    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      flex: 2,
      renderCell: (params) => (
        <>
          {/* Update Password */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="blue"
            style={{ cursor: 'pointer', marginRight: '15px' }}
            height="16"
            viewBox="0 0 448 512"
            onClick={() => {
              const id = params.row.employeeData._id;
              setSelectedRow(id);
              setIsModalOpen(true);
            }}
          >
            <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64zM160 288h128c8.8 0 16 7.2 16 16s-7.2 16-16 16H160c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
          </svg>
          {/* Delete Employee */}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-pencil-fill edit ebtn"
            viewBox="0 0 16 16"
            data-id={params.row.employeeData._id}
            style={{ cursor: 'pointer', marginRight: "15px" }}
            onClick={() => {
              const id = params.row.employeeData._id;
              navigate(`/edit_employee/${id}`);
              console.log("viewemployee id", id);
            }}
          >
            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-trash3-fill delete delete-button"
            viewBox="0 0 16 16"
            data-id={params.row.employeeData._id}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const id = params.row.employeeData._id;
              openConfirmationModal(id);
            }}
          >
            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
          </svg>
        </>
      ),
    },
  ];

  useEffect(() => {

    const storedBranchId = localStorage.getItem('selectedBranchId');
    if (storedBranchId) {
      setSelectedBranch(storedBranchId);
    }
  }, []);
  const handleChangeBranch = (event) => {
    const selectedBranchId = event.target.value;
    setSelectedBranch(selectedBranchId);

    localStorage.setItem('selectedBranchId', selectedBranchId);
  };

  const [id, setId] = useState('');
  useEffect(() => {
    const id = getemployeeId();
    setId(id);
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const [state, setState] = useState({
    _id: '',
    company_id: '',
    password: '',
  });

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setState((prevState) => ({
      ...prevState,
      password: newPassword,
    }));
  };

  useEffect(() => {
    if (sessionStorage.getItem("userType") !== "employee") {
      const debouncedFetchbranchesData = _.debounce(fetchbranchesData, 200);

      debouncedFetchbranchesData();

      return () => {
        debouncedFetchbranchesData.cancel();

      };
    }
  }, []);

  const fetchbranchesData = async () => {
    try {
      const formattedData = await viewBranch();
      console.log("formattedData", formattedData);

      if (!formattedData || !formattedData.length) {
        throw new Error('Failed to fetch data.');
      }

      const companyBranch = formattedData[0].company_branch;
      const branchsArray = Object.values(companyBranch).map(item => ({
        _id: item._id,
        branch_name: item.branch_name || 'N/A',
      }));
      setBranches(branchsArray);
      setBranchData(branchsArray);

    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  // const handleExportEmployee = async () => {
  //   setLoading(true); // Show loading state

  //   try {
  //     const response = await fetch(`${BASE_URL}/exportEmployeeData`, {
  //       method: 'POST',
  //       headers: {
  //         'Accept': 'application/json',
  //         "Content-Type": "application/json",
  //         "Authorization": getToken(),
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }

  //     const data = await response.json();

  //     // Convert JSON data to CSV
  //     const csvData = convertToCSV(data); // Ensure you have a function to convert data to CSV

  //     // Create a Blob and trigger the download
  //     const blob = new Blob([csvData], { type: 'text/csv' });
  //     const link = document.createElement('a');
  //     link.href = URL.createObjectURL(blob);
  //     link.download = 'employee_data.csv'; // Change the file name as needed
  //     link.click();

  //     // Clean up
  //     URL.revokeObjectURL(link.href);



  //   } catch (error) {
  //     console.error("Fetch error: ", error);
  //     setError(error.message || 'An error occurred');



  //   } finally {
  //     setLoading(false); // Hide loading state
  //   }
  // };

  const handleExportEmployee = async () => {
    setLoading(true); // Show loading state

    try {
      const response = await fetch(`${BASE_URL}/exportEmployeeData`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          "Content-Type": "application/json",
          "Authorization": getToken(),
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      // Convert JSON data to CSV
      const csvData = convertToCSV(data); // Ensure you have a function to convert data to CSV

      // Create a Blob and trigger the download
      const blob = new Blob([csvData], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'employee_data.csv'; // Change the file name as needed
      link.click();

      // Clean up
      URL.revokeObjectURL(link.href);

      // Show success toast message
      toast.success("Employee data exported successfully!", {
        position: "top-center",
        autoClose: 1000,
      });

    } catch (error) {
      console.error("Fetch error: ", error);

      // Show error toast message
      toast.error(error.message || 'An error occurred during export');

      setError(error.message || 'An error occurred');
    } finally {
      setLoading(false); // Hide loading state
    }
  };


  function convertToCSV(data) {
    const array = [Object.keys(data[0])].concat(data);

    return array.map(row => {
      return Object.values(row).map(value =>
        typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value
      ).join(',');
    }).join('\n');
  }



  const handleLocationChange = (event) => {
    const selectedLocation = event.target.value;
    setLocation(selectedLocation);
    fetchData(selectedLocation); // Fetch data immediately when location changes
  };

  const openConfirmationModal = (id) => {
    setDeleteEmployeeId(id);
    setShowConfirmation(true);
  };

  const handleDelete = async (id) => {
    setDeleteEmployeeId(id);
    setShowConfirmation(true);
  };

  const handleDeleteConfirm = async () => {
    setShowConfirmation(false);

    try {
      await deleteEmployee(deleteEmployeeId);
      toast.success('Employee deleted successfully', {
        position: 'top-center',
        autoClose: 1000,
      });
      fetchData(); // Refresh data after deletion
    } catch (error) {
      console.error('Error deleting employee:', error);
      toast.error('An error occurred', {
        position: 'top-right',
        autoClose: 1000,
      });
    }
  };

  const handleDeleteCancel = () => {
    setShowConfirmation(false);
    setDeleteEmployeeId(null);
  };



  const handleActualPasswordChange = (e) => {
    setActualPassword(e.target.value);
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if (updating) {
      return;
    }
    setUpdating(true);

    try {
      // Call your API function to update the employee password
      const response = await updateEmployeePassword(selectedRow, state.password, actualPassword);

      if (response && response.success && response.success.length > 0) {
        toast.success(response.success[0].message, {
          position: 'top-center',
          autoClose: 1000,
        });

        // Close the modal after a successful update
        setIsModalOpen(false);

        // Clear the password field in the state
        setState((prevState) => ({
          ...prevState,
          password: '',
        }));
      } else {
        toast.success('Password updated Successfully', {
          position: 'top-right',
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
      toast.error('An error occurred', {
        position: 'top-right',
        autoClose: 1000,
      });
    } finally {
      setUpdating(false);
      fetchData(); // Refresh data after password update
      setIsModalOpen(false);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <React.Fragment>
      <HeaderMobile />
      <Header
        branches={branches}
        handleLocationChange={handleLocationChange} />
      <div className="main p-4 p-lg-0 mt-0">

        <Card>
          <Card.Body style={{ marginTop: '73px' }}>
            <div className="breadcrumb-warning d-flex justify-content-between ot-card">
              <div>
                <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                  <li class="breadcrumb-item ">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li class="breadcrumb-item active"><Link to="/admin/employees">Employees</Link></li>
                  {/* <li class="breadcrumb-item active">Lists</li> */}
                </ol>
              </div>
              <nav aria-label="breadcrumb"><Link to="/admin/employeeform">
                <div><button className="button-one btn-primary" type="submit"><span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                  </svg>Create Employee</span></button></div>
              </Link>
              </nav>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: "1rem" }}>
              <Button className="bg-success border-0" onClick={() => handleExportEmployee()} style={{ whiteSpace: 'nowrap' }}>
                Export Data to Excel Sheet
              </Button>

              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                <TextField
                  label="Search..."
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearch}

                  size="small"
                  style={{ marginBottom: 9, marginTop: '20px' }}
                />
              </div>

            </div>

            {loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (

              <div style={{ width: '100%' }}>
                <div style={{ marginBottom: 20 }}>

                </div>
                {/* <TextField
label="Search"
value={searchText}
onChange={handleSearch}
variant="outlined"
fullWidth
style={{ marginBottom: '16px' }}
/> */}



                {/* <div style={customStyles.grid}>
                  <DataGrid
                    className="gridColor"
                    rows={filterRows(searchTerm, rows)}
                    columns={columns}
                    pageSize={pageSize}
                    pagination
                    paginationMode="client"
                    getRowId={(row) => row.employeeData._id}
                    components={{
                      Pagination: () => (
                        // <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px' }}>
                        <div className="custom-select-container">
                          <div>
                            <label htmlFor="page-size">Rows per page: </label>
                            <select
                              id="page-size"
                              value={pageSize}
                              onChange={handlePageSizeChange}
                            >
                              <option value={5}>5</option>
                              <option value={10}>10</option>
                              <option value={20}>20</option>
                              <option value={50}>50</option>
                            </select>
                          </div>
                          <div>
                            <label htmlFor="page-number">Page: </label>
                            <select
                              className="page-number"
                              id="page-number"
                              value={page}
                              onChange={handlePageChange}
                            >
                              {[...Array(totalPages).keys()].map((pageNumber) => (
                                <option key={pageNumber} value={pageNumber + 1}>
                                  {pageNumber + 1}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      ),
                    }}
                    componentsProps={{
                      cell: { style: customStyles.cell },
                      columnHeader: { style: customStyles.columnHeader },
                    }}
                    rowCount={count}
                    page={page - 1}
                    onPageChange={(newPage) => setPage(newPage + 1)}
                    rowsPerPageOptions={[]} // Avoid displaying the default rows per page options
                  />
                </div> */}

                <div className="data-grid-container" style={customStyles.grid}>
                  <DataGrid
                    className="gridColor"
                    rows={rows} // The data array to be displayed
                    columns={columns} // Your defined columns
                    pageSize={pageSize} // Current page size
                    pagination
                    paginationMode="client"
                    getRowId={(row) => row.employeeData._id} // Use '_id' as the unique identifier
                    components={{
                      Pagination: () => (
                        <div className="custom-select-container">
                          <div>
                            <label htmlFor="page-size">Rows per page: </label>
                            <select
                              id="page-size"
                              value={pageSize}
                              onChange={handlePageSizeChange} // Handle page size changes
                            >
                              <option value={5}>5</option>
                              <option value={10}>10</option>
                              <option value={20}>20</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                          </div>
                          <div>
                            <label htmlFor="page-number">Page: </label>
                            <select
                              className="page-number"
                              id="page-number"
                              value={page}
                              onChange={handlePageChange} // Handle page changes
                            >
                              {[...Array(totalPages).keys()].map((pageNumber) => (
                                <option key={pageNumber} value={pageNumber + 1}>
                                  {pageNumber + 1}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      ),
                      NoRowsOverlay: () => (
                        <div style={{ textAlign: 'center', padding: '20px' }}>
                          No Employee available
                        </div>
                      ),
                    }}
                    componentsProps={{
                      cell: { style: customStyles.cell }, // Your custom cell styles
                      columnHeader: { style: customStyles.columnHeader }, // Your custom header styles
                    }}
                    rowCount={rows.length} // Use the length of rows to show total records
                    page={page - 1} // Current page (0-based index)
                    onPageChange={(newPage) => setPage(newPage + 1)} // Handle page change
                    rowsPerPageOptions={[]} // Avoid displaying the default rows per page options
                  />
                </div>




              </div>
            )}

          </Card.Body>
        </Card>

        {/* Password Update Modal */}
        <Modal show={isModalOpen} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Update Employee Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleUpdatePassword}>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  New Password
                </label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="form-control"
                  id="password"
                  value={state.password}
                  onChange={handlePasswordChange}
                  required
                />
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="showPassword"
                    checked={showPassword}
                    onChange={() => setShowPassword(!showPassword)}
                  />
                  <label className="form-check-label" htmlFor="showPassword">
                    Show Password
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="actualPassword" className="form-label">
                  Confirm Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="actualPassword"
                  value={actualPassword}
                  onChange={handleActualPasswordChange}
                  required
                />
              </div>
              <div className="text-center">
                <button type="submit" className="btn btn-primary">
                  {updating ? 'Updating...' : 'Update Password'}
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal show={showConfirmation} onHide={handleDeleteCancel}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this employee?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDeleteCancel}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDeleteConfirm}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    </React.Fragment>
  );

};

export default Employees;