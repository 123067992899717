import React, { useState, useEffect } from 'react';
import _debounce from 'lodash/debounce';
import { addLogo, basicSetup, viewLogo } from '../../services/api/api';
import Header from '../../layouts/Header';
import HeaderMobile from '../../layouts/HeaderMobile';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const AddCompanyLogo = () => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchLogo();

  }, [logoUrl]);

  const fetchLogo = async () => {
    try {
      setLoading(true);
      const logoUrl = await viewLogo();
      setLogoUrl(logoUrl);
      setLoading(false);
    } catch (error) {
      toast.error('Error fetching logo. Check console for details', {
        position: 'top-right',
        autoClose: 1000,
      });
      setLoading(false);
    }
  };
  const [showPreview, setShowPreview] = useState(true);

  const handleLogoUpload = async () => {
    try {
      if (!selectedImage) {
        toast.error('Please select an image', {
          position: 'top-center',
          autoClose: 1000,
        });
        return;
      }

      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64Image = reader.result.split(',')[1];

        const formData = new FormData();
        formData.append('logo', base64Image);

        try {
          const response = await addLogo(formData);
          console.log(response);

          if (response.message) {
            toast.success(response.message, {
              position: 'top-center',
              autoClose: 1000,
            });

              // Retrieve stored data from sessionStorage
              const storedSetupData = JSON.parse(sessionStorage.getItem('setupData'));

              // Apply the spread operator to keep the stored values, but update `companyProfile`
              const updateProfileData = {
                  ...storedSetupData, // Spread the stored values
                  companyLogo: "true", // Update companyProfile field
              };
              console.log(updateProfileData);
              

              // Second API call with updated data
              const secondResponse = await basicSetup(updateProfileData);
              console.log(secondResponse);

              if (secondResponse && secondResponse.success) {
                  toast.success('Company details updated successfully', {
                      position: 'top-center',
                      autoClose: 1000,
                  });
              } 

              // navigate('/admin/dashboard');



            const logoUrl = await viewLogo();
            setLogoUrl(logoUrl);
            setShowPreview(false);
            if(logoUrl){
              navigate('/admin/dashboard');
            }
          } else {
            toast.success('Logo added Successfully', {
              position: 'top-center',
              autoClose: 1000,
            });
            const logoUrl = await viewLogo();
            setLogoUrl(logoUrl);
            setShowPreview(false);

          }
        } catch (error) {
          console.error('Error adding logo:', error);
          toast.error('Error adding logo. Check console for details', {
            position: 'top-right',
            autoClose: 1000,
          });
        }
      };

      reader.readAsDataURL(selectedImage);
    } catch (error) {
      console.error('An error occurred while adding logo:', error);
      toast.error('An error occurred while adding logo', {
        position: 'top-right',
        autoClose: 1000,
      });
    }


  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    setShowPreview(true);
  };

  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        <div className="mb-4 border-bottom text-center">
          <h3 className="mb-0 pb- border-bottom custome-btn">Update Logo</h3>
        </div>
        <div>

          {loading &&
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          }

          <div className="row align-items-center">

            <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="logo" className="form-label">
                  <h3>Select Company Logo</h3>
                </label>

                <input
                  type="file"
                  className="form-control visually-hidden"
                  id="logo"
                  name="logo"
                  onChange={handleLogoChange}
                  style={{ display: 'none' }}
                />
                <label htmlFor="logo" className="camera-input-label">
                  <div className="camera-input d-flex align-items-center rounded p-2  border-primary">
                    <div className="camera-icon">
                      <i className="bi bi-camera" style={{ fontSize: '2rem', color: '#555' }}></i>
                    </div>
                    <span style={{ color: '#555', marginTop: '8px', marginLeft: '8px' }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                      </svg>
                    </span>
                  </div>
                </label>

                {selectedImage && (
                  <div className="mt-2">
                    <img
                      alt="Selected Image"
                      width={100}
                      src={URL.createObjectURL(selectedImage)}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-8 d-flex align-items-center">
              {logoUrl && (
                <div>
                  <img alt="Company Logo" style={{ width: '50%', height: '50%', objectFit: 'cover' }} src={logoUrl} />
                </div>
              )}
            </div>

            <div className="col-md-4">
              <button type="button" className="btn btn-primary" onClick={handleLogoUpload}>
                Update logo
              </button>

            </div>
          </div>
















        </div>
      </div>
    </React.Fragment>
  );
};

export default AddCompanyLogo;












