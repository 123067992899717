import React, { useState } from "react";
import { Form, Button, Modal, Table } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addSalarySetup } from "../../services/api/api";
import { Link, useNavigate } from "react-router-dom";
import HeaderMobile from "../../layouts/HeaderMobile";
import Header from "../../layouts/Header";

const SalaryStructure = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    basicItems: [
      { type: "Basic", name: "CTC", deduct_type: "Fixed" },
    ],
    allowanceItems: [
      { type: "Allowance", name: "Driver All.", deduct_type: "Fixed", default: true },
      { type: "Allowance", name: "HRA", deduct_type: "Fixed", default: true },
      { type: "Allowance", name: "Conv. All", deduct_type: "Fixed", default: true },
      { type: "Allowance", name: "Sp. Allo", deduct_type: "Fixed", default: true },
      { type: "Allowance", name: "Incentive", deduct_type: "Fixed", default: true },
    ],
    deductionItems: [
      { type: "Deduction", name: "PF", deduct_type: "Fixed", default: true },
      { type: "Deduction", name: "ESI", deduct_type: "Fixed", default: true },
      { type: "Deduction", name: "Proff. Tax", deduct_type: "Fixed", default: true },
      { type: "Deduction", name: "L.W.F", deduct_type: "Fixed", default: true },
      { type: "Deduction", name: "T.D.S", deduct_type: "Fixed", default: true },
    ],
  });

  const [showModal, setShowModal] = useState(false);
  const [modalAction, setModalAction] = useState("add");
  const [selectedType, setSelectedType] = useState("");
  const [newItem, setNewItem] = useState({ name: "", deduct_type: "", type: '' });
  const [editItemDetails, setEditItemDetails] = useState({
    name: '',
    type: '',
  });

  // Delete Modal States
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItemName, setDeleteItemName] = useState("");
  const [deleteType, setDeleteType] = useState("Allowance");
  const [selectedItem, setSelectedItem] = useState(null);


  const handleShowModal = (action, name, deduct_type, type) => {
    console.log(selectedType);

    setModalAction(action);
    setEditItemDetails({ name, type });
    if (type) {
      setSelectedType(type)
    }
    console.log(selectedItem);
    if (name && deduct_type && type) {

      setNewItem({ name: name, deduct_type: deduct_type, type: type });
      setSelectedItem({ name, deduct_type, type });

    }
    else {
      setNewItem({ name: '', deduct_type: "Fixed", type: '' });
      setSelectedType('')
    }
    setShowModal(true);
  };

  // const handleCloseModal = () => setShowModal(false);
  const handleCloseModal = () => {
    console.log(newItem);
    console.log(selectedType);
    setSelectedType('')

    setShowModal(false);
    // setNewItem({ name: "", deduct_type: "Fixed" }); // Reset the newItem state
    setEditItemDetails({ name: '', type: '' }); // Reset editItemDetails state
    setNewItem({ name: '', deduct_type: 'fixed', type: '' })

  };

  const handleModalSave = async (e) => {
    const item = { type: selectedType, ...selectedItem };
    const additem = { type: selectedType, ...newItem };
    console.log(additem);


    let updatedItems;

    if (modalAction === "edit") {
      // Determine which items array to update based on selectedType
      if (selectedType === "Allowance") {
        updatedItems = formData.allowanceItems.map((existingItem) => {
          if (existingItem.name === selectedItem.name) {
            return { ...existingItem, ...selectedItem };
          }
          return existingItem;
        });
      } else if (selectedType === "Deduction") {
        updatedItems = formData.deductionItems.map((existingItem) => {
          if (existingItem.name === selectedItem.name) {
            return { ...existingItem, ...selectedItem };
          }
          return existingItem;
        });
      } else {
        // Handle the case for basicItems
        updatedItems = formData.basicItems.map((existingItem) => {
          if (existingItem.name === selectedItem.name) { // Assuming basicItems match by name only
            return { ...existingItem, ...selectedItem };
          }
          return existingItem;
        });
      }

      console.log(updatedItems);

      setFormData((prevState) => ({
        ...prevState,
        [selectedType === "Allowance" ? 'allowanceItems' : selectedType === "Deduction" ? 'deductionItems' : 'basicItems']: updatedItems,
      }));
    } else {
      // Adding new item logic remains the same
      console.log(selectedType);

      if (selectedType == "Allowance") {
        setFormData((prevState) => ({
          ...prevState,
          allowanceItems: [...prevState.allowanceItems, { ...additem, default: additem.default || false }],
        }));
      } else if (selectedType == "Deduction") {
        setFormData((prevState) => ({
          ...prevState,
          deductionItems: [...prevState.deductionItems, { ...additem, default: additem.default || false }],
        }));
      } else {
        // Adding to basicItems
        setFormData((prevState) => ({
          ...prevState,
          basicItems: [...prevState.basicItems, { ...additem, default: additem.default || false }],
        }));
      }
    }

    // Log the updated formData
    console.log("Updated formData:", item);
    console.log("Updated formData:", formData);

    toast.success(`Successfully ${modalAction === "edit" ? "updated" : "added"} ${newItem.name}!`);
    setShowModal(false); // Un-comment this if you want to close the modal




  };

  const handleAddSalarySetup = async (e) => {
    console.log(formData);
    e.preventDefault();
    try {
      const payload = {
        name: formData.name,
        basic: formData.basicItems.map((item) => ({
          name: item.name,
          deduct_type: item.deduct_type,
          default: item.default
        })),
        allowences: formData.allowanceItems.map(item => ({
          name: item.name,
          deduct_type: item.deduct_type,
          default: item.default
        })),
        deductions: formData.deductionItems.map(item => ({
          name: item.name,
          deduct_type: item.deduct_type,
          default: item.default
        })),
      };

      const response = await addSalarySetup(payload);
      console.log("responsejson", response)
      if (response) {
        toast.success('Salary Structure added successfully.');
        // setFormData({
        //   name: '',
        //   basic: [],
        //   deductions: [],
        //   allowences: []
        // });
        navigate("/admin/Viewempsalary");

      }
    } catch (error) {
      console.error(error);
      toast.success('Salary setup added successfully.');
    }

  };



  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
    setDeleteItemName(""); // Reset item name
  };

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleDeleteModalSave = () => {
    // Check if trying to delete a default item
    const isDefault =
      (deleteType === "Allowance" && formData.allowanceItems.some(item => item.name === deleteItemName && item.name.includes("All"))) ||
      (deleteType === "Deduction" && formData.deductionItems.some(item => item.name === deleteItemName && item.name.includes("Tax")));

    if (isDefault) {
      toast.error("You cannot remove default values!"); // Show error toast if trying to delete default values
    } else {
      if (deleteType === "Allowance") {
        setFormData((prevState) => ({
          ...prevState,
          allowanceItems: prevState.allowanceItems.filter(item => item.name !== deleteItemName),
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          deductionItems: prevState.deductionItems.filter(item => item.name !== deleteItemName),
        }));
      }
      toast.success(`${deleteItemName} successfully deleted!`); // Show success toast
      setShowDeleteModal(false);
    }
  };



  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        <h3 className="mb-4">Add Salary Structure</h3>
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              {/* <li className="mx-2">
                  <h4>Holidays</h4>
                </li> */}
              <li class="breadcrumb-item ">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li class="breadcrumb-item active"><Link to="/admin/Viewempsalary">Salary Structure</Link></li>
              {/* <li class="breadcrumb-item active">Lists</li> */}
            </ol>
          </div>
          <nav aria-label="breadcrumb">

            <nav aria-label="breadcrumb">
              <Link to="/admin/Viewempsalary" className="btn btn-danger mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                </svg>
                Back
              </Link>
            </nav>

          </nav>
        </div>

        {/* Add button for new item */}
        <Button variant="primary" onClick={() => handleShowModal("add")} className="me-2">
          Add Salary Name
        </Button>

        {/* Remove button */}
        <Button variant="danger" onClick={handleShowDeleteModal} className="me-2">
          Remove Salary Name
        </Button>

        <Form>
          <Form.Group>
            <Form.Label>Name:</Form.Label>
            <Form.Control
              type="text"
              name="name"
              required
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
          </Form.Group>

          <br />

          <Table bordered hover className="table ">
            <thead>
              <tr style={{ textAlign: 'center' }}>
                <th style={{ fontWeight: 'bold' }}>Sr No.</th>
                <th style={{ fontWeight: 'bold' }}>Type</th>
                <th style={{ fontWeight: 'bold' }}>Name</th>
                <th style={{ fontWeight: 'bold' }}>Add/Deduct Type</th>
                <th style={{ fontWeight: 'bold' }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {formData.basicItems.map((item, index) => (
                <tr key={index} className="bg-info">
                  <td className="bg-info">{index + 1}</td>
                  <td className="bg-info">{item.type}</td>
                  <td className="bg-info">{item.name}</td>
                  <td className="bg-info">{item.deduct_type}</td>
                  <td className="bg-info">
                    <Button variant="link" onClick={() => handleShowModal("edit", item.name, item.deduct_type, item.type)}>
                      <FaEdit color="black" />
                    </Button>
                  </td>
                </tr>
              ))}
              {formData.allowanceItems.map((item, index) => (
                <tr key={index}  >
                  <td className="bg-success">{index + formData.basicItems.length + 1}</td>
                  <td className="bg-success">{item.type}</td>
                  <td className="bg-success">{item.name}</td>
                  <td className="bg-success">{item.deduct_type}</td>
                  <td className="bg-success">
                    <Button variant="link" onClick={() => handleShowModal("edit", item.name, item.deduct_type, item.type)}>
                      <FaEdit color="black" />
                    </Button>
                  </td>
                </tr>
              ))}
              {formData.deductionItems.map((item, index) => (
                <tr key={index}>
                  <td className="bg-danger">{index + formData.basicItems.length + formData.allowanceItems.length + 1}</td>
                  <td className="bg-danger">{item.type}</td>
                  <td className="bg-danger">{item.name}</td>
                  <td className="bg-danger">{item.deduct_type}</td>
                  <td className="bg-danger">
                    <Button variant="link" onClick={() => handleShowModal("edit", item.name, item.deduct_type, item.type)}>
                      <FaEdit color="black" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <br />
          <Button variant="primary" type="submit" onClick={handleAddSalarySetup}>
            Add Salary Structure
          </Button>
        </Form>

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{modalAction == "add" ? "Add Allowance/Deduction" : "Edit Item"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalAction == "add" ? (
              <>
                <Form.Group>
                  <Form.Label>Select Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedType}
                    required
                    onChange={(e) => {
                      setSelectedType(e.target.value);
                      setNewItem({ ...newItem, type: e.target.value })
                      console.log(e.target.value);
                    }}

                  >
                    <option value="" selected disabled>Select</option>
                    <option value="Allowance">Allowance</option>
                    <option value="Deduction">Deduction</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={newItem.name}
                    required
                    onChange={(e) => {
                      const value = e.target.value;
                      setNewItem({ ...newItem, name: value });
                      console.log("New Name:", value); // Log the new name
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Add/Deduct Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={newItem.deduct_type}
                    onChange={(e) => {
                      const value = e.target.value;
                      setNewItem({ ...newItem, deduct_type: value });
                      console.log("New Deduct Type:", value);
                      console.log(newItem);
                    }}
                  >
                    <option value="Fixed">Fixed</option>
                    <option value="Percentage">Percentage</option>
                  </Form.Control>
                </Form.Group>
              </>
            ) : (
              <>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    value={modalAction === "edit" ? selectedItem.name : newItem.name}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (modalAction === "edit") {
                        setSelectedItem({ ...selectedItem, name: value });
                        console.log("Editing Name:", value); // Log the edited name
                      } else {
                        setNewItem({ ...newItem, name: value });
                        console.log("New Name:", value); // Log the new name for add action
                      }
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Add/Deduct Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={modalAction === "edit" ? selectedItem.deduct_type : newItem.deduct_type}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (modalAction == "edit") {
                        setSelectedItem({ ...selectedItem, deduct_type: value });
                        console.log("Editing Deduct Type:", value); // Log the edited deduct type
                      } else {
                        setNewItem({ ...newItem, deduct_type: value });
                        console.log("New Deduct Type:", value); // Log the new deduct type for add action
                      }
                    }}
                  >
                    <option value="Fixed">Fixed</option>
                    <option value="Percentage">Percentage</option>
                  </Form.Control>
                </Form.Group>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleModalSave}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>


        <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Select Type</Form.Label>
              <Form.Control
                as="select"
                value={deleteType}
                onChange={(e) => {
                  setDeleteType(e.target.value);
                  setDeleteItemName(""); // Reset the selected item when type changes
                }}
              >
                <option value="Allowance">Allowance</option>
                <option value="Deduction">Deduction</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Select Item to Delete</Form.Label>
              <Form.Control
                as="select"
                value={deleteItemName}
                onChange={(e) => setDeleteItemName(e.target.value)}
              >
                <option value="">Select Item</option>
                {(deleteType === "Allowance"
                  ? formData.allowanceItems.filter(item => !item.default) // Show non-default allowance items
                  : formData.deductionItems.filter(item => !item.default) // Show non-default deduction items
                ).map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDeleteModal}>
              Close
            </Button>
            <Button variant="danger" onClick={handleDeleteModalSave}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>


        <ToastContainer />
      </div >
    </React.Fragment>
  );
};

export default SalaryStructure;