import React, { useState, useEffect } from "react";
import _debounce from "lodash/debounce";
import { Card, Button, CardBody } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import img05 from "../../assets/img/img05.jpg";
import {
  addEmployeeAttendance,
  checkemployeeattandance,
  editEmployee,
  getId,
  getemployeeId,
  singleViewEmployeeAttendance,
  viewAttendance,
} from "../../services/api/api";

import HeaderMobile from "../../layouts/HeaderMobile";
import Header from "../../layouts/Header";

const Attendance = () => {
  const firstName = sessionStorage.getItem("first_name");
  const lastName = sessionStorage.getItem("last_name");
  const navigate = useNavigate();
  const [employee_id, setEmployeeId] = useState(getemployeeId());
  const [loading, setLoading] = useState(true);
  const [masterId, setMasterId] = useState();
  const [searchParams, setSearchParams] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });
  const [attendanceStatus, setAttendanceStatus] = useState("");
  // const [attendanceStatusd, setAttendanceStatusd] = useState({IN: "",OUT: "",});
  const [attendanceStatusd, setAttendanceStatusd] = useState("");
  const [emptyResponse, setEmptyResponse] = useState(false);
  const [svgIcon, setSvgIcon] = useState(null);
  const [id, setId] = useState("");

  const [checkInTime, setCheckInTime] = useState("");
  const [checkOutTime, setCheckOutTime] = useState("");

  const [apiDate, setApiDate] = useState("");
  const [todayDate, setTodayDate] = useState(new Date());
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    setTodayDate(new Date());
  }, []);

  // useEffect(() => {
  //   setCurrentDate(formatDate(todayDate));
  //   console.log(currentDate);
  // }, [todayDate]);

  // const formatDate = (date) => {
  //   const day = String(date.getDate()).padStart(2, "0");
  //   const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based
  //   const year = date.getFullYear();
  //   return `${day}-${month}-${year}`;
  // };

  useEffect(() => {
    const id = getemployeeId();
    setId(id);
  }, []);

  const [state, setState] = useState({
    _id: "",
    company_id: "",
    first_name: "",
    last_name: "",
    email: "",
    joining_date: "",
    phone: "",
    department: "",
    designation: "",
    password: "",
    salary: "",
    shift: "",
    branch: "",
  });

  useEffect(() => {
    const empId = getId();
    setEmployeeId(empId);
  }, []);

  useEffect(() => {
    const employee_id = getemployeeId();
    setEmployeeId(employee_id);
  }, []);

  useEffect(() => {
    const debouncedFetchEmployeeAttendance = _debounce(
      fetchEmployeeAttendance,
      100
    );

    debouncedFetchEmployeeAttendance();

    return () => {
      debouncedFetchEmployeeAttendance.cancel();
    };
  }, [searchParams]);

  useEffect(() => {
    const status = state.attendance_status === "IN" ? "IN" : "OUT";
    setAttendanceStatus(status);
  }, [state]);

  const [totalWorkHours, setTotalWorkHours] = useState(null);

  const calculateWorkHours = (startTime, endTime) => {
    const start = new Date(`1970-01-01T${startTime}`);
    const end = new Date(`1970-01-01T${endTime}`);
    const timeDiff = end - start;
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours}h ${minutes}m`;
  };

  const fetchEmployeeAttendance = async () => {
    try {
      setLoading(true);
      const employeeId = getId();

      const latestAttendanceType = await checkemployeeattandance(employeeId);
      console.log(
        "************************************************************************",
        latestAttendanceType
      );
      if (
        latestAttendanceType.type == "" ||
        latestAttendanceType.type == "OUT"
      ) {
        setAttendanceStatusd("OUT");
      } else {
        setAttendanceStatusd("IN");
      }

      const apiResponse = await singleViewEmployeeAttendance(
        employeeId,
        searchParams.year,
        searchParams.month
      );
      console.log(apiResponse);

      // setAttendanceStatusd(latestAttendanceType);

      // const dateString = todayDate;
      // const dateObj = new Date(dateString);

      // const day = dateObj.getDate();
      // const month = dateObj.getMonth() + 1;
      // const year = dateObj.getFullYear();

      // const formattedDate = `${day < 10 ? "0" + day : day}-${
      //   month < 10 ? "0" + month : month
      // }-${year}`;

      // let lastObj = apiResponse.data.attendence_list.attendence.slice(-1)[0].date;
      // console.log(lastObj);

      // console.log(formattedDate);
      // console.log(apiResponse.data[0]?.attendance_list[0]?.attendance[0].date);
      // setApiDate(apiResponse.data[0]?.attendance_list[0]?.attendance[0].date);

      // if (apiDate != formattedDate) {
      //   setButtonDisabled(false);
      // } else {
      //   setButtonDisabled(true);
      // }

      // const currentDate = new Date().toISOString().split('T')[0];
      // const latestAttendance = apiResponse.data[0]?.attendance_list[0]?.attendance.find(
      //   (entry) => entry.date === currentDate
      // );
      const latestAttendance =
        apiResponse.data[0]?.attendance_list[0]?.attendance[0];

      const isIn = latestAttendance?.IN !== null;
      const isOut = latestAttendance?.OUT !== null;

      if (isOut) {
        // setAttendanceStatusd('OUT');
        // setAttendanceStatusd("OUT");
        setCheckOutTime(latestAttendance.OUT);
        setTotalWorkHours(
          calculateWorkHours(latestAttendance.IN, latestAttendance.OUT)
        );
        setSvgIcon(
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="350"
            height="150"
            fill="currentColor"
            className="bi bi-door-closed-fill mt-2"
            viewBox="0 0 16 16"
          >
            <path d="M12 1a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V2a1 1 0 0 1 1-1zm-2 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
          </svg>
        );
      } else {
        setAttendanceStatusd("IN");
        setCheckInTime(latestAttendance.IN);
        setTotalWorkHours(null);
        setSvgIcon(
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="350"
            height="150"
            fill="currentColor"
            className="bi bi-building-fill-check mt-2"
            viewBox="0 0 16 16"
          >
            <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514" />
            <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v7.256A4.5 4.5 0 0 0 12.5 8a4.5 4.5 0 0 0-3.59 1.787A.5.5 0 0 0 9 9.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .39-.187A4.5 4.5 0 0 0 8.027 12H6.5a.5.5 0 0 0-.5.5V16H3a1 1 0 0 1-1-1zm2 1.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5m3 0v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5m3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM4 5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5M7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5M3.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z" />
          </svg>
        );
      }
    } catch (error) {
      console.error("Error fetching employee break count:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckInOut = async () => {
    const newAttendanceStatus = attendanceStatusd === "IN" ? "OUT" : "IN";

    try {
      const response = await addEmployeeAttendance({
        employee_id: employee_id,
        attendance_status: newAttendanceStatus,
      });
      const jsonString = JSON.parse(response);
      // console.log('this is the response',jsonString);
      // console.log('this is the response message',jsonString.success);

      setAttendanceStatusd(newAttendanceStatus);

      if (
        jsonString.success == true
        // &&
        // response.attendance_status &&
        // (newAttendanceStatus === "IN"
        //   ? response.attendance_status === "IN"
        //   : response.attendance_status === "OUT")
      ) {
        toast.success(
          `Employee Checked ${newAttendanceStatus === "IN" ? "In" : "Out"
          } successfully`,
          {
            position: "top-center",
            autoClose: 1000,
          }
        );
      } else {
        toast.error(jsonString.message, {
          position: "top-center",
          autoClose: 1000,
        });
      }
      //   toast.success(`Employee attendance status added successfully`, {
      //     position: "top-center",
      //     autoClose: 1000,
      //   }
      // );
    } catch (error) { }
    fetchEmployeeAttendance();
  };

  useEffect(() => {
    const debouncedFetchEmployeeData = _debounce(fetchemployeeData, 100);

    debouncedFetchEmployeeData();

    return () => {
      debouncedFetchEmployeeData.cancel();
    };
  }, [id]);

  async function fetchemployeeData() {
    try {
      const employeeData = await editEmployee(id);
      console.log(employeeData);

      if (
        employeeData &&
        employeeData.success &&
        employeeData.success.length > 0
      ) {
        const employee = employeeData.success[0];

        setState({
          _id: employee._id || "",
          first_name: employee.first_name || "",
          last_name: employee.last_name || "",
        });
      } else {
        console.error("No employee data found");
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  }

  // const formatTime = (istTime) => {
  //   const [hours, minutes] = istTime.split(':').map(Number);
  //   const istDate = new Date();
  //   istDate.setHours(hours, minutes, 0, 0);

  //   const cstDate = new Date(istDate.toLocaleString('en-US', { timeZone: 'America/Chicago' }));

  //   return cstDate.toLocaleTimeString('en-US', {
  //     hour: 'numeric',
  //     minute: 'numeric',
  //     hour12: true,
  //     timeZone: 'America/Chicago',
  //   });
  // };

  const formatTime = (chicagoTime) => {
    const [hours, minutes] = chicagoTime.split(":").map(Number);
    const chicagoDate = new Date();
    chicagoDate.setHours(hours, minutes, 0, 0);

    const istDate = new Date(
      chicagoDate.toLocaleString("en-US", {
        timeZone: "America/Chicago",
      })
    );
    const istTime = istDate.toLocaleString("en-US", {
      timeZone: "Asia/Kolkata",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return istTime;
  };

  // Example usage
  console.log(formatTime("11:50")); // This will convert 11:50 Chicago time to IST

  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        <div className="col-md-12">
          <Card
            style={{
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              borderRadius: "10px",
              overflow: "hidden",
              border: "none",
            }}
          >
            <CardBody>
              <div className="mb-4 border-bottom text-center">
                <h3 className="mb-0 pb- border-bottom custome-btn">
                  Attendance
                </h3>
              </div>

              <div style={{ marginTop: "-17px" }}>
                {loading ? (
                  <div className="loader-container">
                    <div className="loader"></div>
                  </div>
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ marginTop: "-12px" }}
                  >
                    <div
                      className="card p-4 shadow-lg rounded"
                      style={{ maxWidth: "600px" }}
                    >
                      <h2
                        className="text-center mb-0 p-0"
                        style={{
                          backgroundColor: "white",
                          border: "1px solid #ccc",
                        }}
                      >
                        {/* {state.first_name} {state.last_name} */}
                        <h3 class="mb-0 pb-  custome-btn1">
                          {firstName} {lastName}
                        </h3>
                        <img
                          src={img05}
                          alt="User Profile"
                          className="img-fluid rounded-circle"
                          style={{ width: "150px", height: "100px" }}
                        />
                      </h2>

                      <button
                        className="btn btn-warning mt-3"
                        onClick={handleCheckInOut}
                        disabled={isButtonDisabled}
                      >
                        <span className="text-center">
                          <div className="d-flex justify-content-center">
                            {/* {attendanceStatusd === 'IN' && (
                              <h4 className="card-title mt-3">Since: {formatTime(checkInTime) ? formatTime(checkInTime) : '0'}</h4>
                            )} */}
                            {attendanceStatusd === "IN" && (
                              <h4 className="card-title mt-3">
                                Since:{checkInTime ? checkInTime : "0"}
                              </h4>
                            )}
                            {attendanceStatusd === "OUT" &&
                              checkInTime &&
                              checkOutTime && (
                                <h4 className="card-title mt-3">
                                  Total Work Hours: {totalWorkHours}
                                </h4>
                              )}
                          </div>
                          {/* <svg xmlns="http://www.w3.org/2000/svg" width="400" height="90" fill="currentColor" className="bi bi-box-arrow-in-right mt-4" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z" />
                            <path fillRule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z" />
                          </svg> */}
                          <div className="newbtn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="400"
                              height="90"
                              fill="currentColor"
                              className={`bi bi-box-arrow-in-right mt-4 ${attendanceStatusd == "IN"
                                  ? "text-success"
                                  : "text-danger"
                                }`}
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z"
                              />
                              <path
                                fillRule="evenodd"
                                d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
                              />
                            </svg>
                          </div>
                          {/* <h4 className="card-title mt-4">Click to check {attendanceStatusd === 'OUT' ? 'in' : 'out'}</h4> */}
                          <br /> <br />
                          <div className="newbtn">
                            <button class="btn btnnew-light">
                              Click to check {attendanceStatusd == "OUT" ? "IN" : "OUT"}
                            </button>
                          </div>
                          {/* <div className='newbtn'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="400" height="90" fill="currentColor" className={`bi bi-box-arrow-in-right mt-4 ${attendanceStatusd === 'IN' ? 'text-success' : 'text-danger'}`} viewBox="0 0 16 16">
                              <path fillRule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z" />
                              <path fillRule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z" />
                            </svg>
                          </div> */}
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Attendance;
