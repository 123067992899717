
const dashboardMenu = [
  {
    "label": "Dashboard",
    "link": "admin/dashboard",
    "icon": "ri-pie-chart-2-line"
  },

  {
    "label": "Employee Management",
    "icon": "ri-account-circle-line",
    "submenu": [
      {
        "label": "Employees",
        "link": "admin/employees"
      },
      {
        "label": "Employee History",
        "link": "/admin/employeehistory"
      },
    ]
  },




  {
    "label": "HR",
    "icon": "ri-account-circle-line",
    "submenu": [
      {
        "label": "Designations",
        "link": "/admin/designation"
      },
      {
        "label": "Departments",
        "link": "/pages/hr/department"
      },
      {
        "label": "Resignations",
        "link": "/admin/resignation"
      },
      {
        "label": "Holidays",
        "link": "/admin/holidays"
      },
      {
        "label": "Branch",
        "link": "/pages/hr/branch"
      },
      {
        "label": "Shift",
        "link": "/pages/hr/shift"
      },
    ]
  },

  {
    "label": "Break",
    "icon": "ri-account-circle-line",
    "submenu": [
      {
        "label": "Break time",
        "link": "/admin/empbreaktime"
      },

    ]
  },




  {
    "label": "Recruitment",
    "icon": "ri-account-circle-line",
    "submenu": [
      {
        "label": "Jobs",
        "link": "/pages/recruitment/job"
      },
      // {
      //   "label": "Resume",
      //   "link": "/pages/recruitment/resume"
      // },

      // {
      //   "label": "Contract",
      //   "link": "/pages/recruitment/contract"
      // },
      {
        "label": "Applicant",
        "link": "/pages/recruitment/candidate"
      },
      {
        "label": "Interviews",
        "link": "/pages/recruitment/interviews"
      },

    ]
  },
  {
    "label": "Leave",
    "icon": "ri-account-circle-line",
    "submenu": [
      {
        "label": "Leave View",
        "link": "/pages/leave"
      },
    ]
  },
  {
    "label": "File Manager",
    "link": "/pages/filemanager",
    "icon": "ri-folder-2-line"
  },
  // {
  //   "label": "File Manager",
  //   "link": "/apps/file-manager",
  //   "icon": "ri-folder-2-line"
  // },
  {
    "label": "Settings",
    "icon": "ri-account-circle-line",
    "submenu": [
      {
        "label": "Company",
        "link": "/pages/company"
      },
      // {
      //   "label": "Email Setup",
      //   "link": "/pages/email",
      //   "icon": "ri-folder-2-line"
      // },
      {
        "label": "Email Setup",
        "link": "/pages/email",
        "icon": "ri-folder-2-line"
      },
      {
        "label": "User",
        "link": "/pages/userprofile"
      },
      {
        "label": "Working days",
        "link": "/pages/workingdays/viewworkingdays"
      },
      {
        "label": "Automate User",
        "link": "/automateuser"
      },
      {
        "label": "Password Manage",
        "link": "/pages/passwordmanage"
      },
      {
        "label": "Payment Details",
        "link": "/pages/paymentdetails"
      },
      {
        "label": "App Setting",
        "link": "/pages/appsetting"
      },

    ]





  },






  // {
  //   "label": "Storage Management",
  //   "link": "/dashboard/storage",
  //   "icon": "ri-hard-drive-2-line"
  // },
  // {
  //   "label": "Product Management",
  //   "link": "/dashboard/product",
  //   "icon": "ri-suitcase-2-line"
  // }
];




const admindashboardmenu = [
  {
    "label": "Dashboard",
    "link": "admin/dashboard",
    "icon": "ri-pie-chart-2-line"
  },
  // {
  //   "label": "logo",
  //   "link": "/companylogo",
  //   "icon": "ri-pie-chart-2-line"
  // },

  {
    "label": "Employee",
    "icon": "ri-account-circle-line",
    "submenu": [
      {
        "label": "Employees",
        "link": "/admin/employees"
      },
      {
        "label": "Employee History",
        "link": "/admin/employeehistory"
      },
      {
        "label": "Resignations",
        "link": "/admin/resignation"
      },
    ]
  },
  {
    "label": "Payroll",
    "icon": "ri-account-circle-line",
    "submenu": [
      {
        "label": "Salary Structure",
        "link": "admin/Viewempsalary"
      },
      {
        "label": "Salary Setup",
        "link": "admin/empsalarysetup"
      },
      {
        "label": "Employee Salary",
        "link": "admin/employeesalary"
      },

      // {
      //   "label": "Add Salary setup",
      //   "link": "admin/salarysetup"
      // },
      // {
      //   "label": "View Setup",
      //   "link": "/admin/viewemployeesalarysetup"
      // },




      // {
      //   "label": "Emp Salary setup",
      //   "link": "admin/empsalarysetup"
      // },

      {
        "label": "View Credit Leave",
        "link": "admin/viewcreditempleave"
      },

      // {
      //   "label": "Employee Salary form",
      //   "link": "admin/employeesalaryform"
      // },


    ]
  },
  {
    "label": "HR",
    "icon": "ri-account-circle-line",
    "submenu": [
      {
        "label": "Designations",
        "link": "/admin/designation"
      },
      {
        "label": "Departments",
        "link": "/admin/department"
      },
      // {
      //   "label": "Resignations",
      //   "link": "/admin/resignation"
      // },
      {
        "label": "Holidays",
        "link": "/admin/holidays"
      },
      {
        "label": "Attendance",
        "link": "/admin/pages/totalattendance"
      },
      {
        "label": "Monthly Attendance",
        "link": "/admin/pages/employeeattendance"
      },
      {
        "label": "Break",
        "link": "/admin/empbreakdata",
      },
      {
        "label": "Monthly Break",
        "link": "/admin/pages/employeebreak",
      },
      {
        "label": "Leave Type",
        "link": "/pages/leave"
      },
      {
        "label": "Leave",
        "link": "/pages/empleave/empleave"
      },
      // {
      //   "label": "branch",
      //   "link": "/pages/hr/branch"
      // },
      // {
      //   "label": "shift",
      //   "link": "/pages/hr/shift"
      // },
      {
        "label": "Announcement",
        "link": "/admin/announcement"
      },
    ]
  },



  // {
  //   "label": "Attendance",
  //   "icon": "ri-account-circle-line",
  //   "submenu": [
  //     // {
  //     //   "label": "Attendance total list",
  //     //   "link": "/admin/pages/totalattendance"
  //     // },
  //     // {
  //     //   "label": "Employee Attendance",
  //     //   "link": "/admin/pages/employeeattendance"
  //     // },


  //   ]

  // },
  // {
  //   "label": "Break",
  //   "icon": "ri-account-circle-line",
  //   "submenu": [
  //     // {
  //     //   "label": "Employee Break details",
  //     //   "link": "/admin/empbreakdata",
  //     // },
  //     // {
  //     //   "label": "Employee Break",
  //     //   "link": "/admin/pages/employeebreak",
  //     // },


  //   ]

  // },
  {
    "label": "Recruitment",
    "icon": "ri-account-circle-line",
    "submenu": [
      {
        "label": "Jobs",
        "link": "/pages/recruitment/job"
      },
      // {
      //   "label": "Resume",
      //   "link": "/pages/recruitment/resume"
      // },

      // {
      //   "label": "Contract",
      //   "link": "/pages/recruitment/contract"
      // },
      {
        "label": "Invite Applicant",
        "link": "/pages/recruitment/invitecandidate"
      },
      {
        "label": "Applicants",
        "link": "/pages/recruitment/candidate"
      },

      {
        "label": "Interviews",
        "link": "/pages/recruitment/interviews"
      },

    ]
  },
  // {
  //   "label": "Employee Break details",
  //   "link": "/admin/empbreakdata",
  //   "icon": "ri-account-circle-line",
  // },
  // {
  //   "label": "Employee Break",
  //   "link": "/admin/pages/employeebreak",
  //   "icon": "ri-account-circle-line",
  // },


  // {
  //   "label": "Leave",
  //   "icon": "ri-account-circle-line",
  //   "submenu": [
  //     // {
  //     //   "label": "Leave Type",
  //     //   "link": "/pages/leave"
  //     // },
  //     // {
  //     //   "label": "Leave",
  //     //   "link": "/pages/empleave/empleave"
  //     // },
  //   ]
  // },
  // {
  //   "label": "File Manager",
  //   "link": "/filemanager",
  //   "icon": "ri-folder-2-line"
  // },
  // {
  //   "label": "File Manager",
  //   "link": "/apps/file-manager",
  //   "icon": "ri-folder-2-line"
  // },
  {
    "label": "Settings",
    "icon": "ri-account-circle-line",
    "submenu": [
      {
        "label": "Company Profile",
        "link": "/companyprofile"
      },
      {
        "label": "Company Time",
        "link": "/pages/hr/shift"
      },
      {
        "label": "Company Branch",
        "link": "/pages/hr/branch"
      },
      {
        "label": "Employee Breakoff List",
        "link": "/admin/breakofflist"
      },

      {
        "label": "Company Logo",
        "link": "/companylogo",
        "icon": "ri-pie-chart-2-line"
      },
      // {
      //   "label": "Email Setup",
      //   "link": "/pages/email",
      //   "icon": "ri-folder-2-line"
      // },
      // {
      //   "label": "Break of Employee",
      //   "link": "/admin/breaklist",
      //   "icon": "ri-folder-2-line"
      // },
      // {
      //   "label": "User",
      //   "link": "/pages/userprofile"
      // },
      {
        "label": "User",
        "link": "pages/user/viewuser",
        "icon": "ri-pie-chart-2-line"
      },
      {
        "label": "Working Days",
        "link": "/pages/workingdays/viewworkingdays"
      },
      {
        "label": "Automate User",
        "link": "/automateduser"
      },
      {
        "label": "Applicant Status",
        "link": "pages/settings/addapplicantstatus"
      },
      // {
      //   "label": "Password Manage",
      //   "link": "/pages/passwordmanage"
      // },
      // {
      //   "label": "Payment Details",
      //   "link": "/pages/paymentdetails"
      // },
      // {
      //   "label": "App Setting",
      //   "link": "/pages/appsetting"
      // },

    ]





  },






  // {
  //   "label": "Storage Management",
  //   "link": "/dashboard/storage",
  //   "icon": "ri-hard-drive-2-line"
  // },
  // {
  //   "label": "Product Management",
  //   "link": "/dashboard/product",
  //   "icon": "ri-suitcase-2-line"
  // }
];







const employeedashboardMenu = [
  {
    "label": "Dashboard",
    "link": "employee/dashboard",
    "icon": "ri-pie-chart-2-line"
  },


  // {
  //   "label": "Employees",
  //   "icon": "ri-account-circle-line",
  // },

  {
    "label": "Profile",
    "icon": "ri-account-circle-line",
    "link": "/employee/profile"
  },
  {
    "label": "Leave",
    "icon": "ri-account-circle-line",
    "submenu": [
      {
        "label": "Leave",
        "icon": "ri-account-circle-line",
        "link": "employee/addleave"
      },

    ]
  },
  {
    "label": "Attendance",
    "icon": "ri-account-circle-line",
    "submenu": [
      {
        "label": "Employee Attendance",
        "link": "/employee/attendance"
      }, {
        "label": "Attendance View",
        "link": "/employee/attendanceview"
      },

    ]
  },

  {
    "label": "Break",
    "icon": "ri-account-circle-line",
    "submenu": [
      {
        "label": "Take a Break",
        "icon": "ri-account-circle-line",
        "link": "employee/break"
      },
      {
        "label": "Monthly Break Report",
        "link": "/employee/breakview",
        "icon": "ri-pie-chart-2-line"
      },
      {

        "label": "Break Details",
        "link": "/employee/empbreaktime",
        "icon": "ri-pie-chart-2-line"
      },

    ]
  },
  // {

  //   "label": "Monthly Break Report",
  //   "link": "/employee/breakview",
  //   "icon": "ri-pie-chart-2-line"
  // },
  // {

  //   "label": "Break details",
  //   "link": "/employee/empbreaktime",
  //   "icon": "ri-pie-chart-2-line"
  // },
  {

    "label": "Salary Report",
    "link": "/employee/salaryreport",
    "icon": "ri-pie-chart-2-line"
  },


  // {
  //   "label": "Break",
  //   "icon": "ri-account-circle-line",
  //   "submenu": [
  //     {
  //       "label": "Break time",
  //       "link": "/empbreaktime"
  //     },

  //   ]
  // },





  {
    "label": "Apply Resignation",
    "icon": "ri-account-circle-line",
    "link": "/emoloyee/resignation"
  },
  {
    "label": "Change Password",
    "link": "/employee/changepassword",
    "icon": "ri-folder-2-line"
  },

  // {
  //   "label": "File Manager",
  //   "link": "/apps/file-manager",
  //   "icon": "ri-folder-2-line"
  // },







  // {
  //   "label": "Storage Management",
  //   "link": "/dashboard/storage",
  //   "icon": "ri-hard-drive-2-line"
  // },
  // {
  //   "label": "Product Management",
  //   "link": "/dashboard/product",
  //   "icon": "ri-suitcase-2-line"
  // }
];





const applicationsMenu = [
  // {
  //   "label": "File Manager",
  //   "link": "/apps/file-manager",
  //   "icon": "ri-folder-2-line"
  // },
  {
    "label": "Jobs",
    "link": "/apps/jobs",
    "icon": "ri-mail-send-line"
  },
  {
    "label": "Calendar",
    "link": "/apps/calendar",
    "icon": "ri-calendar-line"
  },
  {
    "label": "Chat",
    "link": "/apps/chat",
    "icon": "ri-question-answer-line"
  },
  {
    "label": "Contacts",
    "link": "/apps/contacts",
    "icon": "ri-contacts-book-line"
  },
  {
    "label": "Task Manager",
    "link": "/apps/tasks",
    "icon": "ri-checkbox-multiple-line"
  },
  {
    "label": "Media Gallery",
    "icon": "ri-gallery-line",
    "submenu": [
      {
        "label": "Music Stream",
        "link": "/apps/gallery-music"
      },
      {
        "label": "Video Stream",
        "link": "/apps/gallery-video"
      }
    ]
  }
];

const pagesMenu = [
  {
    "label": "Designation",
    "icon": "ri-account-circle-line",
    "submenu": [
      {
        "label": "User Profile",
        "link": "/pages/profile"
      },
      {
        "label": "People & Groups",
        "link": "/pages/people"
      },
      {
        "label": "Activity Log",
        "link": "/pages/activity"
      },
      {
        "label": "Events",
        "link": "/pages/events"
      },
      {
        "label": "Settings",
        "link": "/pages/settings"
      }
    ]
  },



  {
    "id": 27,
    "label": "Department",
    "icon": "ri-lock-2-line",
    "submenu": [
      {
        "label": "Sign In Basic",
        "link": "/pages/signin"
      },
      {
        "label": "Sign In Cover",
        // "link": "/pages/signin2"
        "link": "/signin"
      },

      // {
      //   "label": "Sign Up Basic",
      //   "link": "/pages/signup"
      // },
      {
        "label": "Sign Up Cover",
        // "link": "/pages/signup2"
        "link": "/signup"
      },
      {
        "label": "Verify Account",
        "link": "/pages/verify"
      },
      {
        "label": "Forgot Password",
        "link": "/pages/forgot"
      },
      {
        "label": "Reset Password",
        "link": "/forgotPassword"
      },
      {
        "label": "Lock Screen",
        "link": "/pages/lock"
      }
    ]
  },
  {
    "label": "Role",
    "icon": "ri-error-warning-line",
    // "submenu": [
    //   {
    //     "label": "Page Not Found",
    //     "link": "/pages/error-404"
    //   },
    //   {
    //     "label": "Internal Server Error",
    //     "link": "/pages/error-500"
    //   },
    //   {
    //     "label": "Service Unavailable",
    //     "link": "/pages/error-503"
    //   },
    //   {
    //     "label": "Forbidden",
    //     "link": "/pages/error-505"
    //   }
    // ]
  },
  {
    "label": "Branch",
    "icon": "ri-file-text-line",
  },
  {
    "label": "Holidays",
    "icon": "ri-file-text-line",
  },
  // {
  //   "label": "Shift",
  //   "icon": "ri-file-text-line",
  // },
];

const salary = [
  // {
  //   "label": "Getting Started",
  //   "icon": "ri-pencil-ruler-2-line",
  //   "submenu": [
  //     {
  //       "label": "Grid System",
  //       "link": "/docs/layout/grid"
  //     },
  //     {
  //       "label": "Columns",
  //       "link": "/docs/layout/columns"
  //     },
  //     {
  //       "label": "Gutters",
  //       "link": "/docs/layout/gutters"
  //     }
  //   ]
  // },
  {
    "label": "Salary",
    "icon": "ri-suitcase-line",
    // "submenu": [
    //   {
    //     "label": "Accordion",
    //     "link": "/docs/com/accordions"
    //   },
    //   {
    //     "label": "Alerts",
    //     "link": "/docs/com/alerts"
    //   },
    //   {
    //     "label": "Avatars",
    //     "link": "/docs/com/avatars"
    //   },
    //   {
    //     "label": "Badge",
    //     "link": "/docs/com/badge"
    //   },
    //   {
    //     "label": "Breadcrumbs",
    //     "link": "/docs/com/breadcrumbs"
    //   },
    //   {
    //     "label": "Buttons",
    //     "link": "/docs/com/buttons"
    //   },
    //   {
    //     "label": "Cards",
    //     "link": "/docs/com/cards"
    //   },
    //   {
    //     "label": "Carousel",
    //     "link": "/docs/com/carousel"
    //   },
    //   {
    //     "label": "Dropdown",
    //     "link": "/docs/com/dropdown"
    //   },
    //   {
    //     "label": "Images",
    //     "link": "/docs/com/images"
    //   },
    //   {
    //     "label": "List Group",
    //     "link": "/docs/com/listgroup"
    //   },
    //   {
    //     "label": "Markers",
    //     "link": "/docs/com/markers"
    //   },
    //   {
    //     "label": "Modal",
    //     "link": "/docs/com/modal"
    //   },
    //   {
    //     "label": "Nav & Tabs",
    //     "link": "/docs/com/navtabs"
    //   },
    //   {
    //     "label": "Offcanvas",
    //     "link": "/docs/com/offcanvas"
    //   },
    //   {
    //     "label": "Pagination",
    //     "link": "/docs/com/pagination"
    //   },
    //   {
    //     "label": "Placeholders",
    //     "link": "/docs/com/placeholders"
    //   },
    //   {
    //     "label": "Popovers",
    //     "link": "/docs/com/popovers"
    //   },
    //   {
    //     "label": "Progress",
    //     "link": "/docs/com/progress"
    //   },
    //   {
    //     "label": "Spinners",
    //     "link": "/docs/com/spinners"
    //   },
    //   {
    //     "label": "Toast",
    //     "link": "/docs/com/toasts"
    //   },
    //   {
    //     "label": "Tooltips",
    //     "link": "/docs/com/tooltips"
    //   },
    //   {
    //     "label": "Tables",
    //     "link": "/docs/com/tables"
    //   }
    // ]
  },
  {
    "label": "Credit Leave",
    "icon": "ri-list-check-2",
    // "submenu": [
    //   {
    //     "label": "Text Elements",
    //     "link": "/docs/form/elements"
    //   },
    //   {
    //     "label": "Selects",
    //     "link": "/docs/form/selects"
    //   },
    //   {
    //     "label": "Checks & Radios",
    //     "link": "/docs/form/checksradios"
    //   },
    //   {
    //     "label": "Range",
    //     "link": "/docs/form/range"
    //   },
    //   {
    //     "label": "Pickers",
    //     "link": "/docs/form/pickers"
    //   },
    //   {
    //     "label": "Layouts",
    //     "link": "/docs/form/layouts"
    //   }
    // ]
  },
  {
    "label": "Setup ",
    "icon": "ri-bar-chart-2-line",
    // "submenu": [
    //   {
    //     "label": "ApexCharts",
    //     "link": "/docs/chart/apex"
    //   },
    //   {
    //     "label": "Chartjs",
    //     "link": "/docs/chart/chartjs"
    //   }
    // ]
  },
  {
    "label": "Maps & Icons",
    "icon": "ri-stack-line",
    "submenu": [
      {
        "label": "Leaflet Maps",
        "link": "/docs/map/leaflet"
      },
      {
        "label": "Vector Maps",
        "link": "/docs/map/vector"
      },
      {
        "label": "Remixicon",
        "link": "/docs/icon/remix"
      },
      {
        "label": "Feathericons",
        "link": "/docs/icon/feather"
      }
    ]
  },
  {
    "label": "Utilities",
    "icon": "ri-briefcase-4-line",
    "submenu": [
      {
        "label": "Background",
        "link": "/docs/util/background"
      },
      {
        "label": "Border",
        "link": "/docs/util/border"
      },
      {
        "label": "Colors",
        "link": "/docs/util/colors"
      },
      {
        "label": "Divider",
        "link": "/docs/util/divider"
      },
      {
        "label": "Flex",
        "link": "/docs/util/flex"
      },
      {
        "label": "Sizing",
        "link": "/docs/util/sizing"
      },
      {
        "label": "Spacing",
        "link": "/docs/util/spacing"
      },
      {
        "label": "Opacity",
        "link": "/docs/util/opacity"
      },
      {
        "label": "Position",
        "link": "/docs/util/position"
      },
      {
        "label": "Typography",
        "link": "/docs/util/typography"
      },
      {
        "label": "Shadows",
        "link": "/docs/util/shadows"
      },
      {
        "label": "Extras",
        "link": "/docs/util/extras"
      }
    ]
  }
];

export { dashboardMenu, employeedashboardMenu, admindashboardmenu, applicationsMenu, pagesMenu, salary };