import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getSingleEmployeeDetails, viewLogo } from '../../services/api/api';

const SalaryPDF = () => {
    const location = useLocation();
    const { rowData, year, month, selectedEmployee } = location.state || {}; // Access rowData passed from employeesalary.js
    const [singleEmp, setSingleEmp] = useState(null);
    const [singleLogo, setCompanyLogo] = useState(null);
    const ignoredKeys = ['id', 'basic', 'deduction', 'late_violation', 'leave_violation', 'break_violation', 'allowance', 'month', 'name', 'net_total', 'serial', 'totalDeductions', 'year', 'totalEarning', 'deduction_monthly_total', 'gross_month_total'];


    // const renderRows = (data) => {
    //     const filteredKeys = Object.keys(data).filter(key => !ignoredKeys.includes(key));
    //     const rowPairs = [];

    //     const sumArray = (arr) => arr.reduce((acc, val) => acc + Number(val), 0);

    //     // 18.9.24

    //     rowPairs.push(
    //         <React.Fragment key="earnings-section">
    //             <tr>
    //                 <th scope="row" colSpan="2" className='text-center' style={{ backgroundColor: '#f0f0f0' }}>Earnings</th>
    //                 <th scope="row" colSpan="2" className='text-center' style={{ backgroundColor: '#f0f0f0' }}>Deductions</th>
    //             </tr>
    //             <tr>
    //                 <th scope="row">Basic</th>
    //                 <td>{sumArray(data.basic)}</td>
    //                 <th scope="row">Break Violation</th>
    //                 <td>{data.break_violation}</td>
    //             </tr>
    //             <tr>
    //                 <th scope="row">Allowance</th>
    //                 <td>{sumArray(data.allowance)}</td>
    //                 <th scope="row">Late Violation</th>
    //                 <td>{data.late_violation}</td>
    //             </tr>
    //             <tr>
    //                 <th scope="row"></th>
    //                 <td></td>
    //                 <th scope="row">Leave Violation</th>
    //                 <td>{data.leave_violation}</td>
    //             </tr>
    //             <tr>
    //                 <th scope="row"></th>
    //                 <td></td>
    //                 <th scope="row">Other Deductions</th>
    //                 <td>{sumArray([0, 0, 0, 0])}</td>
    //             </tr>
    //             <tr>
    //                 <td></td>
    //             </tr>
    //         </React.Fragment>
    //     );
    //     // xxxxxxxxxxxxxxxxxx

    //     // Create pairs of key-value to display in two columns
    //     for (let i = 0; i < filteredKeys.length; i += 2) {
    //         const key1 = filteredKeys[i];
    //         const key2 = filteredKeys[i + 1];

    //         const value1 = Array.isArray(data[key1]) ? sumArray(data[key1]) : data[key1];
    //         const value2 = key2 && Array.isArray(data[key2]) ? sumArray(data[key2]) : data[key2];

    //         rowPairs.push(
    //             <tr key={i}>
    //                 <th scope="row">{formatKey(key1)}</th>
    //                 <td>{value1}</td>
    //                 {key2 && (
    //                     <>
    //                         <th>{formatKey(key2)}</th>
    //                         <td>{value2}</td>
    //                     </>
    //                 )}
    //             </tr>
    //         );
    //     }
    //     return rowPairs;
    // };


    const roundValue = (value) => {
        if (value === null || value === undefined) {
            return ''; // Handle null or undefined values
        }

        const rounded = value > 0.5 ? Math.ceil(value) : Math.floor(value);
        return rounded % 1 === 0 ? rounded.toString() : rounded;
    };

    const renderRows = (data) => {
        // const filteredKeys = Object.keys(data).filter(key => !ignoredKeys.includes(key));
        const rowPairs = [];
        console.log(data);
        console.log(selectedEmployee);


        const sumArray = (arr) => arr.reduce((acc, val) => acc + Number(val), 0);

        // Days Details and Bank Details Section
        rowPairs.push(
            <React.Fragment key="days-bank-details-section">
                <tr>
                    <th scope="row" colSpan="2" className='text-center' style={{ backgroundColor: '#f0f0f0' }}>Days Details</th>
                    <th scope="row" colSpan="2" className='text-center' style={{ backgroundColor: '#f0f0f0' }}>Bank Details</th>
                </tr>
                <tr>
                    <th scope="row">Working Days</th>
                    <td>{data.working_days}</td>
                    <th scope="row">Branch Name</th>
                    <td>{data.branch_name}</td>
                </tr>
                <tr>
                    <th scope="row">Present Days</th>
                    <td>{data.present_days}</td>
                    <th scope="row">Account No</th>
                    <td>{data.account_no}</td>
                </tr>
                <tr>
                    <th scope="row">Absent Days</th>
                    <td>{data.absent_days}</td>
                    <th scope="row">PAN No</th>
                    <td>{data.PAN_no}</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <th scope="row">IFSC Code</th>
                    <td>{data.IFSC_code}</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <th scope="row">Bank Name</th>
                    <td>{data.bank_name}</td>
                </tr>
            </React.Fragment>
        );

        rowPairs.push(
            <React.Fragment key="earnings-deductions-section">
                <tr>
                    <th scope="row" colSpan="2" className='text-center' style={{ backgroundColor: '#f0f0f0' }}>Earnings</th>
                    <th scope="row" colSpan="2" className='text-center' style={{ backgroundColor: '#f0f0f0' }}>Deductions</th>
                </tr>
                <tr>
                    <th scope="row">Basic</th>
                    <td>{sumArray(selectedEmployee.basic.map(b => roundValue(b.monthly)))}</td>
                    <th scope="row">Break Violation</th>
                    <td>{data.break_violation}</td>
                </tr>
                <tr>
                    <th scope="row">Allowance</th>
                    <td>{sumArray(selectedEmployee.allowence.map(item => roundValue(item.monthly) || 0))}</td>
                    <th scope="row">Late Violation</th>
                    <td>{data.late_violation}</td>
                </tr>
                <tr>
                    <th scope="row"></th>
                    <td></td>
                    <th scope="row">Leave Violation</th>
                    <td>{data.leave_violation}</td>
                </tr>
                <tr>
                    <th scope="row"></th>
                    <td></td>
                    <th scope="row">Other Deductions</th>
                    <td>{sumArray(selectedEmployee.allowence.map(item => roundValue(item.monthly) || 0))}</td>
                </tr>
                <tr>
                    <td colSpan="2"></td>
                    <td className='text-center' style={{ backgroundColor: '#f0f0f0' }}>Net Total</td>
                    <td className='text-center'>{data.net_total}</td>
                </tr>
            </React.Fragment>
        );

        return rowPairs;


        // Earnings and Deductions Section
        // rowPairs.push(
        //     <React.Fragment key="earnings-deductions-section">
        //         <tr>
        //             <th scope="row" colSpan="2" className='text-center' style={{ backgroundColor: '#f0f0f0' }}>Earnings</th>
        //             <th scope="row" colSpan="2" className='text-center' style={{ backgroundColor: '#f0f0f0' }}>Deductions</th>
        //         </tr>
        //         <tr>
        //             <th scope="row">Basic</th>
        //             <td>{sumArray(data.basic)}</td>
        //             <th scope="row">Break Violation</th>
        //             <td>{data.break_violation}</td>
        //         </tr>
        //         <tr>
        //             <th scope="row">Allowance</th>
        //             <td>{sumArray(data.allowance)}</td>
        //             <th scope="row">Late Violation</th>
        //             <td>{data.late_violation}</td>
        //         </tr>
        //         <tr>
        //             <th scope="row"></th>
        //             <td></td>
        //             <th scope="row">Leave Violation</th>
        //             <td>{data.leave_violation}</td>
        //         </tr>
        //         <tr>
        //             <th scope="row"></th>
        //             <td></td>
        //             <th scope="row">Other Deductions</th>
        //             <td>{sumArray([0, 0, 0, 0])}</td>
        //         </tr>
        //         <tr>
        //             <td></td>
        //         </tr>
        //     </React.Fragment>
        // );
    };










    const handlePrint = () => {
        const originalContents = document.body.innerHTML;
        const printContents = document.getElementById('print-section').innerHTML;

        document.body.innerHTML = printContents;

        window.print();

        document.body.innerHTML = originalContents;
    };

    useEffect(() => {

        console.log(rowData);
        console.log(month);
        console.log(year);

        if (rowData && rowData.employee_id) {
        }
        handleEditEmployee();
        getCompanyLogo();

    }, [rowData]);

    const handleEditEmployee = async () => {
        try {
            const response = await getSingleEmployeeDetails({ id: `${rowData.employee_id}` });
            console.log("edit API response:", response);

            setSingleEmp(response);
        } catch (error) {
            console.error("Error fetching salary structure setup:", error);
        }
    };
    const getCompanyLogo = async () => {
        try {
            const response = await viewLogo();
            setCompanyLogo(response);
        } catch (error) {
            console.error("Error fetching salary structure setup:", error);
        }
    };
    console.log('singleLogo:', singleLogo);

    console.log('rowData:', rowData);
    console.log('singleEmp:', singleEmp);
    // const netTotalInWords = rowData ? numberToWords(rowData.net_total) : '';
    return (
        <div>

            {/* <style>
                {`
  @media print {
      body {
          width: 100%;
          padding: 0;
          margin: 0;
      }
      .headerNew, .footer, .breadcrumb-warning, .button-oneX {
          display: none !important;
      }
      .main {
          display: block !important;
          width: 100%;
          padding: 0;
          margin: 0;
      }
      .print-section {
          display: block !important;
          width: 100%;
          padding: 0;
          margin: 0;
      }
      .table-bordered {
          width: 100%;
          border-collapse: collapse;
      }
  }
  `}
            </style> */}
            <style>
                {`
  @media print {
      body {
          width: 100% !important;
          padding: 0 !important;
          margin: 0 !important;
      }
      .headerNew, .footer, .breadcrumb-warning, .button-oneX, .salary-report, .line-below {
          display: none !important;
      }
      .main {
          display: block !important;
          width: 100% !important;
          padding: 0 !important;
          margin: 0 !important;
      }
      .print-section {
          display: block !important;
          width: 100% !important;
          padding: 0 !important;
          margin: 0 !important;
      }
      .table-bordered {
          width: 100% !important;
          border-collapse: collapse !important;
      }
  }
  `}
            </style>





            <div className="headerNew">
            </div>

            <div className="main p-4 p-lg-5 mt-5">
                {/* <div className="mb-4 border-bottom text-center">
                    <h3 className="mb-0 pb- border-bottom custome-btn">Salary Report</h3>
                </div> */}

                <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                    <div>
                        <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                            {/* <li className="breadcrumb-item active">Salary Report</li> */}
                        </ol>
                    </div>
                    <div className="d-flex justify-content-between ">
                        <nav aria-label="breadcrumb" style={{ marginRight: '1rem', }}>
                            <Link to="/admin/employeesalary" state={{ fromSalaryPDF: true }} className="btn btn-danger mb-3 button-oneX" style={{
                                backgroundColor: '#007bff',
                                color: 'white',
                                textDecoration: 'none',
                                padding: '0.5rem 1rem',
                                borderRadius: '0.25rem',
                                border: '0px'
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16" style={{ marginRight: '10px' }}>
                                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                </svg>
                                Back
                            </Link>
                        </nav>
                        <nav aria-label="breadcrumb">
                            <div>
                                <button className="button-oneX btn-primary" onClick={handlePrint} style={{
                                    backgroundColor: '#007bff',
                                    color: 'white',
                                    border: 'none',
                                    padding: '7px 12px',
                                    borderRadius: '0.25rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: '0.4rem'
                                }}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer" viewBox="0 0 16 16" style={{ marginRight: '10px' }}>
                                            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                                            <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1" />
                                        </svg>Print</span>
                                </button>
                            </div>
                        </nav>
                    </div>
                </div>

                <div className="row"
                    style={{
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                        borderRadius: "10px",
                        overflow: "hidden",
                    }}
                >
                    <div className="row">
                        <div id="print-section" className="col-md-12">
                            <div className="text-center lh-1 mb-2">
                                <Link to="" className="">
                                    {singleLogo ? (
                                        <img src={singleLogo} style={{ width: "150px" }} alt="Company Logo" />
                                    ) : (
                                        <img src="" style={{ width: "100px" }} alt="Company Logo" />
                                    )}
                                </Link>
                                <div className="border p-3 mt-4">
                                    <div className="d-flex flex-column">
                                        <span className="fw-bold">
                                            <u>PAYSLIP FOR {month.toUpperCase()} {year}</u>
                                        </span>


                                    </div>
                                </div>
                            </div>

                            <div className="container mt-4">
                                <div style={{ display: 'flex', flexDirection: 'column' }}>

                                    <div style={{ marginBottom: '1rem' }}>
                                        <div><span className="fw-bolder">EMP Name:</span> <span className="ms-3">{rowData.name}</span></div>
                                    </div>
                                    <div style={{ marginBottom: '1rem' }}>
                                        <div><span className="fw-bolder">PF No.:</span> <span className="ms-3">123456</span></div>
                                    </div>

                                    <div style={{ marginBottom: '1rem' }}>
                                        <div><span className="fw-bolder">ESI No.:</span> <span className="ms-3">123456</span></div>
                                    </div>
                                    <div style={{ marginBottom: '1rem' }}>
                                        <div><span className="fw-bolder">Ac No.:</span> <span className="ms-3">{rowData?.account_no}</span></div>
                                    </div>
                                </div>
                                <table className="mt-4 table table-bordered">
                                    <tbody>
                                        {renderRows(rowData)}
                                    </tbody>
                                </table>
                            </div>



                            <div className="d-flex justify-content-end">
                                <div className="d-flex flex-column mt-2">
                                    <span className="mt-4">Authorised Signatory</span> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
export default SalaryPDF;


