import React, { useState, useEffect, useCallback } from "react";
import Header from "../../layouts/Header";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, Alert } from 'react-bootstrap';
import { getsingleemployeesetup, viewLogo } from "../../services/api/api";
import { debounce } from "@mui/material";


const Salaryreportemp = () => {

    const [loading, setLoading] = useState(false);
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().toLocaleString('default', { month: 'long' }).toLowerCase();

    // const [salaryForm, setSalaryForm] = useState({
    //     year: currentYear.toString(),
    //     month: currentMonth
    // });
    const [salaryForm, setSalaryForm] = useState({ year: '', month: '' });
    const [rowData, setRowData] = useState([]);
    const [years, setYears] = useState([]);
    const [months, setMonths] = useState([]);
    const [employeeId, setEmployeeId] = useState(null);
    const [singleLogo, setCompanyLogo] = useState(null);
    const monthNames = [
        "January", "February", "March", "April",
        "May", "June", "July", "August",
        "September", "October", "November", "December"
    ];
    const [dataNotAvailable, setDataNotAvailable] = useState(false);

    useEffect(() => {
        const generatedYears = Array.from({ length: 31 }, (_, i) => 2020 + i);
        setYears(generatedYears);

        const monthNames = [
            'january', 'february', 'march', 'april', 'may', 'june',
            'july', 'august', 'september', 'october', 'november', 'december'
        ];
        setMonths(monthNames);
    }, []);

    const handleviewsalarystructuresetup = useCallback(async (year, month) => {
        setLoading(true);
        setDataNotAvailable(false);
        if (employeeId) {
            try {
                const response = await getsingleemployeesetup({
                    year: year,
                    month: month,
                    employeeId: employeeId,
                });
                if (response && response.length > 0) {
                    setRowData(response);
                } else {
                    setDataNotAvailable(true);
                }
            } catch (error) {
                console.error("Error fetching salary structure setup:", error);
                toast.error("Error fetching salary structure setup");
            } finally {
                setLoading(false);
            }
        }
    }, [employeeId]);

    useEffect(() => {
        const employeeId = Number(sessionStorage.getItem('id'));
        setEmployeeId(employeeId)
        setSalaryForm({ year: currentYear.toString(), month: currentMonth });
        console.log(salaryForm);
    }, [currentYear, currentMonth, employeeId]);


    useEffect(() => {
        handleviewsalarystructuresetup(salaryForm.year, salaryForm.month);
        getCompanyLogo()
    }, [salaryForm.year, salaryForm.month, handleviewsalarystructuresetup]);

    const getCompanyLogo = async () => {
        try {
            const response = await viewLogo();
            setCompanyLogo(response);
        } catch (error) {
            console.error("Error fetching salary structure setup:", error);
        }
    };



    const handleChange = (e) => {
        const { name, value } = e.target;
        setSalaryForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };


    const renderRows = (data) => {
        if (!data || !Array.isArray(data) || data.length === 0) {
            return <tr><td colSpan="4" className='text-center'>No data available</td></tr>;
        }

        const empData = data[0]?.emp_new_salary; // Using optional chaining

        if (!empData) {
            return <tr><td colSpan="4" className='text-center'>Employee data not available</td></tr>;
        }

        const sumArray = (arr) => arr.reduce((acc, val) => acc + Number(val), 0);

        const rowPairs = [
            <React.Fragment key="days-bank-details-section">
                <tr>
                    <th colSpan="2" className='text-center' style={{ backgroundColor: '#f0f0f0' }}>Days Details</th>
                    <th colSpan="2" className='text-center' style={{ backgroundColor: '#f0f0f0' }}>Bank Details</th>
                </tr>
                <tr>
                    <th>Working Days</th>
                    <td>{empData.working_days}</td>
                    <th>Branch Name</th>
                    <td>{empData.branch_name || '-'}</td>
                </tr>
                <tr>
                    <th>Present Days</th>
                    <td>{empData.present_days}</td>
                    <th>Account No</th>
                    <td>{empData.account_no || '-'}</td>
                </tr>
                <tr>
                    <th>Absent Days</th>
                    <td>{empData.absent_days}</td>
                    <th>PAN No</th>
                    <td>{empData.PAN_no || '-'}</td>
                </tr>
                <tr>
                    <td colSpan="2"></td>
                    <th>IFSC Code</th>
                    <td>{empData.IFSC_code || '-'}</td>
                </tr>
                <tr>
                    <td colSpan="2"></td>
                    <th>Bank Name</th>
                    <td>{empData.bank_name || '-'}</td>
                </tr>
            </React.Fragment>
        ];

        rowPairs.push(
            <React.Fragment key="earnings-deductions-section">
                <tr>
                    <th colSpan="2" className='text-center' style={{ backgroundColor: '#f0f0f0' }}>Earnings</th>
                    <th colSpan="2" className='text-center' style={{ backgroundColor: '#f0f0f0' }}>Deductions</th>
                </tr>
                <tr>
                    <th>Basic</th>
                    <td>{empData.basic_amount}</td>
                    <th>Break Violation</th>
                    <td>{empData.break_violation}</td>
                </tr>
                <tr>
                    <th>Allowance</th>
                    <td>{sumArray(empData.allowence.map(a => a.monthly))}</td>
                    <th>Late Violation</th>
                    <td>{empData.late_violation}</td>
                </tr>
                <tr>
                    <th></th>
                    <td></td>
                    <th>Leave Violation</th>
                    <td>{empData.leave_violation}</td>
                </tr>
                <tr>
                    <th></th>
                    <td></td>
                    <th>Other Deductions</th>
                    <td>{sumArray(empData.deduction.map(d => d.monthly))}</td>
                </tr>
                <tr>
                    <td colSpan="2"></td>
                    <td className='text-center' style={{ backgroundColor: '#f0f0f0' }}>Net Total</td>
                    <td className='text-center'>{empData.net_total}</td>
                </tr>
            </React.Fragment>
        );

        return rowPairs;
    };


    const handlePrint = () => {
        const originalContents = document.body.innerHTML;
        const printContents = document.getElementById('print-sectionEmp').innerHTML;

        document.body.innerHTML = printContents;

        window.print();

        document.body.innerHTML = originalContents;
    };
    return (
        <React.Fragment>
            <style>
                {`
                @media print {
                    body {
                        width: 100% !important;
                        padding: 0 !important;
                        margin: 0 !important;
                    }
                    .headerNew, .footer, .breadcrumb-warning, .button-oneX, .custome-btn, .border-bottom, .hideyear {
                        display: none !important;
                    }
                    .main {
                        display: block !important;
                        width: 100% !important;
                    }
                    .table-bordered {
                        width: 100% !important;
                        border-collapse: collapse !important;
                    }
                }
                `}
            </style>

            <div className="headerNew">
                {/* Your Header component content */}
                <Header />
            </div>

            <div className="main p-4 p-lg-5 mt-5">
                <div className="mb-4 border-bottom text-center ">
                    <h3 className="mb-0 pb- border-bottom custome-btn">Salary Report</h3>
                </div>


                <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                    <div>
                        <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                            <li class="breadcrumb-item ">
                                <Link to="/admin/dashboard">Dashboard</Link>
                            </li>
                            <li class="breadcrumb-item active"><Link to="/employee/salaryreport">Salary Report
                            </Link></li>
                        </ol>
                    </div>

                    <nav aria-label="breadcrumb">


                        <Link to="">
                            <div><button className="button-oneX" onClick={handlePrint}><span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer" viewBox="0 0 16 16" style={{ marginRight: '10px' }}>
                                    <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                                    <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1" />
                                </svg>

                                Print</span></button></div>
                        </Link>
                    </nav>
                </div>

                <div className="col-md-3 mb-3 hideyear">
                    <Row>
                        <Col md={6} className="mb-3 pt-3">
                            <Form.Group>
                                <Form.Control
                                    as="select"
                                    name="year"
                                    value={salaryForm.year} // Reflect the year state
                                    onChange={handleChange}
                                >
                                    <option value="">Select Year</option>
                                    {years.map((year) => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>

                        <Col md={6} className="mb-3 pt-3">
                            <Form.Group>
                                <Form.Control
                                    as="select"
                                    name="month"
                                    value={salaryForm.month}  // Reflect the month state
                                    onChange={handleChange}
                                >
                                    <option value="">Select Month</option>
                                    {months.map((month) => (
                                        <option key={month} value={month}>
                                            {month.charAt(0).toUpperCase() + month.slice(1)}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                </div>


                {loading ? (
                    <div className="loader-container">
                        <div className="loader"></div>
                    </div>
                ) : (
                    <div className="row"
                        style={{
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            borderRadius: "10px",
                            overflow: "hidden",
                        }}
                    >
                        <div className="row">
                            <div id="print-sectionEmp" className="col-md-12">
                                <div className="text-center lh-1 mb-2">
                                    <Link to="">
                                        {singleLogo ? (
                                            <img src={singleLogo} style={{ width: "150px" }} alt="Company Logo" />
                                        ) : (
                                            <img src="" style={{ width: "100px" }} alt="Company Logo" />
                                        )}
                                    </Link>
                                    <div className="border p-3 mt-4">
                                        <div className="d-flex flex-column">
                                            <span className="fw-bold">
                                                <u>PAYSLIP FOR {salaryForm.month.toUpperCase()} {salaryForm.year}</u>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                {/* Employee Details */}
                                <div className="container mt-4">
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        {rowData.map(item => (
                                            <div key={item.emp_new_salary.employee_id} style={{ marginBottom: '1rem' }}>
                                                <div>
                                                    <span className="fw-bolder">EMP Name:</span>
                                                    <span className="ms-3">{item.emp_new_salary.name || ''}</span>
                                                </div>
                                                <div>
                                                    <span className="fw-bolder">PF No.:</span>
                                                    <span className="ms-3">{item.emp_new_salary.account_no || ''}</span>
                                                </div>
                                                <div>
                                                    <span className="fw-bolder">ESI No.:</span>
                                                    <span className="ms-3">{item.emp_new_salary.account_no || ''}</span>
                                                </div>
                                                <div>
                                                    <span className="fw-bolder">Ac No.:</span>
                                                    <span className="ms-3">{item.emp_new_salary.account_no || ''}</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <table className="mt-4 table table-bordered">
                                        <tbody>
                                            {renderRows(rowData)} {/* Pass rowData to renderRows */}
                                        </tbody>
                                    </table>
                                </div>


                                {/* Signature Section */}
                                <div className="d-flex justify-content-end">
                                    <div className="d-flex flex-column mt-2">
                                        <span className="mt-4">Authorised Signatory</span>
                                    </div>
                                </div>
                            </div>
                        </div>




                    </div>

                )}

            </div>
        </React.Fragment>
    );
};

export default Salaryreportemp;
