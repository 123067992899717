import React, { useState, useEffect, useRef } from 'react';
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import { Card, Button } from "react-bootstrap";
import '../../styles/globals.css';
import { Link } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import { getSingleCompanyJobs } from '../../services/api/api';

const Job = () => {
  const [activeButton, setActiveButton] = useState('open');
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState([]);

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const columns = [
    {
      field: 'srNo',
      headerName: 'SR No.',
      width: 100,
      renderCell: (params) => {
        return params.row.id
      }
    },
    { field: 'jobTitle', headerName: 'Job Title', flex: 1 },
    { field: 'jobType', headerName: 'Job Type', flex: 1 },
    { field: 'companyName', headerName: 'Company Name', flex: 1 },
    { field: 'numberOfOpening', headerName: 'Openings', flex: 1 },
    { field: 'payTotal', headerName: 'Pay Total', flex: 1 },
    { field: 'country', headerName: 'Country', flex: 1 },
    { field: 'keyResponsibilities', headerName: 'Responsibilities', flex: 1 },
    { field: 'location', headerName: 'Location', flex: 1 },
  ];

  const fetchJobs = async () => {
    setLoading(true); // Set loading to true when fetching begins
    try {
      const response = await getSingleCompanyJobs();
      console.log(response);

      const formattedJobs = response.map(item => ({
        id: item.company_job._id, // Assuming _id is unique for each job
        jobTitle: item.company_job.jobTitle,
        jobType: item.company_job.jobType,
        companyName: item.company_job.companyName, // Default to "N/A" if empty
        numberOfOpening: item.company_job.numberOfOpening,
        payTotal: item.company_job.payTotal,
        country: item.company_job.country,
        keyResponsibilities: item.company_job.keyResponsibilities,
        location: item.company_job.location,
      }));
      setJobs(formattedJobs);
      setLoading(false); // Set loading to false after data is set
    } catch (error) {
      console.error('Error submitting form:', error);
      setLoading(false); // Ensure loading is false even on error
    }
  };

  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />

      <div className="main p-4 p-lg-5 mt-5">
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              <li className="breadcrumb-item ">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active"><Link to="/pages/recruitment/job">Jobs</Link></li>
            </ol>
          </div>
          <nav aria-label="breadcrumb">
            <Button className="mb-3 custom-submit-btn">
              <Link to="/pages/recruitment/jobform">
                <span style={{ color: "white" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                  </svg>
                  Create Job
                </span>
              </Link>
            </Button>
            <Button className="mb-3 custom-submit-btn">
              <Link to="/jobListing">
                <span style={{ color: "white" }}>
                  
                  View Jobs
                </span>
              </Link>
            </Button>
          </nav>
        </div>
        <Card className="popup-card empHistory">
          <Card.Body>
            <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', justifyContent: 'flex-end', marginBottom: "1rem" }}>
              <label htmlFor="searchInput"></label>
              <input
                type="text"
                className='p-1 w-25 ms-2'
                placeholder="Search"
              />
            </div>
            {loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (
              <div style={{ height: 400, width: '100%', overflow: 'auto' }}>
                <DataGrid
                  rows={jobs}
                  columns={columns}
                  pageSize={5}
                  autoHeight
                  getRowId={(row) => row.id} // Using the id field for row identification
                  headerClassName="custom-header"
                  components={{
                    NoRowsOverlay: () => (
                      <div style={{ textAlign: 'center', padding: '20px' }}>
                        No data available
                      </div>
                    ),
                  }}
                />
              </div>
            )}
          </Card.Body>
        </Card>
      </div>

    </React.Fragment>
  );
}

export default Job;
