import React, { useState, useEffect, useRef } from 'react';
import HeaderMobile from '../../layouts/HeaderMobile';
import Header from '../../layouts/Header';
import { Card, Button, Modal } from "react-bootstrap";
import $ from 'jquery';
import 'datatables.net';
import { viewWorkingDays, deleteWorkingDays } from '../../services/api/api'
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DataGrid } from '@mui/x-data-grid';

const ViewWorkingDays = () => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [workingDay, setWorkingDay] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [workingdaysId, setDeleteWorkingdaysId] = useState(null);
  const [masterId, setMasterId] = useState();
  const [searchText, setSearchText] = useState('');


  const fetchData = async () => {
    try {
      const formattedData = await viewWorkingDays();

      const responseData = formattedData.data;

      setWorkingDay(formattedData)

      if (!formattedData || !formattedData.length) {
        throw new Error('Failed to fetch data.');
      }
      if ($.fn.DataTable.isDataTable('#commonTable')) {
        $('#commonTable').DataTable().destroy();
      }



      const companyWorkingDay = formattedData[0].company_workingday;
      setMasterId(formattedData[0]._id);
      const workingdaysArray = Object.values(companyWorkingDay).map(item => ({

        _id: item._id,
        sunday: item.sunday || 'N/A',
        monday: item.monday || 'N/A',
        tuesday: item.tuesday || 'N/A',
        wednesday: item.wednesday || 'N/A',
        thursday: item.thursday || 'N/A',
        friday: item.friday || 'N/A',
        saturday: item.saturday || 'N/A',
        monthly_allow_leave: item.monthly_allow_leave || 'N/A',
      }));

      setWorkingDay(workingdaysArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const reversedRows = [...workingDay].reverse();

  const columns = [

    {
      field: 'id',
      headerName: 'Sr.',
      width: 50,
      renderCell: (params) => (
        <span>
          {reversedRows.indexOf(params.row) + 1}
        </span>
      ),
    },
    { field: 'sunday', headerName: 'Sunday', width: 105, },
    { field: 'monday', headerName: 'Monday', width: 105, },
    { field: 'tuesday', headerName: 'Tuesday', width: 105, },
    { field: 'wednesday', headerName: 'Wednesday', width: 105, },
    { field: 'thursday', headerName: 'Thrusday', width: 105, },
    { field: 'friday', headerName: 'Friday', width: 105, },
    { field: 'saturday', headerName: 'Saturday', width: 105, },

    {
      field: 'actions', headerName: 'Actions', width: 104, renderCell: (params) => (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-pencil-fill edit ebtn"
            viewBox="0 0 16 16"
            data-id={params.row._id}
            style={{ cursor: 'pointer', marginRight: "15px" }}
            onClick={() => {
              const id = params.row._id;
              navigate(`/edit_workingday/${id}/${masterId}`);
              // console.log("viewemployee id", id);
            }}
          >
            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
          </svg>

          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-trash3-fill delete delete-button"
            viewBox="0 0 16 16"
            data-id={params.row._id}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const id = params.row._id;
              openConfirmationModal(id);
            }}
          >
            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
          </svg> */}
        </>
      )
    },
  ];

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filterRows = () => {
    if (!searchText) {
      return [...workingDay].reverse();
    }

    return [...workingDay].reverse().filter((row) =>
      Object.values(row).some(
        (value) =>
          typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  // useEffect(() => {
  //   if ($.fn.DataTable.isDataTable('#commonTable')) {
  //     $('#commonTable').DataTable().destroy();
  //   }

  //   if ($.fn.DataTable && workingDay.length > 0) {
  //     tableRef.current = $('#commonTable').DataTable({
  //       data: workingDay.map((dataItem, index) => {
  //         return [
  //           // dataItem._id,
  //           workingDay.length - index,
  //           dataItem.sunday || 'N/A',
  //           dataItem.monday || 'N/A',
  //           dataItem.tuesday || 'N/A',
  //           dataItem.wednesday || 'N/A',
  //           dataItem.thursday || 'N/A',
  //           dataItem.friday || 'N/A',
  //           dataItem.saturday || 'N/A',
  //           dataItem.monthly_allow_leave || 'N/A',
  //           `   <svg
  //           xmlns="http://www.w3.org/2000/svg"
  //           width="16"
  //           height="16"
  //           fill="currentColor"
  //           class="bi bi-pencil-fill edit edit-button"
  //           viewBox="0 0 16 16"
  //           data-id="${dataItem._id}"
  //           style="cursor: pointer;">
  //           <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
  //         </svg>


  //     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill delete delete-button" viewBox="0 0 16 16" 
  //     data-id="${dataItem._id}" style="cursor: pointer;">
  //     //   <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
  //     // </svg>

  //     `
  //         ]
  //       }),
  //       columns: [
  //         { title: 'Sr.no.' },
  //         { title: 'Sunday' },
  //         { title: 'Monday' },
  //         { title: 'Tuesday' },
  //         { title: 'Wednesday' },
  //         { title: 'Thursday' },
  //         { title: 'Friday' },
  //         { title: 'Saturday' },
  //         { title: 'Monthly Leave' },
  //         { title: 'Action' },
  //       ],
  //       paging: true,
  //       ordering: true,
  //       searching: true,
  //       order: [[0, 'asc']],
  //       columnDefs: [
  //         {
  //           targets: -1,
  //           orderable: false,
  //           searchable: false,
  //           //   render: function (data, type, full, meta) {
  //           //     return createActionButton(full);
  //           //   },
  //         },
  //       ],
  //       responsive: true,
  //     });
  //   }


  //   $('#commonTable tbody').on('click', '.edit-button', function () {
  //     const id = $(this).data('id');
  //     navigate(`/edit_workingday/${id}/${masterId}`);
  //   });

  //   const isAdminDashboard = window.location.pathname === '/pages/workingdays';
  //   if (isAdminDashboard) {
  //     $('#commonTable tbody').on('click', '.delete-button', function () {
  //       const id = $(this).data('id');
  //       openConfirmationModal(id, masterId);
  //     });
  //   }



  // }, [workingDay]);


  const handleDeleteWorkingdays = async (id, masterId) => {
    setDeleteWorkingdaysId(id);
    setShowConfirmation(true);

    try {
      const response = await deleteWorkingDays(id, masterId);
      if (response && response.status === 'success') {
        if (!toast.isActive('workinDeletedToast')) {
          toast.success('Working deleted successfully', { toastId: 'workingDeletedToast', autoClose: 1000 });
        }
        const updatedWorkingDays = workingDay.filter(day => day._id !== id);
        setWorkingDay(updatedWorkingDays);
        fetchData();
      } else {
        // Handle failure case
      }
    } catch (error) {
      console.error('Error deleting leave:', error);
    }
  };

  const createActionButton = (data) => {
    const id = data[0];

    const viewButton = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill view" viewBox="0 0 16 16" style="cursor: pointer;">
    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
    </svg>`;

    const editButton = `
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-pencil-fill edit edit-button"
          viewBox="0 0 16 16"
          data-id="${id}"
          data-masterId="${masterId}"
          style="cursor: pointer;">
          <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
        </svg>
      `;


    const deleteButton = `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill delete delete-button" viewBox="0 0 16 16" 
    data-id="${id}" style="cursor: pointer;">
    //   <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
    // </svg>
    `;


    return ` ${editButton} <span style="margin-right: 8px;"></span> ${deleteButton} <span style="margin-right: 8px;"></span> ${viewButton} <span style="margin-right: 8px;"></span> `;
  };
  // $('#commonTable tbody').on('click', '.edit-button', function () {
  //   const id = $(this).data('id');
  //   navigate(`/pages/workingdays/editworkingday/${id}/${masterId}`);
  // });

  // $('#commonTable tbody').on('click', '.delete-button', function () {
  //   const id = $(this).data('id');
  //   openConfirmationModal(id, masterId);
  // });

  const openConfirmationModal = (id) => {
    setDeleteWorkingdaysId(id);
    setShowConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setShowConfirmation(false);
  };

  return (
    <React.Fragment>
      {/* Header components */}
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        {/* Breadcrumb section */}
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              {/* <li className="mx-2">
                    <h4>Holidays</h4>
                  </li> */}
              <li class="breadcrumb-item ">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li class="breadcrumb-item active"><Link to="/pages/workingdays/viewworkingdays">Working Days</Link></li>
              {/* <li class="breadcrumb-item active">Lists</li> */}
            </ol>
          </div>
          <nav aria-label="breadcrumb">

            {/* <Button variant="primary" className="mb-3">
            <Link to="/pages/workingdays/addworkingdays">
                <span style={{ color: "white" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                  </svg>
                  Add Working Day
                </span>
              </Link>
            </Button> */}

            <Button variant="primary" className="mb-3" disabled={workingDay.length >= 1}>
              <Link to="/pages/workingdays/addworkingdays" style={{ textDecoration: 'none', color: 'inherit' }}>
                <span style={{ color: workingDay.length >= 1 ? 'black' : 'white' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                  </svg>
                  Add Working Day
                </span>
              </Link>
            </Button>
          </nav>
        </div>
        <Card className="popup-card empHistory">
          <Card.Body>
            <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', justifyContent: 'flex-end', marginBottom: "1rem" }}>
              <label htmlFor="searchInput"></label>
              <input
                type="text"
                className='p-1 w-25 ms-2'
                placeholder="Search"
                value={searchText}
                onChange={handleSearch}
              />
            </div>
            {loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (
              <div style={{ height: 500, width: '100%', backgroundColor: 'white' }}>
                <DataGrid
                  // rows={filterRows()}
                  rows={workingDay}
                  columns={columns}
                  pageSize={5}
                  // checkboxSelection
                  getRowId={(row) => row._id}
                  components={{
                    NoRowsOverlay: () => <div style={{ textAlign: 'center', padding: '20px' }}>No data available</div>,
                  }}
                />
              </div>
            )}
          </Card.Body>
        </Card>
      </div>

      <Modal show={showConfirmation} onHide={closeConfirmationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this Working Day?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeConfirmationModal}>
            Cancel
          </Button>
          {/* <Button
                variant="danger"
                onClick={() => {
                  handledeleteHoliday(deleteHolidayId);
                  closeConfirmationModal();
                }}
              >
                Delete
              </Button> */}
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteWorkingdays(workingdaysId, masterId);
              closeConfirmationModal();
            }}

          >
            Delete
          </Button>

        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ViewWorkingDays
