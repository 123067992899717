import React, { useEffect, useState } from 'react'
import { getSingleCompanyJobs } from '../services/api/api';
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import { Eye } from "react-bootstrap-icons";

export const JobListing = () => {

    const [loading, setLoading] = useState(true);
    const [jobs, setJobs] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    const filteredJobs = jobs.filter(job =>
        job.jobTitle.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        fetchJobs();
    }, [])

    const fetchJobs = async () => {
        setLoading(true); // Set loading to true when fetching begins
        try {
            const response = await getSingleCompanyJobs();
            const formattedJobs = response.map(item => ({
                id: item.company_job._id,
                jobTitle: item.company_job.jobTitle,
                jobType: item.company_job.jobType,
                companyName: item.company_job.comapnyName || "N/A",
                numberOfOpening: item.company_job.numberOfOpening,
                payTotal: item.company_job.payTotal,
                country: item.company_job.country,
                keyResponsibilities: item.company_job.keyResponsibilities,
                location: item.company_job.location,
            }));
            setJobs(formattedJobs);
        } catch (error) {
            console.error('Error fetching jobs:', error);
        } finally {
            setLoading(false); // Ensure loading is false even on error
        }
    };

    const handleSearch = (e) => {
        e.preventDefault();
        console.log('Searching for:', searchQuery);
    };

    return (
        <div className="main p-4 p-lg-5 mt-5">
            {loading ? (
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            ) : (
                <>
                    <Form className="mb-4">
                        <Form.Group controlId="searchJob">
                            <Form.Control
                                type="text"
                                placeholder="Search for jobs..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                                className="search-input" // Add a class for styling
                            />
                        </Form.Group>
                    </Form>

                    <Row xs={1} className="g-4">
                        {filteredJobs.map((job) => (
                            <Col key={job._id} className="mb-3">
                                <Card className="job-card d-flex flex-row" style={{ width: '100%' }}>
                                    <Card.Body className="flex-grow-1">
                                        <Card.Title>{job.jobTitle}</Card.Title>
                                        <Card.Text>
                                            <strong>Company:</strong> {job.companyName}<br />
                                            <strong>Job Type:</strong> {job.jobType}<br />
                                            <strong>Location:</strong> {job.location}<br />
                                            <strong>Openings:</strong> {job.numberOfOpening}<br />
                                            <strong>Pay Total:</strong> {job.payTotal}<br />
                                            <strong>Country:</strong> {job.country}<br />
                                        </Card.Text>
                                    </Card.Body>
                                    <div className="d-flex align-items-center p-3">
                                        <Button variant="primary" className="me-2">Easy Apply</Button>
                                        <Button variant="link" onClick={() => console.log(job)}>
                                            <Eye />
                                        </Button>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </>
            )}
        </div>
    )
}
