import React, { useState, useEffect } from "react";
import Header from "../../../layouts/Header";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addBranch, addEmployee, addHoliday, basicSetup, viewDepartment } from "../../../services/api/api";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";


const BranchForm = () => {

  const navigate = useNavigate();
  const [state, setState] = useState({
    branch_name: "",
  });

  const [branches, setbranches] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setState({
      branch_name: "",
    });
    setErrors({});
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(state);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      setIsSubmitting(true);
      const response = await addBranch(state);

      if (response.message) {
        toast.success(response.message, {
          position: "top-center",
          autoClose: 1000,
        });
        navigate('/pages/hr/branch')
        // navigate('/admin/dashboard');

        const storedSetupData = JSON.parse(sessionStorage.getItem('setupData'));
        const updateProfileData = {
          ...storedSetupData, // Spread the stored values
          companyBranch: "true", // Update companyProfile field
        };
        console.log(updateProfileData);
        const secondResponse = await basicSetup(updateProfileData);
        console.log(secondResponse);

      } else {
        toast.error('Unable to add branch', {
          position: "top-center",
          autoClose: 1000,
        });
      }
      // navigate("/pages/hr/branch");
    } catch (er) {
      toast.success("Branch added successfully", {
        position: "top-right",
        autoClose: 1000,
      });
      setIsSubmitting(false);
      navigate("/pages/hr/branch");


    }
  };







  const validateForm = (state) => {
    let errors = {};

    if (!state.branch_name.trim()) {
      errors.branch_name = "Branch name is required";
    }

    return errors;
  };



  return (
    <React.Fragment>
      <Header />
      {/* Your other layout code here */}
      <div className="main p-4 p-lg-5 mt-5">
        {/* Your layout structure */}
        <div className="mb-4 border-bottom text-center">
          <h3 className="mb-0 pb- border-bottom custome-btn">Add Branch</h3>
        </div>
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to="/pages/hr/branch">Branch</Link>
              </li>
              <li className="breadcrumb-item active">Add Branch</li>
            </ol>
          </div>
          <nav aria-label="breadcrumb">
            <Link to="/pages/hr/branch" className="btn btn-danger mb-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
              </svg>
              Back
            </Link>
          </nav>
        </div>



        <div className="row"
          style={{
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >


          <div className="col-md-12 formcontrols">
            <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
              <form onSubmit={handleSubmit}>


                <div className="row">
                  <div className="col-md-12">

                    <div className="mb-3">
                      <label htmlFor="branch_name" className="form-label">
                        Branch name
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.branch_name && "is-invalid"}`}
                        id="branch_name"
                        name="branch_name"
                        value={state.branch_name}
                        onChange={handleChange}
                        placeholder="Enter branch"
                      />
                      {errors.branch_name && (
                        <div className="invalid-feedback">{errors.branch_name}</div>
                      )}
                    </div>




                  </div>
                </div>
                <button type="submit" className="custom-submit-btn" disabled={isSubmitting}>
                  {isSubmitting ? 'Submitting...' : 'Add Branch'}
                </button>
              </form>
            </div>
          </div>
        </div>



      </div>
    </React.Fragment>
  );
};

export default BranchForm;
