// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import Header from '../../layouts/Header';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { addBreakoffemp, viewEmployee } from '../../services/api/api';
// import { useNavigate } from 'react-router-dom';

// const AddBreakOffForm = () => {
//     const navigate = useNavigate();
//     const [state, setState] = useState({
//         employeeId: '',
//         date: '',
//     });
//     const [submitting, setSubmitting] = useState(false);
//     const [employeeData, setEmployeeData] = useState({});
//     const [loading, setLoading] = useState(true);


//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         let formattedDate = value;
//         if (name === 'date') {
//             const dateParts = value.split('-');
//             formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
//         }
//         setState((prevState) => ({
//             ...prevState,
//             [name]: formattedDate,
//         }));
//     };


//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         if (submitting) {
//             return;
//         }
//         setSubmitting(true);

//         try {
//             const response = await addBreakoffemp(state.employeeId, state.date);
//             if (response && response.message === 'Break Off Added successfully') {
//                 toast.success(response.message, {
//                     position: 'top-center',
//                     autoClose: 1000,
//                 });
//                 navigate('/admin/breakofflist'); 
//             } else {

//             }
//         } catch (error) {

//         } finally {
//             setSubmitting(false);
//         }
//         toast.success('Breakoff employee add successfully', {
//             position: 'top-center',
//             autoClose: 1000,
//         });
//         navigate('/admin/breakofflist'); 
//     };

//     const fetchData = async () => {
//         try {
//             const formData = {};
//             const response = await viewEmployee(formData);
//             console.log(response);
//             if (!response || !response.success || !response.data) {
//                 throw new Error('Failed to fetch data.');
//             }
//             setEmployeeData(response.data);
//         } catch (error) {

//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchData();
//     }, []);


//     return (
//         <React.Fragment>
//             <Header />
//             <div className="main p-4 p-lg-5 mt-5">
//                 <div className="mb-4 border-bottom text-center">
//                     <h3 className="mb-0 pb- border-bottom custome-btn">Add Break Off</h3>
//                 </div>
//                 <div className="breadcrumb-warning d-flex justify-content-between ot-card">
//                     <div>
//                         <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
//                             <li className="breadcrumb-item">
//                                 <Link to="/admin/dashboard">Dashboard</Link>
//                             </li>
//                             <li className="breadcrumb-item active">
//                                 <Link to="/admin/breakofflist">Breakofflist</Link>
//                             </li>
//                             <li className="breadcrumb-item active">Add Break Off</li>
//                         </ol>
//                     </div>
//                     <nav aria-label="breadcrumb">
//                         <Link to="/admin/breakofflist" className="btn btn-danger mb-3">
//                             <svg
//                                 xmlns="http://www.w3.org/2000/svg"
//                                 width="16"
//                                 height="16"
//                                 fill="currentColor"
//                                 className="bi bi-arrow-left"
//                                 viewBox="0 0 16 16"
//                             >
//                                 <path
//                                     fillRule="evenodd"
//                                     d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
//                                 />
//                             </svg>
//                             Back
//                         </Link>
//                     </nav>
//                 </div>
//                 <div className="row">
//                     <div className="col-md-12 formcontrols">
//                         <div className="bg-white p-4 rounded" style={{ height: '100%' }}>
//                             <form onSubmit={handleSubmit}>
//                                 <div className="row">
//                                     <div className="col-md-6">
//                                         <div className="mb-3">
//                                             <label htmlFor="employeeId" className="form-label">
//                                                 Employee
//                                             </label>
//                                             <select
//                                                 className="form-select"
//                                                 id="employeeId"
//                                                 name="employeeId"
//                                                 value={state.employeeId}
//                                                 onChange={handleChange}
//                                             >
//                                                 <option value="">Select Employee</option>
//                                                 {Object.values(employeeData).map((employee) => (
//                                                     <option key={employee.employeeData._id} value={employee.employeeData._id}>
//                                                         {employee.employeeData.first_name} {employee.employeeData.last_name}
//                                                     </option>
//                                                 ))}
//                                             </select>
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6">
//                                         <div className="mb-3">
//                                             <label htmlFor="date" className="form-label">
//                                                 Date
//                                             </label>
//                                             <input
//                                                 type="date"
//                                                 className="form-control"
//                                                 id="date"
//                                                 name="date"
//                                                 value={state.date}
//                                                 onChange={handleChange}
//                                                 placeholder="Enter Date"
//                                             />
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <button
//                                     type="submit"
//                                     className="btn btn-primary"
//                                     disabled={submitting}
//                                 >
//                                     {submitting ? 'Submitting...' : 'Add Break Off'}
//                                 </button>
//                             </form>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </React.Fragment>
//     );
// };

// export default AddBreakOffForm;


import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../layouts/Header';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addBreakoffemp, viewEmployee } from '../../services/api/api';
import { useNavigate } from 'react-router-dom';

const AddBreakOffForm = () => {
    const navigate = useNavigate();
    const [state, setState] = useState({
        employeeId: '',
        date: '',
    });
    const [submitting, setSubmitting] = useState(false);
    const [employeeData, setEmployeeData] = useState({});
    const [loading, setLoading] = useState(true);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (submitting) {
            return;
        }
        setSubmitting(true);

        try {
            const response = await addBreakoffemp(state.employeeId, state.date);
            // if (response) {
            //     toast.success(response.message, {
            //         position: 'top-center',
            //         autoClose: 1000,
            //     });
            //     navigate('/admin/breakofflist');
            // }
            // else {
            //     toast.error(response.message, {
            //         position: 'top-center',
            //         autoClose: 1000,
            //     });
            // }
        } catch (error) {
            // Handle error
        } finally {
            setSubmitting(false);
        }
        toast.success('Breakoff employee add successfully', {
            position: 'top-center',
            autoClose: 1000,
        });
        navigate('/admin/breakofflist');
    };

    const fetchData = async () => {
        try {
            const formData = {};
            const response = await viewEmployee(formData);
            if (!response || !response.success || !response.data) {
                throw new Error('Failed to fetch data.');
            }
            setEmployeeData(response.data);
        } catch (error) {
            // Handle error
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <React.Fragment>
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
                <div className="mb-4 border-bottom text-center">
                    <h3 className="mb-0 pb- border-bottom custome-btn">Add Break Off</h3>
                </div>
                <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                    <div>
                        <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                            <li className="breadcrumb-item">
                                <Link to="/admin/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/admin/breakofflist">Breakofflist</Link>
                            </li>
                            <li className="breadcrumb-item active">Add Break Off</li>
                        </ol>
                    </div>
                    <nav aria-label="breadcrumb">
                        <Link to="/admin/breakofflist" className="btn btn-danger mb-3">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-arrow-left"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                                />
                            </svg>
                            Back
                        </Link>
                    </nav>
                </div>
                <div className="row">
                    <div className="col-md-12 formcontrols">
                        <div className="bg-white p-4 rounded" style={{ height: '100%' }}>
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="employeeId" className="form-label">
                                                Employee
                                            </label>
                                            <select
                                                className="form-select"
                                                id="employeeId"
                                                name="employeeId"
                                                value={state.employeeId}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Employee</option>
                                                {Object.values(employeeData).map((employee) => (
                                                    <option key={employee.employeeData._id} value={employee.employeeData._id}>
                                                        {employee.employeeData.first_name} {employee.employeeData.last_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="date" className="form-label">
                                                Date
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="date"
                                                name="date"
                                                value={state.date}
                                                onChange={handleChange}
                                                placeholder="Enter Date"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={submitting}
                                >
                                    {submitting ? 'Submitting...' : 'Add Break Off'}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AddBreakOffForm;




