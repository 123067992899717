import React, { useEffect, useState } from "react";
import _debounce from 'lodash/debounce';
import { debounce } from 'lodash';
import { Button, Card, Col, Row, Nav, Table, ProgressBar, Form, Badge, ListGroup, Spinner, CardBody } from "react-bootstrap";
import Footer from "../layouts/Footer.js";
import { Link } from "react-router-dom";
import Avatar from "../components/Avatar.js";
import { Bar } from 'react-chartjs-2';
import ReactApexChart from "react-apexcharts";
import { viewAnnouncement, viewHoliday, viewAddEmployeeLeave, editEmployee, getemployeeId, viewEmployeeBreakCount, viewholidaypagination } from "../services/api/api.js";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { getId, searchEmployeeBreakCount, singleViewEmployeeAttendance } from '../services/api/api';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';



import img19 from "../assets/img/img19.jpg";
import img27 from "../assets/img/img27.jpg";
import img28 from "../assets/img/img28.jpg";
import img29 from "../assets/img/img29.jpg";
import img30 from "../assets/img/img30.jpg";
import img31 from "../assets/img/img31.jpg";
import img32 from "../assets/img/img32.jpg";
import img33 from "../assets/img/img33.jpg";
import img34 from "../assets/img/img34.jpg";
import img35 from "../assets/img/img35.jpg";
import img36 from "../assets/img/img36.jpg";
import img37 from "../assets/img/img37.jpg";
import img38 from "../assets/img/img38.jpg";
import img39 from "../assets/img/img39.jpg";
import img40 from "../assets/img/img40.jpg";
import img41 from "../assets/img/img41.jpg";
import img42 from "../assets/img/img42.jpg";

// Import images
import img6 from "../assets/img/img6.jpg";
import img7 from "../assets/img/img7.jpg";
import img8 from "../assets/img/img8.jpg";
import img9 from "../assets/img/img9.jpg";
import img10 from "../assets/img/img10.jpg";
import img11 from "../assets/img/img11.jpg";
import img12 from "../assets/img/img12.jpg";
import img15 from "../assets/img/img15.jpg";
import img16 from "../assets/img/img16.jpg";
import img17 from "../assets/img/img17.jpg";
import img18 from "../assets/img/img18.jpg";
import img13 from "../assets/img/img13.jpg";
import img14 from "../assets/img/img14.jpg";




import Header from "../layouts/Header.js";

// Import clock component
import Clock from "../clock.js";
import { useNavigate } from "react-router-dom";
// import { DateCalendarServerRequest } from "../pages/attendance/calender.jsx";
import SingleAttendanceView from "../pages/attendance/calender.jsx";
import EmployeeBreakhistory from "../pages/employee/break/breakhistory.js";
import EmployeeBreakstatic from "../pages/employee/break/breakstatics.js";
import EmployeeBreakCalendar from "../pages/breakemp/empcalender.js";
import DashbaordBreakCalendar from "../pages/breakemp/dashbreakcalender.js";
// import Upcomingbirthday from "./upcomingbirthday.jsx";

export default function EmployeeDashboard() {

  const [holidayData, setHolidayData] = useState([]);
  const [masterId, setMasterId] = useState('');
  const [loading, setLoading] = useState(true);
  const [announcements, setAnnouncements] = useState([]);
  const [addEmployeeDataData, setAddEmployeeDataData] = useState([]);
  const [remainingLeaves, setRemainingLeaves] = useState(0);

  const [events, setEvents] = useState([]);

  // const [searchParams, setSearchParams] = useState({
  //   year: new Date().getFullYear(),
  //   month: new Date().getMonth() + 1, // Months are 0-indexed, so adding 1
  // });
  const [searchParams, setSearchParams] = useState({ year: 2024, month: 9 }); // Default: September 2024
  const [daysInMonth, setDaysInMonth] = useState(30);

  const [calendarDate, setCalendarDate] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1, // Months are 0-indexed
  });


  const monthsMap = [
    'january', 'february', 'march', 'april', 'may', 'june',
    'july', 'august', 'september', 'october', 'november', 'december'
  ];


  var showDate = new Date()
  var DisplayDate = showDate.getDay + '-' + showDate.getDate + '-' + showDate.getMonth + '-' + showDate.getFullYear
  var dt = showDate.toDateString()




  const handleSearch = (e) => {
    e.preventDefault();
    fetchEmployeeBreakCount();
  };


  useEffect(() => {
    const debouncedFetchEmployeeBreakCount = _debounce(fetchEmployeeBreakCount, 0);

    debouncedFetchEmployeeBreakCount();

    return () => {
      debouncedFetchEmployeeBreakCount.cancel();
    };
  }, [searchParams]);

  const fetchEmployeeBreakCount = async () => {
    console.log(searchParams);

    try {
      setLoading(true);
      const employeeId = getId();
      let apiResponse;
      const monthName = monthsMap[searchParams.month - 1];
      if (employeeId) {

        apiResponse = await singleViewEmployeeAttendance(
          employeeId,
          searchParams.year,
          monthName
        );
      }



      const parsedEvents = parseApiResponse(apiResponse, searchParams.year, searchParams.month);
      setEvents(parsedEvents);
      console.log(parsedEvents);

    } catch (error) {
      console.error('Error fetching employee break count:', error);
    } finally {
      setLoading(false);
    }
  };


  const getLastDateOfMonth = (year, month) => {
    return new Date(year, month, 0).getDate(); // Month is 0-indexed, so use 0 for the last day
  };


  const handleYearChange = (e) => {
    const newYear = parseInt(e.target.value, 10);
    setSearchParams((prev) => ({ ...prev, year: newYear })); // Update year
  };

  // Handler for month change
  const handleMonthChange = (e) => {
    const newMonth = parseInt(e.target.value, 10);
    setSearchParams((prev) => ({ ...prev, month: newMonth })); // Update month
  };

  useEffect(() => {
    setDaysInMonth(getLastDateOfMonth(searchParams.year, searchParams.month));
  }, [searchParams.year, searchParams.month]);


  // const parseApiResponse = (apiResponse, year, month) => {
  //   const formattedEvents = [];
  //   const allDates = [];
  //   const startDate = new Date("2024-09-01");
  //   const endDate = new Date("2024-09-30");
  //   const today = new Date().toISOString().split('T')[0]; // Current date in 'YYYY-MM-DD' format

  //   // Generate all dates between start and end date
  //   for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
  //     const currentDate = date.toISOString().split('T')[0];
  //     if (currentDate <= today) { // Exclude dates greater than today
  //       allDates.push(currentDate);
  //     }
  //   }

  //   // Get holiday dates
  //   const holidayDates = apiResponse.data[0].holidayList.flatMap(holiday =>
  //     Object.values(holiday.company_holiday).map(h => h.holiday_date)
  //   );

  //   // Get working days and weekend days
  //   const workingDays = apiResponse.data[0].workingDay[0];
  //   const weekendDays = ['sunday', 'saturday'].filter(day => !workingDays[day]);

  //   // Check for holidays, weekends, and attendance, with priority to attendance
  //   allDates.forEach((date) => {
  //     // Check for attendance first
  //     const attendance = apiResponse.data[0].attendance_list.find(att => {
  //       const attDate = att.attendance_time.split(" ")[0];
  //       return attDate === date;
  //     });

  //     if (attendance) {
  //       // Prioritize attendance if found
  //       let title = '';
  //       let dotColor = '';

  //       // Check attendanceCheck values for display
  //       switch (attendance.attendanceCheck) {
  //         case 1:
  //           title = 'Present';
  //           dotColor = 'blue';
  //           break;
  //         case 2:
  //           title = 'Half Day';
  //           dotColor = '#ff9800'; // Orange for half day
  //           break;
  //         case 3:
  //           title = 'Weekend';
  //           dotColor = 'purple';
  //           break;
  //         case 4:
  //           title = 'Paid Leave';
  //           dotColor = 'green';
  //           break;
  //         case 0:
  //         default:
  //           title = 'Absent';
  //           dotColor = '#de3c3c'; // Red for absent
  //           break;
  //       }

  //       formattedEvents.push({
  //         title,
  //         date,
  //         time: attendance.attendance_time.split(" ")[1], // Just the time
  //         dotColor,
  //       });

  //     } else if (holidayDates.includes(date)) {
  //       // Check for holidays
  //       formattedEvents.push({
  //         title: 'Holiday',
  //         date: date,
  //         time: '', // No time for holidays
  //         dotColor: 'purple', // Purple for holiday
  //       });
  //     } else if (weekendDays.includes(new Date(date).toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase())) {
  //       // Check for weekends
  //       formattedEvents.push({
  //         title: 'Weekend',
  //         date: date,
  //         time: '', // No time for weekends
  //         dotColor: 'purple', // Purple for weekend
  //       });
  //     } else {
  //       // Default to absent if no attendance or holiday or weekend
  //       formattedEvents.push({
  //         title: 'Absent',
  //         date: date,
  //         time: '', // No time for absent
  //         dotColor: '#de3c3c', // Red for absent
  //       });
  //     }
  //   });

  //   return formattedEvents;
  // };

  const parseApiResponse = (apiResponse, year, month) => {
    const formattedEvents = [];
    const allDates = [];

    // Calculate dynamic start and end dates
    const startDate = new Date(year, month - 1, 1); // First day of the selected month
    const endDate = new Date(year, month, 0); // Last day of the selected month
    const today = new Date().toISOString().split('T')[0]; // Current date in 'YYYY-MM-DD' format

    console.log(startDate, endDate);


    // Generate all dates between start and end date
    for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
      console.log(date);

      const currentDate = date.toISOString().split('T')[0];
      console.log(currentDate);

      if (currentDate <= today) { // Exclude dates greater than today
        allDates.push(currentDate);
      }
    }

    // Get holiday dates
    const holidayDates = apiResponse.data[0].holidayList.flatMap(holiday =>
      Object.values(holiday.company_holiday).map(h => h.holiday_date)
    );

    // Get working days and weekend days
    const workingDays = apiResponse.data[0].workingDay[0];
    const weekendDays = ['sunday', 'saturday'].filter(day => !workingDays[day]);

    // Check for holidays, weekends, and attendance, with priority to attendance
    allDates.forEach((date) => {
      const attendance = apiResponse.data[0].attendance_list.find(att => {
        const attDate = att.attendance_time.split(" ")[0];
        return attDate === date;
      });

      if (attendance) {
        let title = '';
        let dotColor = '';

        switch (attendance.attendanceCheck) {
          case 1:
            title = 'Present';
            dotColor = 'blue';
            break;
          case 2:
            title = 'Half Day';
            dotColor = '#ff9800'; // Orange for half day
            break;
          case 3:
            title = 'Weekend';
            dotColor = 'purple';
            break;
          case 4:
            title = 'Paid Leave';
            dotColor = 'green';
            break;
          case 0:
          default:
            title = 'Absent';
            dotColor = '#de3c3c'; // Red for absent
            break;
        }

        formattedEvents.push({
          title,
          date,
          time: attendance.attendance_time.split(" ")[1],
          dotColor,
        });

      } else if (holidayDates.includes(date)) {
        formattedEvents.push({
          title: 'Holiday',
          date: date,
          time: '',
          dotColor: 'purple',
        });
      } else if (weekendDays.includes(new Date(date).toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase())) {
        formattedEvents.push({
          title: 'Weekend',
          date: date,
          time: '',
          dotColor: 'purple',
        });
      } else {
        formattedEvents.push({
          title: 'Absent',
          date: date,
          time: '',
          dotColor: '#de3c3c',
        });
      }
    });

    return formattedEvents;
  };



  useEffect(() => {
    const fetchEmpLeave = async () => {
      try {
        setLoading(true);
        // Reset the remainingLeaves before fetching new data
        setRemainingLeaves(0);

        const formattedData = await viewAddEmployeeLeave();

        if (!formattedData || !formattedData.length) {
          throw new Error('Failed to fetch data.');
        }

        const addEmployeeLeave = formattedData[0].employee_leave;
        setMasterId(formattedData[0]._id);
        const addEmployeeLeaveArray = Object.values(addEmployeeLeave).map(item => ({
          _id: item._id,
          leave_type: item.leave_type || 'N/A',
          from_date: item.from_date || 'N/A',
          to_date: item.to_date || 'N/A',
          total_days: item.total_days || 'N/A',
          remaining_leaves: item.remaining_leaves !== null ? item.remaining_leaves.toString() : '0',
          leave_reason: item.leave_reason || 'N/A',
        }));

        setAddEmployeeDataData(addEmployeeLeaveArray);

        // Calculate total remaining leaves without using reduce
        let totalRemainingLeaves = 0;
        addEmployeeLeaveArray.forEach(item => {
          if (item.remaining_leaves) {
            totalRemainingLeaves = parseInt(item.remaining_leaves, 10);
          }
        });

        setRemainingLeaves(totalRemainingLeaves);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    // fetchEmpLeave();
    const debouncedFetchEmpLeave = _debounce(fetchEmpLeave, 50);

    debouncedFetchEmpLeave();

    return () => {
      debouncedFetchEmpLeave.cancel();
    };
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const formattedData = await viewAnnouncement();

  //       if (!formattedData || !formattedData.length) {
  //         throw new Error('Failed to fetch data.');
  //       }

  //       const companyAnnouncement = formattedData[0].announcement;
  //       setMasterId(formattedData[0]._id);
  //       const announcementsArray = Object.values(companyAnnouncement).map(item => ({

  //         _id: item._id,
  //         announcement_subject: item.announcement_subject || 'N/A',
  //       }));

  //       setAnnouncements(announcementsArray);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchData()
  // }, []);

  useEffect(() => {
    const debouncedFetchData = _debounce(async () => {
      try {
        const formattedData = await viewAnnouncement();

        if (!formattedData || !formattedData.length) {
          throw new Error('Failed to fetch data.');
        }

        const companyAnnouncement = formattedData[0].announcement;
        setMasterId(formattedData[0]._id);
        const announcementsArray = Object.values(companyAnnouncement).map(item => ({
          _id: item._id,
          announcement_subject: item.announcement_subject || 'N/A',
        }));

        setAnnouncements(announcementsArray);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    }, 50);

    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, []);


  const fetchHolidayData = async () => {
    try {
      const formattedData = await viewHoliday();

      if (!formattedData || !formattedData.length) {
        throw new Error('Failed to fetch data.');
      }

      const companyHoliday = formattedData[0].company_holiday;
      setMasterId(formattedData[0]._id);
      const holidaysArray = Object.values(companyHoliday).map(item => ({

        _id: item._id,
        holiday_name: item.holiday_name || 'N/A',
        holiday_date: item.holiday_date ? (item.holiday_date) : 'N/A',
        // holiday_description: item.holiday_description || 'N/A',
      }));

      setHolidayData(holidaysArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchHolidayData();
  // }, []);
  useEffect(() => {
    const debouncedFetchHolidayData = _debounce(fetchHolidayData, 50);

    debouncedFetchHolidayData();

    return () => {
      debouncedFetchHolidayData.cancel();
    };
  }, []);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month starts from 0
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };





  const [id, setId] = useState('');
  useEffect(() => {
    const id = getemployeeId();
    setId(id);
  }, []);


  const [state, setState] = useState({
    _id: '',
    company_id: '',
    first_name: '',
    last_name: '',
    email: '',
    joining_date: '',
    phone: '',
    department: '',
    designation: '',
    password: '',
    salary: '',
    shift: '',
    branch: '',
  });

  // useEffect(() => {
  //   async function fetchemployeeData() {
  //     try {

  //       const employeeData = await editEmployee(id);
  //       console.log(employeeData);


  //       if (employeeData && employeeData.success && employeeData.success.length > 0) {
  //         const employee = employeeData.success[0];

  //         setState({
  //           _id: employee._id || '',
  //           company_id: employee.company_id || '',
  //           first_name: employee.first_name || '',
  //           last_name: employee.last_name || '',
  //           email: employee.email || '',
  //           joining_date: employee.joining_date || '',
  //           phone: employee.phone || '',
  //           department: employee.department || '',
  //           designation: employee.designation || '',
  //           branch: employee.branch || '',
  //           // password: employee.password || '',
  //           salary: employee.salary || '',
  //           shift: employee.shift || '',
  //           file: employee.file || [],
  //         });

  //         // const userBranches = employee.branch ? employee.branch.split(',') : [];
  //       } else {
  //         console.error('No employee data found');
  //       }
  //     } catch (error) {
  //       console.error('Error fetching employee data:', error);
  //     }


  //   }

  //   fetchemployeeData();
  // }, [id]);


  useEffect(() => {
    const debouncedFetchEmployeeData = debounce(async () => {
      try {
        const employeeData = await editEmployee(id);
        console.log(employeeData);

        if (employeeData && employeeData.success && employeeData.success.length > 0) {
          const employee = employeeData.success[0];

          setState({
            _id: employee._id || '',
            company_id: employee.company_id || '',
            first_name: employee.first_name || '',
            last_name: employee.last_name || '',
            email: employee.email || '',
            joining_date: employee.joining_date || '',
            phone: employee.phone || '',
            department: employee.department || '',
            designation: employee.designation || '',
            branch: employee.branch || '',
            // password: employee.password || '',
            salary: employee.salary || '',
            shift: employee.shift || '',
            file: employee.file || [],
          });

          // const userBranches = employee.branch ? employee.branch.split(',') : [];
        } else {
          console.error('No employee data found');
        }
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    }, 2000);

    debouncedFetchEmployeeData();

    return () => {
      debouncedFetchEmployeeData.cancel();
    };
  }, [id]);






  const [breakcountData, setBreakcountData] = useState([]);

  const [employeeId, setEmployeeId] = useState('');
  const reversedRows = [...breakcountData].reverse();
  const calculateTotalDuration = (breaks) => {
    const totalDuration = breaks.reduce((total, breakItem) => total + breakItem.duration_minutes, 0);
    const hours = Math.floor(totalDuration / 60);
    const minutes = totalDuration % 60;

    return `${hours}h ${minutes}m`;
  };
  const formatTime = (time) => {
    const formattedTime = new Date(`2022-01-01 ${time}`);
    return formattedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };
  useEffect(() => {
    const empId = getId();
    setEmployeeId(empId);
  }, []);

  // const fetchData = async () => {
  //   try {
  //     const formattedData = await viewEmployeeBreakCount(employeeId);

  //     if (!formattedData || !formattedData.data.length) {
  //       throw new Error('Failed to fetch data.');
  //     }

  //     const breakList = formattedData.data[0].break_list;
  //     const branchArray = breakList.flatMap((item, index) =>
  //       Object.entries(item.attendance).map(([date, attendanceData]) => ({
  //         id: `${index}_${date}`,
  //         date: attendanceData.date,
  //         name: attendanceData.name,
  //         type: attendanceData.breaks[0].type,
  //         time: formatTime(attendanceData.breaks[0].time),
  //         breaks: attendanceData.breaks.map(breakItem => ({
  //           type: breakItem.type,
  //           time: formatTime(breakItem.time),
  //           duration_minutes: breakItem.duration_minutes || 0,
  //         })),
  //       }))
  //     );

  //     setBreakcountData(branchArray);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, [employeeId]);


  const fetchData = async () => {
    try {
      const formattedData = await viewEmployeeBreakCount(employeeId);

      if (!formattedData || !formattedData.data.length) {
        throw new Error('Failed to fetch data.');
      }

      const breakList = formattedData.data[0].break_list;
      const branchArray = breakList.flatMap((item, index) =>
        Object.entries(item.attendance).map(([date, attendanceData]) => ({
          id: `${index}_${date}`,
          date: attendanceData.date,
          name: attendanceData.name,
          type: attendanceData.breaks[0].type,
          time: formatTime(attendanceData.breaks[0].time),
          breaks: attendanceData.breaks.map(breakItem => ({
            type: breakItem.type,
            time: formatTime(breakItem.time),
            duration_minutes: breakItem.duration_minutes || 0,
          })),
        }))
      );

      setBreakcountData(branchArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchData = _debounce(fetchData, 50);

  useEffect(() => {
    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [employeeId]);

  const dataBar1 = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [{
      data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
      backgroundColor: '#506fd9',
      barPercentage: 0.5
    }, {
      data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
      backgroundColor: '#85b6ff',
      barPercentage: 0.5
    }, {
      data: [8, 30, 40, 35, 40, 45, 35, 30, 25, 10, 20, 15],
      backgroundColor: '#e2e5ec',
      barPercentage: 0.5
    }]
  };

  const optionBar1 = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 80,
        ticks: {
          color: '#a1aab3',
          font: {
            size: 10
          }
        },
        grid: {
          borderColor: '#e2e5ec',
          borderWidth: 1.5,
          color: 'rgba(65,80,95,.08)'
        }
      },
      x: {
        ticks: {
          color: '#313c47'
        },
        grid: {
          color: 'rgba(65,80,95,.08)'
        }
      }
    }
  };


  const [tooltip, setTooltip] = useState({ visible: false, text: '', x: 0, y: 0 });

  const showTooltip = (event, time) => {
    setTooltip({ visible: true, text: time ? `Time: ${time}` : 'No Attendance Recorded', x: event.clientX, y: event.clientY });
  };

  const hideTooltip = () => {
    setTooltip({ ...tooltip, visible: false });
  };




  const dataBar2 = {
    labels: ['Modification', 'Code Request', 'Feature Request', 'Bug Fix', 'Integration', 'Production'],
    datasets: [{
      data: [20, 60, 50, 45, 50, 75],
      backgroundColor: ['#506fd9', '#85b6ff', '#33d685', '#ffc107', '#0dcaf0', '#ea4c89'],
      barPercentage: 0.5
    }, {
      data: [10, 40, 30, 40, 60, 55],
      backgroundColor: '#e2e5ec',
      barPercentage: 0.5
    }]
  };

  const optionBar2 = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 100,
        grid: {
          borderColor: '#e2e5ec',
          color: '#f3f5f9'
        },
        ticks: {
          font: {
            size: 11
          }
        }
      },
      y: {
        grid: {
          borderWidth: 0,
          color: '#f3f5f9'
        },
        ticks: {
          color: '#465463',
          font: {
            size: 13
          }
        }
      }
    }
  };

  const optionOne = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false },
      stacked: true,
      sparkline: { enabled: true }
    },
    colors: ['#506fd9'],
    stroke: {
      curve: 'straight',
      width: 2
    },
    xaxis: { max: 40 },
    yaxis: {
      min: 0,
      max: 80
    },
    fill: {
      type: 'solid',
      opacity: 0.2
    },
    tooltip: { enabled: false }
  }

  // Time to Resolved Complain
  const optionDonut1 = {
    chart: { parentHeightOffset: 0 },
    colors: ['#506fd9', '#e5e9f2'],
    dataLabels: { enabled: false },
    legend: { show: false }
  };

  const optionDonut2 = {
    chart: { parentHeightOffset: 0 },
    colors: ['#4c5366', '#e5e9f2'],
    dataLabels: { enabled: false },
    legend: { show: false }
  }

  // Agent Performance Score
  const optionTwo = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false },
      stacked: true
    },
    colors: ['#4f6fd9'],
    grid: {
      borderColor: 'rgba(72,94,144, 0.08)',
      padding: {
        top: -20,
        left: 0,
        right: -8
      },
      yaxis: {
        lines: { show: false }
      }
    },
    dataLabels: { enabled: false },
    stroke: {
      curve: 'straight',
      width: 1.5
    },
    xaxis: {
      overwriteCategories: ['', 'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC', ''],
      tickAmount: 13,
      axisBorder: { show: false },
      labels: {
        style: {
          colors: '#6e7985',
          fontSize: '10px'
        }
      },
    },
    yaxis: {
      min: 0,
      max: 50,
      show: false
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
      }
    },
    legend: { show: false },
    tooltip: { enabled: false }
  }

  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const breakHistory = [
    {
      id: 1,
      employee: 'John Doe',
      scheduledBreak: '12:00 PM - 12:30 PM',
      status: 'On Break',
    },
    // Add more break history data as needed
  ]; const NoHistoryComponent = () => (
    <div className="text-center py-3">
      <p className="mb-0">No break history available.</p>
      <p className="text-muted">Start managing breaks to track the history.</p>
    </div>
  );

  const padd = {
    paddingLeft: '24px',
  };

  const seriesPie = [44, 55, 13,];
  const optionsPie = {
    chart: {
      type: 'pie',
    },
    labels: ['Utilized(5)', 'Casual(4)', ' Sick(2)'],
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link href="/employee/dashboard">Dashboard</Link></li>
              {/* <li className="breadcrumb-item active" aria-current="page">Helpdesk Service</li> */}
            </ol>
            <h4 className="main-title mb-2">Welcome, {state.first_name} {state.last_name}</h4>

            <p className="text-secondary mb-0">{dt}</p>

          </div>
          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
            <Button variant="white" className="btn-icon"><i className="ri-share-line fs-18 lh-1"></i></Button>
            <Button variant="white" className="btn-icon"><i className="ri-printer-line fs-18 lh-1"></i></Button>
            <Button variant="primary" className="btn-icon"><i className="ri-bar-chart-2-line fs-18 lh-1"></i></Button>
          </div>
        </div>

        <Row className="g-3">
          <Col md="7" xl="6">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Your Attendance</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <CardBody>
                <Form onSubmit={handleSearch}>
                  <Row className="mb-3">
                    <Col>
                      <Form.Control
                        type="number"
                        placeholder="Year"
                        value={searchParams.year}
                        onChange={handleYearChange}
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        as="select"
                        value={searchParams.month}
                        onChange={handleMonthChange}
                      >
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </Form.Control>
                    </Col>
                    <Col>
                      <Button type="submit">Search</Button>
                    </Col>
                  </Row>
                </Form>

                {loading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (

                  // <FullCalendar
                  //   plugins={[dayGridPlugin]}
                  //   initialView="dayGridMonth"
                  //   events={events} // Parsed events from API response
                  //   eventContent={(eventInfo) => {
                  //     const dotColor = eventInfo.event.extendedProps.dotColor; // Get dot color from event props
                  //     const time = eventInfo.event.extendedProps.time; // Get time for present attendance
                  //     const title = eventInfo.event.title;

                  //     return (
                  //       <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'transparent' }}>
                  //         <div
                  //           style={{
                  //             width: '10px',
                  //             height: '10px',
                  //             borderRadius: '30%',
                  //             backgroundColor: dotColor,
                  //             border: `2px solid ${dotColor}`, // Outline color
                  //             marginRight: '5px',
                  //             cursor: 'pointer',
                  //             position: 'absolute',
                  //             top: '-25px',
                  //             left: '4px'
                  //           }}
                  //           title={time ? `Time: ${time} - ${title}` : title} // Tooltip shows time or event title (Holiday, Weekend, etc.)
                  //         ></div>
                  //       </div>
                  //     );
                  //   }}
                  // />



                  <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    // Use state variables for dynamic initialDate
                    initialDate={`${searchParams.year}-${String(searchParams.month).padStart(2, '0')}-01`} // Set initial date
                    events={events}
                    eventContent={(eventInfo) => {
                      const dotColor = eventInfo.event.extendedProps.dotColor;
                      const time = eventInfo.event.extendedProps.time;
                      const title = eventInfo.event.title;

                      return (
                        <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'transparent' }}>
                          <div
                            style={{
                              width: '10px',
                              height: '10px',
                              borderRadius: '30%',
                              backgroundColor: dotColor,
                              border: `2px solid ${dotColor}`,
                              marginRight: '5px',
                              cursor: 'pointer',
                              position: 'absolute',
                              top: '-25px',
                              left: '4px'
                            }}
                            title={time ? `Time: ${time} - ${title}` : title}
                          ></div>
                        </div>
                      );
                    }}
                  />

                )}

              </CardBody>
            </Card>
          </Col>
          <Col md="5" xl="6">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Notice Board</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>

              {loading ? (
                <div className="loader-container">
                  <div className="loader"></div>
                </div>
              ) : (
                <Card.Body className="p-0">
                  <marquee behavior="scroll" direction="up" height="250">
                    {announcements.map((announcement, index) => (
                      <div className="border rounded p-3 mb-3" key={index}>
                        <h6>
                          {announcement.announcement_subject}
                          <Badge bg="secondary" className="ms-2">
                            {announcement.timestamp}
                          </Badge>
                        </h6>
                        <p className="text-secondary">{announcement.content}</p>
                        <div className="d-flex justify-content-between align-items-center">
                          <Button variant="primary" className="me-2">
                            Like
                          </Button>
                        </div>
                      </div>
                    ))}
                  </marquee>

                </Card.Body>
              )}
            </Card>
          </Col>





          {/* <Col md="7" xl="6">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Upcoming birthdays</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <Col xs="12" sm>
                  <Table className="table-music mb-0">
                    <tbody>
                      {[
                        {
                          "img": img19,
                          "title": "Mehul Parmar",
                          "artist": "Senior Developer",
                          "date": "01/12/1997"
                        }, {
                          "img": img30,
                          "title": "Dipali Khopade",
                          "artist": "Web Developer",
                          "date": "01/12/1997"
                        }, {
                          "img": img31,
                          "title": "Neha Dalsania",
                          "artist": "Web Developer",
                          "date": "02/12/1997"
                        }, {
                          "img": img32,
                          "title": "Priti Malode",
                          "artist": "Web Developer",
                          "date": "02/12/1997"
                        }, {
                          "img": img33,
                          "title": "Reena Pipaliya",
                          "artist": "Web Developer",
                          "date": "02/12/1997"
                        }
                      ].map((music, index) => (
                        <tr key={index}>
                          <td className="w-5">{index + 1}</td>
                          <td className="w-75">
                            <div className="media-music">
                              <Link to="" className="media-img"><img src={music.img} alt="" /></Link>
                              <div className="media-body">
                                <h6><Link to="">{music.title}</Link></h6>
                                <span>{music.artist}</span>
                              </div>
                            </div>
                          </td>
                          <td className="w-15"><span className="time">{music.date}</span></td>
                          <td className="w-5">
                            <Link to="" className="dropdown-link"><i className="ri-more-2-fill"></i></Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Card.Body>
            </Card>
          </Col> */}
          <Col md="6" xl="6">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Your Break History</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-0">



                {/* <EmployeeBreakstatic/> */}
                <EmployeeBreakhistory />



                {/* <Table responsive striped bordered hover className="m-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Employee</th>
                      <th>Scheduled Break</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>John Doe</td>
                      <td>12:00 PM - 12:30 PM</td>
                      <td>
                        <span className="badge bg-success">On Break</span>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Jane Smith</td>
                      <td>-</td>
                      <td>
                        <Button variant="primary" size="sm">Start Break</Button>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Emily Johnson</td>
                      <td>02:30 PM - 03:00 PM</td>
                      <td>
                        <span className="badge bg-warning text-dark">Pending</span>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Michael Brown</td>
                      <td>01:45 PM - 02:15 PM</td>
                      <td>
                        <span className="badge bg-success">On Break</span>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Olivia Williams</td>
                      <td>-</td>
                      <td>
                        <Button variant="primary" size="sm">Start Break</Button>
                      </td>
                    </tr>

                  </tbody>
                </Table> */}






              </Card.Body>
            </Card>
          </Col>

          <Col md="6" xl="6">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">UPCOMING HOLIDAY</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-0">
                <hr className="my-3 opacity-0" />
                <ul className="event-group" style={padd}>
                  {holidayData.map(holiday => (
                    <li className="event-item" key={holiday._id}>
                      <span style={{ width: "50px", height: "50px" }}>
                        <HolidayVillageIcon style={{ fontSize: "2.5rem", color: "blueviolet" }} />
                      </span>
                      {/* <div className={`event-icon ${getEventIconColor(holiday.holiday_name)}`}>
                        <i className={getEventIcon(holiday.holiday_name)}></i>
                      </div> */}
                      <div className="event-body ">
                        <h6><Link to="">{holiday.holiday_name}</Link></h6>
                        <p>{holiday.holiday_date}</p>
                        <p >{holiday.holiday_description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
                <hr className="my-4 opacity-0" />
              </Card.Body>
            </Card>

          </Col>

          {/* break statistics  */}
          {/* <Col md="7" xl="7">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Break Statics</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <Col xs="12" sm>
                  <DashbaordBreakCalendar />
                </Col>
              </Card.Body>
            </Card>
          </Col> */}


          <Col md="7" xl="6">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Your Remaining Leaves: {remainingLeaves}</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <Col xs="12" sm>
                  <Link to="/employee/addleave">
                    <Button variant="primary">Apply Leave</Button>
                  </Link>
                  <p>Leave Taken In this month: 0</p>
                </Col>
                <ReactApexChart series={seriesPie} options={optionsPie} type="pie" width={380} />


              </Card.Body>
            </Card>
          </Col>
          {/* <Col md="5" xl="6">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">UPCOMING HOLIDAY</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-0">
                <hr className="my-3 opacity-0" />
                <ul className="event-group" style={padd}>
                  <li className="event-item">
                    <div className="event-icon"><i className="ri-cake-2-line"></i></div>
                    <div className="event-body">
                      <h6><Link to="">New Year</Link></h6>
                      <p>Tomorrow, April 21, 6:00pm</p>
                    </div>
                  </li>
                  <li className="event-item">
                    <div className="event-icon bg-secondary"><i className="ri-restaurant-fill"></i></div>
                    <div className="event-body">
                      <h6><Link to="">Food Event</Link></h6>
                      <p>Friday, April 27, 8:00am</p>
                    </div>
                  </li>
                  <li className="event-item">
                    <div className="event-icon bg-primary"><i className="ri-roadster-line"></i></div>
                    <div className="event-body">
                      <h6><Link to="">Monster Event</Link></h6>
                      <p>Saturday, April 30, 9:00m</p>
                    </div>
                  </li>
                </ul>

                <hr className="my-4 opacity-0" />




              </Card.Body>
            </Card>
          </Col> */}

          {/* <Col md="5" xl="6">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">UPCOMING HOLIDAY</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-0">
                <hr className="my-3 opacity-0" />
                <ul className="event-group" style={padd}>
                  {holidayData.map(holiday => (
                    <li className="event-item" key={holiday._id}>
                      <span style={{ width: "50px", height: "50px" }}>
                        <HolidayVillageIcon style={{ fontSize: "2.5rem", color: "blueviolet" }} />
                      </span>
                      
                      <div className="event-body ">
                        <h6><Link to="">{holiday.holiday_name}</Link></h6>
                        <p>{holiday.holiday_date}</p>
                        <p >{holiday.holiday_description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
                <hr className="my-4 opacity-0" />
              </Card.Body>
            </Card>

          </Col> */}

          <Col md="5" xl="6">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Break Statics</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <Col xs="12" sm>
                  <DashbaordBreakCalendar />
                </Col>
              </Card.Body>
            </Card>
          </Col>

          {/* <Col md="6">
            <Card className="card-one">
              <Card.Body className="p-3">
                <Row className="g-3 row-cols-auto align-items-center">
                  <Col>
                    <div className="apex-donut-one">
                      <ReactApexChart series={[86, 14]} options={optionDonut1} width={160} height={160} type="donut" />
                      <div>
                        <h4 className="ff-numerals text-dark mb-0">86%</h4>
                        <span className="fs-xs text-secondary">Reached</span>
                      </div>
                    </div>
                  </Col>
                  <Col xs="6" xl="7">
                    <h2 className="card-value mb-3"></h2>
                    <h6 className="card-label text-dark fw-semibold mb-1">Total attendance</h6>
                    <p className="fs-sm text-secondary mb-0">The average time taken to resolve complaints.</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6">
            <Card className="card-one">
              <Card.Body className="p-3">
                <Row className="g-3 row-cols-auto align-items-center">
                  <Col>
                    <div className="apex-donut-one">
                      <ReactApexChart series={[68, 32]} options={optionDonut2} width={160} height={160} type="donut" />
                      <div>
                        <h4 className="ff-numerals text-dark mb-0">68%</h4>
                        <span className="fs-xs text-secondary">Reached</span>
                      </div>
                    </div>
                  </Col>
                  <Col xs="6" xl="7">
                    <h2 className="card-value mb-3"></h2>
                    <h6 className="card-label text-dark fw-semibold mb-1">Your attendance</h6>
                    <p className="fs-sm text-secondary mb-0">The average time taken to resolve complaints.</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col> */}
          <Col xl="7">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Upcoming Birthday</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                {/* <Upcomingbirthday /> */}
              </Card.Body>
            </Card>
          </Col>
          {/* <Col sm="6" xl>
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">Overall Rating</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                    <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body>
                  <div className="d-flex align-items-baseline gap-2 mb-0">
                    <h1 className="card-value mb-0">4.8</h1>
                    <div className="d-flex gap-1 text-warning fs-20">
                      <i className="ri-star-fill"></i>
                      <i className="ri-star-fill"></i>
                      <i className="ri-star-fill"></i>
                      <i className="ri-star-fill"></i>
                      <i className="ri-star-half-fill"></i>
                    </div>
                  </div>
                  <p className="fs-sm">Measures the quality or your support team's efforts.</p>
  
                  <Table className="table-ratings mb-0">
                    <tbody>
                      {[
                        {
                          "rating": "5.0",
                          "star": ["fill", "fill", "fill", "fill", "fill"],
                          "count": "4,230",
                          "percent": "58%"
                        }, {
                          "rating": "4.0",
                          "star": ["fill", "fill", "fill", "fill", "line"],
                          "count": "1,416",
                          "percent": "26%"
                        }, {
                          "rating": "3.0",
                          "star": ["fill", "fill", "fill", "line", "line"],
                          "count": "980",
                          "percent": "16%"
                        }, {
                          "rating": "2.0",
                          "star": ["fill", "fill", "line", "line", "line"],
                          "count": "798",
                          "percent": "12%"
                        }, {
                          "rating": "1.0",
                          "star": ["fill", "line", "line", "line", "line"],
                          "count": "401",
                          "percent": "8%"
                        }
                      ].map((item, index) => (
                        <tr key={index}>
                          <td><strong>{item.rating}</strong></td>
                          <td>
                            <div className="d-flex gap-1 text-warning fs-16">
                              {item.star.map((star, ind) => (
                                <i key={ind} className={"ri-star-" + star}></i>
                              ))}
                            </div>
                          </td>
                          <td>{item.count}</td>
                          <td>{item.percent}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col> */}
          {/* <Col sm="5" xl>
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Performance Score</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <div className="d-flex align-items-baseline gap-2 mb-0">
                  <h1 className="card-value mb-0">9.6</h1>
                  <ProgressBar className="flex-fill ht-5">
                    <ProgressBar now={20} />
                    <ProgressBar now={15} variant="success" />
                    <ProgressBar now={15} variant="warning" />
                    <ProgressBar now={20} variant="info" />
                    <ProgressBar now={10} variant="danger" />
                  </ProgressBar>
                </div>
                <p className="fs-sm">The percentage value assigned to the operating metric.</p>
                <Table className="table-ratings mb-0">
                  <tbody>
                    {[
                      {
                        "dot": "primary",
                        "label": "Mehul",
                        "count": "",
                        "percent": "100%"
                      }, {
                        "dot": "success",
                        "label": "Reena",
                        "count": "",
                        "percent": "95%"
                      }, {
                        "dot": "warning",
                        "label": "Dipali",
                        "count": "",
                        "percent": "80%"
                      }, {
                        "dot": "info",
                        "label": "Priti",
                        "count": "",
                        "percent": "70%"
                      }, {
                        "dot": "danger",
                        "label": "Khushali",
                        "count": "",
                        "percent": "50%"
                      }
                    ].map((item, index) => (
                      <tr key={index}>
                        <td><span className={"badge-dot bg-" + item.dot}></span></td>
                        <td><strong>{item.label}</strong></td>
                        <td>{item.count}</td>
                        <td>{item.percent}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col> */}
          {/* <Col xs="12">
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">Agent Performance Score</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                    <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body>
                  <ReactApexChart series={seriesOne} options={optionTwo} type="area" height={200} className="apex-chart-four mb-5" />
  
                  <Table className="table-agent mb-0" responsive>
                    <thead>
                      <tr>
                        <th>
                          <Form.Check type="checkbox" />
                        </th>
                        <th>Agent ID</th>
                        <th>Agent Name</th>
                        <th>Status</th>
                        <th>Quota</th>
                        <th>Reached</th>
                        <th>Progress</th>
                        <th>Rating</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[
                        {
                          "id": "00035",
                          "avatar": img6,
                          "name": "Allan R. Palban",
                          "email": "allan@themepixels.me",
                          "status": {
                            "badge": "success",
                            "label": "Active"
                          },
                          "quota": 120,
                          "reached": 64,
                          "progress": 50,
                          "star": ["fill", "fill", "fill", "half-fill", "line"]
                        }, {
                          "id": "00028",
                          "avatar": img8,
                          "name": "Charlene S. Plateros",
                          "email": "charlene@themepixels.me",
                          "status": {
                            "badge": "info",
                            "label": "Away"
                          },
                          "quota": 100,
                          "reached": 79,
                          "progress": 70,
                          "star": ["fill", "fill", "fill", "fill", "line"]
                        }, {
                          "id": "00025",
                          "avatar": img10,
                          "name": "Adrian M. Moniño",
                          "email": "adrian@themepixels.me",
                          "status": {
                            "badge": "success",
                            "label": "Active"
                          },
                          "quota": 130,
                          "reached": 108,
                          "progress": 75,
                          "star": ["fill", "fill", "fill", "line", "line"]
                        }, {
                          "id": "00024",
                          "avatar": img11,
                          "name": "Marianne B. Audrey",
                          "email": "marianne@themepixels.me",
                          "status": {
                            "badge": "warning",
                            "label": "Pending"
                          },
                          "quota": 110,
                          "reached": 45,
                          "progress": 50,
                          "star": ["fill", "fill", "fill", "fill", "line"]
                        }, {
                          "id": "00023",
                          "avatar": img12,
                          "name": "Carlyn Y. Salomon",
                          "email": "carlyn@themepixels.me",
                          "status": {
                            "badge": "secondary",
                            "label": "Inactive"
                          },
                          "quota": 125,
                          "reached": 32,
                          "progress": 25,
                          "star": ["fill", "fill", "fill", "half-fill", "line"]
                        }
                      ].map((item, index) => (
                        <tr key={index}>
                          <td>
                            <Form.Check type="checkbox" />
                          </td>
                          <td><span className="ff-numerals">{item.id}</span></td>
                          <td>
                            <div className="d-flex align-items-center gap-2">
                              <Avatar img={item.avatar} />
                              <div>
                                <h6 className="mb-0">{item.name}</h6>
                                <span className="fs-xs text-secondary">{item.email}</span>
                              </div>
                            </div>
                          </td>
                          <td><span className={"badge bg-" + item.status.badge}>{item.status.label}</span></td>
                          <td><span className="ff-numerals">{item.quota}</span></td>
                          <td><span className="ff-numerals">{item.reached}</span></td>
                          <td>
                            <ProgressBar now={item.progress} className="ht-5 mb-0" />
                          </td>
                          <td>
                            <div className="d-flex gap-1 text-warning">
                              {item.star.map((star, ind) => (
                                <i key={ind} className={"ri-star-" + star}></i>
                              ))}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex justify-content-end">
                              <Link to="" className="link-more"><i className="ri-more-2-fill"></i></Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col> */}
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  )
}
