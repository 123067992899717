import React, { useState, useEffect, useRef } from 'react';
import _debounce from 'lodash/debounce';
import { Link, useNavigate } from 'react-router-dom';
import HeaderMobile from '../layouts/HeaderMobile';
import Header from '../layouts/Header';
import { toast } from 'react-toastify';
import { Button, Card, Modal } from 'react-bootstrap';
import { DataGrid } from '@mui/x-data-grid';
import { deleteBranch, viewapplicatestatus, viewBranch } from '../services/api/api';

const AddApplicantsStatus = () => {
    const navigate = useNavigate();
    const [applicantStatus, setApplicantStatus] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [masterId, setMasterId] = useState('');
    const [searchText, setSearchText] = useState('');

    const fetchApplicantStatus = async () => {
        try {
            const response = await viewapplicatestatus();

            console.log('API Response:', response);

            if (response[0]) {
                let applicatStat = response[0].applicat_status.map((status, index) => ({
                    ...status,
                    _id: index + 1,
                }));
                console.log(applicatStat);
                setApplicantStatus(applicatStat);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const debouncedFetchData = _debounce(fetchApplicantStatus, 200);

        debouncedFetchData();

        return () => {
            debouncedFetchData.cancel();
        };
    }, []);

    const columns = [
        {
            field: '_id',
            headerName: 'ID',
            width: 90,
            align: 'center', flex: 1, headerAlign: 'center',
            renderCell: (params) => {
                console.log(params);
                return params.value;
            }
        },
        {
            field: 'status_name',
            headerName: 'Status Name',
            width: 150,
            align: 'center', flex: 1, headerAlign: 'center',
            renderCell: (params) => {
                console.log(params);
                return params.value;
            }
        },
        {
            field: 'actions', headerName: 'Actions', width: 150, align: 'center', flex: 1, headerAlign: 'center', renderCell: (params) => (
                <>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-pencil-fill edit ebtn"
                        viewBox="0 0 16 16"
                        data-id={params.row._id}
                        style={{ cursor: 'pointer', marginRight: "15px" }}
                        onClick={() => {
                            navigate(`/pages/settings/addapplicantstatus/editstatusform/${params.row._id}`, {
                                state: { applicant: params.row }
                            });
                        }}
                    >
                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                    </svg>
                </>
            )
        },
    ];


    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
                <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                    <div>
                        <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                            <li className="breadcrumb-item">
                                <Link to="/admin/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/pages/settings/addapplicantstatus">Application Status</Link>
                            </li>
                            {/* <li className="breadcrumb-item active">Lists</li> */}
                        </ol>
                    </div>
                    <nav aria-label="breadcrumb">
                        <Button className="mb-3 custom-submit-btn">
                            <Link to="/pages/settings/addapplicantstatus/applicantstatusform">
                                <span style={{ color: "white" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                    </svg>
                                    Create Status
                                </span>
                            </Link>
                        </Button>
                    </nav>
                </div>
                <Card className="popup-card empHistory">
                    <Card.Body>
                        {loading ? (
                            <div className="loader-container">
                                <div className="loader"></div>
                            </div>
                        ) : (
                            <div style={{  width: '100%', backgroundColor: 'white' }}>
                                <DataGrid
                                    rows={applicantStatus}
                                    columns={columns}
                                    pageSize={5}
                                    responsive
                                    autoHeight
                                    getRowId={(row) => row._id}
                                    headerClassName="custom-header"
                                    components={{
                                        NoRowsOverlay: () => <div style={{ textAlign: 'center', padding: '20px' }}>No data available</div>,
                                    }}
                                />
                            </div>
                        )}
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    );
};

export default AddApplicantsStatus;
