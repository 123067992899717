import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { updateDepartment, editShift, updateShift } from '../../../services/api/api';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../layouts/Header';
import HeaderMobile from '../../../layouts/HeaderMobile';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form } from 'react-bootstrap';
import { Link } from "react-router-dom";

const EditShift = () => {
    const navigate = useNavigate();
    const { id, masterId, shiftNo } = useParams();
    const [submitting, setSubmitting] = useState(false);
    const [state, setState] = useState({
        _id: '',
        company_id: '',
        shift_no: "",
        company_start_time: "",
        company_end_time: "",
        company_break_time: "",
        company_break_fine: "",
        company_late_fine: "",
        timezone: "",
        breakList: [{ break_id: null, break_name: "", break_time: null }],
    });

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);



    // useEffect(() => {
    //     const fetchShiftData = debounce(async () => {
    //         try {
    //             setLoading(true);
    //             const shiftData = await editShift(id, masterId, shiftNo);

    //             if (shiftData && shiftData.success) {
    //                 const shift = shiftData.data[0];

    //                 console.log(shift);


    //                 setState({
    //                     _id: shift._id || '',
    //                     company_id: shift.company_id || '',
    //                     shift_no: shift.shift_no || '',
    //                     company_start_time: shift.company_start_time || '',
    //                     company_end_time: shift.company_end_time || '',
    //                     company_break_time: shift.company_break_time || '',
    //                     company_break_fine: shift.company_break_fine || '',
    //                     company_late_fine: shift.company_late_fine || '',
    //                     timezone: shift.timezone || '',
    //                     breakList: shift.breakList || [],
    //                 });
    //             } else {
    //                 console.error('No shift data found');
    //             }
    //             setLoading(false);
    //         } catch (error) {
    //             console.error('Error fetching shift data:', error);
    //             setLoading(false);
    //         }
    //     }, 100);

    //     fetchShiftData();

    //     return () => {
    //         fetchShiftData.cancel();
    //     };
    // }, [id, masterId]);



    // onchange without timezone 


    useEffect(() => {
        const fetchShiftData = debounce(async () => {
            try {
                setLoading(true);
                const shiftData = await editShift(id, masterId, shiftNo);

                // Check if the response contains the shift data correctly
                if (shiftData && shiftData.success) {
                    const shift = shiftData.success; // Accessing the success object directly
                    console.log(shift);

                    // Safely accessing shift details and setting state
                    setState({
                        _id: shift._id || '',
                        company_id: shift.company_id || '',
                        shift_no: shift.shift_no || '',
                        company_start_time: shift.company_start_time || '',
                        company_end_time: shift.company_end_time || '',
                        company_break_time: shift.company_break_time || '',
                        company_break_fine: shift.company_break_fine || '',
                        company_late_fine: shift.company_late_fine || '',
                        timezone: shift.timezone || '',
                        breakList: shift.breakList || [], // Assuming breakList exists and needs to be handled
                    });
                } else {
                    console.error('No valid shift data found');
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching shift data:', error);
                setLoading(false);
            }
        }, 100);

        fetchShiftData();

        return () => {
            fetchShiftData.cancel();
        };
    }, [id, masterId]);





    const handleChange = (e, break_id) => {
        const { name, value } = e.target;

        setState(prevState => {
            // Check if the field name is in the top-level state
            if (['shift_no', 'company_start_time', 'company_end_time', 'timezone', 'company_break_time', 'company_break_fine', 'company_late_fine'].includes(name)) {
                return {
                    ...prevState,
                    [name]: value,
                };
            } else {
                console.log(name, value);
                // Update the breakList
                return {
                    ...prevState,
                    breakList: prevState.breakList.map(item => {
                        if (item.break_id === break_id) {
                            return {
                                ...item,
                                [name]: value,
                            };
                        }
                        return item;
                    }),
                };
            }
        });
    };






    // const handleChange = (e, break_id) => {
    //     const { name, value } = e.target;

    //     // Define the fields where only numeric values are allowed
    //     const numericFields = ['company_break_time', 'company_break_fine', 'company_late_fine'];

    //     // Check if the field is one of the numeric-only fields
    //     if (numericFields.includes(name)) {
    //         // Allow only numeric values (optional: allow empty string for clearing)
    //         if (!/^\d*$/.test(value)) {
    //             // If the input is not numeric, prevent the update
    //             return;
    //         }
    //     }

    //     setState(prevState => {
    //         if (['shift_no', 'company_start_time', 'company_end_time', 'timezone', 'company_break_time', 'company_break_fine', 'company_late_fine'].includes(name)) {
    //             return {
    //                 ...prevState,
    //                 [name]: value,
    //             };
    //         } else {
    //             return {
    //                 ...prevState,
    //                 breakList: prevState.breakList.map(item => {
    //                     if (item.break_id === break_id) {
    //                         return {
    //                             ...item,
    //                             [name]: value,
    //                         };
    //                     }
    //                     return item;
    //                 }),
    //             };
    //         }
    //     });
    // };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm(state);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        } if (submitting) {
            return;
        }
        setSubmitting(true);

        try {
            const response = await updateShift(id, masterId, state);
            if (response && response.success && response.success.length > 0) {
                toast.success(response.success[0].message, {
                    position: 'top-center',
                    autoClose: 1000,
                });
            } else {
                toast.success('shift updated successfully', {
                    position: 'top-center',
                    autoClose: 1000,
                });
                setSubmitting(false);
                navigate('/pages/hr/shift');
            }
        } catch (error) {
            console.error('An error occurred:', error);
            toast.error('An error occurred', {
                position: 'top-right',
                autoClose: 1000,
            });
        }
    };

    const validateForm = (state) => {
        let errors = {};
        // Add validations if necessary
        return errors;
    };

    const [displayedDate, setDisplayedDate] = useState('');
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US');
    };
    useEffect(() => {
        if (state.shift_date) {
            setDisplayedDate(formatDate(state.shift_date));
        }
    }, [state.joining_date]);

    const handleDateChange = (event) => {
        const newDate = event.target.value;
        setState((prevState) => ({
            ...prevState,
            shift_date: newDate,
        }));
        setDisplayedDate(formatDate(newDate));
    };

    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />

            <div className="main p-4 p-lg-5 mt-5">
                <div className="mb-4 border-bottom text-center">
                    <h3 className="mb-0 pb- border-bottom custome-btn">Edit Shift</h3>
                </div>
                <div>


                    <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                        <div>
                            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    <Link to="/pages/hr/shift">Shift</Link>
                                </li>
                                <li className="breadcrumb-item active">Edit Shift</li>
                            </ol>
                        </div>
                        <nav aria-label="breadcrumb">
                            <Link to="/pages/hr/shift" className="btn btn-danger mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                </svg>
                                Back
                            </Link>
                        </nav>
                    </div>

                    {loading &&
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                    }

                    <div className="row"
                        style={{
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            borderRadius: "10px",
                            overflow: "hidden",
                        }}
                    >


                        <div className="col-md-12">
                            <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="shift_no" className="form-label">
                                                    Shift no.
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.shift_no && 'is-invalid'}`}
                                                    id="shift_no"
                                                    name="shift_no"
                                                    value={state.shift_no}
                                                    onChange={handleChange}
                                                />
                                                {errors.shift_no && (
                                                    <div className="invalid-feedback">{errors.shift_no}</div>
                                                )}
                                            </div>


                                        </div>
                                        <div className="col-md-6">

                                            <div className="mb-3">
                                                <label htmlFor="company_start_time" className="form-label">
                                                    Company start time
                                                </label>
                                                <input
                                                    type="time"
                                                    className={`form-control ${errors.company_start_time && 'is-invalid'}`}
                                                    id="company_start_time"
                                                    name="company_start_time"
                                                    value={state.company_start_time}
                                                    onChange={handleChange}
                                                />
                                                {errors.company_start_time && (
                                                    <div className="invalid-feedback">{errors.company_start_time}</div>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="company_end_time" className="form-label">
                                                    Company end time
                                                </label>
                                                <input
                                                    type="time"
                                                    className={`form-control ${errors.company_end_time && 'is-invalid'}`}
                                                    id="company_end_time"
                                                    name="company_end_time"
                                                    value={state.company_end_time}
                                                    onChange={handleChange}
                                                />
                                                {errors.company_end_time && (
                                                    <div className="invalid-feedback">{errors.company_end_time}</div>
                                                )}
                                            </div>


                                        </div>
                                        <div className="col-md-6">

                                            <div className="mb-3">
                                                <label htmlFor="company_break_time" className="form-label">
                                                    Company break time (in minutes)
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.company_break_time && 'is-invalid'}`}
                                                    id="company_break_time"
                                                    name="company_break_time"
                                                    value={state.company_break_time}
                                                    onChange={handleChange}
                                                />
                                                {errors.company_break_time && (
                                                    <div className="invalid-feedback">{errors.company_break_time}</div>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="company_break_fine" className="form-label">
                                                    Break fine (in minutes)
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.company_break_fine && 'is-invalid'}`}
                                                    id="company_break_fine"
                                                    name="company_break_fine"
                                                    value={state.company_break_fine}
                                                    onChange={handleChange}
                                                />
                                                {errors.company_break_fine && (
                                                    <div className="invalid-feedback">{errors.company_break_fine}</div>
                                                )}
                                            </div>


                                        </div>
                                        <div className="col-md-6">

                                            <div className="mb-3">
                                                <label htmlFor="company_late_fine" className="form-label">
                                                    Late fine (in minutes)
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.company_late_fine && 'is-invalid'}`}
                                                    id="company_late_fine"
                                                    name="company_late_fine"
                                                    value={state.company_late_fine}
                                                    onChange={handleChange}
                                                />
                                                {errors.company_late_fine && (
                                                    <div className="invalid-feedback">{errors.company_late_fine}</div>
                                                )}
                                            </div>

                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="timezone" className="form-label">
                                                    Time Zone <span className="req text-danger">*</span>
                                                </label>

                                                <select id="timezone" style={{ width: '100%', padding: '5px' }} onChange={handleChange} value={state.timezone} class="custom-select" name="timezone">
                                                    <option value="" disabled selected>Select Your TimeZone</option>
                                                    <option value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</option>
                                                    <option value="America/Adak">(GMT-10:00) Hawaii-Aleutian</option>
                                                    <option value="Etc/GMT+10">(GMT-10:00) Hawaii</option>
                                                    <option value="Pacific/Marquesas">(GMT-09:30) Marquesas Islands</option>
                                                    <option value="Pacific/Gambier">(GMT-09:00) Gambier Islands</option>
                                                    <option value="America/Anchorage">(GMT-09:00) Alaska</option>
                                                    <option value="America/Ensenada">(GMT-08:00) Tijuana, Baja California</option>
                                                    <option value="Etc/GMT+8">(GMT-08:00) Pitcairn Islands</option>
                                                    <option value="America/Los_Angeles">(GMT-08:00) Pacific Time (US & Canada)</option>
                                                    <option value="America/Denver">(GMT-07:00) Mountain Time (US & Canada)</option>
                                                    <option value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
                                                    <option value="America/Dawson_Creek">(GMT-07:00) Arizona</option>
                                                    <option value="America/Belize">(GMT-06:00) Saskatchewan, Central America</option>
                                                    <option value="America/Cancun">(GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
                                                    <option value="Chile/EasterIsland">(GMT-06:00) Easter Island</option>
                                                    <option value="America/Chicago">(GMT-06:00) Central Time (US & Canada)</option>
                                                    <option value="America/New_York">(GMT-05:00) Eastern Time (US & Canada)</option>
                                                    <option value="America/Havana">(GMT-05:00) Cuba</option>
                                                    <option value="America/Bogota">(GMT-05:00) Bogota, Lima, Quito, Rio Branco</option>
                                                    <option value="America/Caracas">(GMT-04:30) Caracas</option>
                                                    <option value="America/Santiago">(GMT-04:00) Santiago</option>
                                                    <option value="America/La_Paz">(GMT-04:00) La Paz</option>
                                                    <option value="Atlantic/Stanley">(GMT-04:00) Faukland Islands</option>
                                                    <option value="America/Campo_Grande">(GMT-04:00) Brazil</option>
                                                    <option value="America/Goose_Bay">(GMT-04:00) Atlantic Time (Goose Bay)</option>
                                                    <option value="America/Glace_Bay">(GMT-04:00) Atlantic Time (Canada)</option>
                                                    <option value="America/St_Johns">(GMT-03:30) Newfoundland</option>
                                                    <option value="America/Araguaina">(GMT-03:00) UTC-3</option>
                                                    <option value="America/Montevideo">(GMT-03:00) Montevideo</option>
                                                    <option value="America/Miquelon">(GMT-03:00) Miquelon, St. Pierre</option>
                                                    <option value="America/Godthab">(GMT-03:00) Greenland</option>
                                                    <option value="America/Argentina/Buenos_Aires">(GMT-03:00) Buenos Aires</option>
                                                    <option value="America/Sao_Paulo">(GMT-03:00) Brasilia</option>
                                                    <option value="America/Noronha">(GMT-02:00) Mid-Atlantic</option>
                                                    <option value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Is.</option>
                                                    <option value="Atlantic/Azores">(GMT-01:00) Azores</option>
                                                    <option value="Europe/Belfast">(GMT) Greenwich Mean Time : Belfast</option>
                                                    <option value="Europe/Dublin">(GMT) Greenwich Mean Time : Dublin</option>
                                                    <option value="Europe/Lisbon">(GMT) Greenwich Mean Time : Lisbon</option>
                                                    <option value="Europe/London">(GMT) Greenwich Mean Time : London</option>
                                                    <option value="Africa/Abidjan">(GMT) Monrovia, Reykjavik</option>
                                                    <option value="Europe/Amsterdam">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
                                                    <option value="Europe/Belgrade">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
                                                    <option value="Europe/Brussels">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                                                    <option value="Africa/Algiers">(GMT+01:00) West Central Africa</option>
                                                    <option value="Africa/Windhoek">(GMT+01:00) Windhoek</option>
                                                    <option value="Asia/Beirut">(GMT+02:00) Beirut</option>
                                                    <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
                                                    <option value="Asia/Gaza">(GMT+02:00) Gaza</option>
                                                    <option value="Africa/Blantyre">(GMT+02:00) Harare, Pretoria</option>
                                                    <option value="Asia/Jerusalem">(GMT+02:00) Jerusalem</option>
                                                    <option value="Europe/Minsk">(GMT+02:00) Minsk</option>
                                                    <option value="Asia/Damascus">(GMT+02:00) Syria</option>
                                                    <option value="Europe/Moscow">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
                                                    <option value="Africa/Addis_Ababa">(GMT+03:00) Nairobi</option>
                                                    <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
                                                    <option value="Asia/Dubai">(GMT+04:00) Abu Dhabi, Muscat</option>
                                                    <option value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
                                                    <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
                                                    <option value="Asia/Yekaterinburg">(GMT+05:00) Ekaterinburg</option>
                                                    <option value="Asia/Tashkent">(GMT+05:00) Tashkent</option>
                                                    <option value="Asia/Kolkata">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                                                    <option value="Asia/Katmandu">(GMT+05:45) Kathmandu</option>
                                                    <option value="Asia/Dhaka">(GMT+06:00) Astana, Dhaka</option>
                                                    <option value="Asia/Novosibirsk">(GMT+06:00) Novosibirsk</option>
                                                    <option value="Asia/Rangoon">(GMT+06:30) Yangon (Rangoon)</option>
                                                    <option value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
                                                    <option value="Asia/Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
                                                    <option value="Asia/Hong_Kong">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                                                    <option value="Asia/Irkutsk">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
                                                    <option value="Australia/Perth">(GMT+08:00) Perth</option>
                                                    <option value="Australia/Eucla">(GMT+08:45) Eucla</option>
                                                    <option value="Asia/Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
                                                    <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
                                                    <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
                                                    <option value="Australia/Adelaide">(GMT+09:30) Adelaide</option>
                                                    <option value="Australia/Darwin">(GMT+09:30) Darwin</option>
                                                    <option value="Australia/Brisbane">(GMT+10:00) Brisbane</option>
                                                    <option value="Australia/Hobart">(GMT+10:00) Hobart</option>
                                                    <option value="Asia/Vladivostok">(GMT+10:00) Vladivostok</option>
                                                    <option value="Australia/Lord_Howe">(GMT+10:30) Lord Howe Island</option>
                                                    <option value="Etc/GMT-11">(GMT+11:00) Solomon Is., New Caledonia</option>
                                                    <option value="Asia/Magadan">(GMT+11:00) Magadan</option>
                                                    <option value="Pacific/Norfolk">(GMT+11:30) Norfolk Island</option>
                                                    <option value="Asia/Anadyr">(GMT+12:00) Anadyr, Kamchatka</option>
                                                    <option value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</option>
                                                    <option value="Etc/GMT-12">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
                                                    <option value="Pacific/Chatham">(GMT+12:45) Chatham Islands</option>
                                                    <option value="Pacific/Tongatapu">(GMT+13:00) Nuku'alofa</option>
                                                    <option value="Pacific/Kiritimati">(GMT+14:00) Kiritimati</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">

                                        {state.breakList.map((item, index) => (
                                            <div key={index} className="mb-3">
                                                <label htmlFor={`break_name_${item.break_id}`} className="form-label">
                                                    {item.break_name}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id={`break_name_${item.break_id}`}
                                                    name='break_time'
                                                    value={item.break_time}
                                                    onChange={(e) => handleChange(e, item.break_id)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <button type="submit" className="custom-submit-btn" onClick={handleSubmit}
                                        disabled={submitting}>

                                        {submitting ? 'Submitting...' : 'Update'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </React.Fragment>
    );
};

export default EditShift;

